import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import SidebarButton from './SidebarButton';
import SidebarContent from './SidebarContent';

interface Props {
  open: boolean;
  onOpen: () => void;
}

function CollapsedSidebar(props: Props) {
  const { open, onOpen } = props;
  return (
    <Transition
      as={Fragment}
      show={!open}
      enter="transition-opacity delay-100 duration-700"
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div className="relative z-10 hidden text-center lg:fixed lg:inset-y-0 lg:block lg:w-16 lg:border-r lg:border-gray-200 lg:bg-gray-100 dark:lg:border-gray-800 lg:dark:bg-gray-800">
        <div className="absolute bottom-0 w-full p-2 text-center">
          <SidebarButton open={open} onClick={onOpen} />
        </div>
        <div className="pt-6">
          <SidebarContent collapsed />
        </div>
      </div>
    </Transition>
  );
}

export default CollapsedSidebar;
