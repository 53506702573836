import { DoorFilters } from '@hakimo-ui/hakimo/types';

export function isNotDefaultFilter(filters: DoorFilters): boolean {
  return (
    !filters.labelStatus.showLabeled ||
    !filters.labelStatus.showUnlabeled ||
    filters.location.values.length > 0 ||
    filters.door.values.length > 0
  );
}

export const mapDoorFilter = (filters: DoorFilters) => {
  const { location, door, labelStatus } = filters;
  const filtersApplied: Record<string, string[] | object> = {};

  location.values.length > 0 &&
    (filtersApplied['location'] = {
      value: location.values.map((l) => l.name),
      negative: location.negative,
    });

  door.values.length > 0 &&
    (filtersApplied['door'] = {
      value: door.values.map((d) => d.name),
      negative: door.negative,
    });

  filtersApplied['labelStatus'] = labelStatus;

  return filtersApplied;
};
