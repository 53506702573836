import { EmployeeFilterValue } from '@hakimo-ui/hakimo/types';
import { FilterSection } from '@hakimo-ui/hakimo/ui-filters';
import EmployeeFilter from './employee-filter/EmployeeFilter';

interface Props {
  onChange: (val: EmployeeFilterValue) => void;
  filterValue: EmployeeFilterValue;
}
export function EmployeeFilterSection(props: Props) {
  const { filterValue, onChange } = props;
  return (
    <FilterSection
      title="Employee"
      content={
        <EmployeeFilter
          label="Employee Name"
          onChange={onChange}
          employees={filterValue}
        />
      }
      hasFilterApplied={filterValue.values.length > 0}
    />
  );
}
