import { useReportBug } from '@hakimo-ui/hakimo/data-access';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal, Radio } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { getBugReport, isValidBugReport, RadioItem, radioItems } from './util';

interface Props {
  alarmId: string;
  onClose: () => void;
}

export function BugReportModal(props: Props) {
  const { alarmId, onClose } = props;

  const mutation = useReportBug(alarmId, () => {
    toast('Bug report submitted', { type: 'success' });
    onClose();
  });
  const [description, setDescription] = useState<string>('');
  const [severity, setSeverity] = useState<RadioItem>();
  const [bugReporter, setBugReporter] = useState<string>();
  const onSubmit = () => {
    if (!isValidBugReport(description, severity?.id)) {
      return;
    }
    const bugReport = getBugReport(
      description,
      severity?.id ?? 'high',
      bugReporter
    );
    mutation.mutate(bugReport);
  };
  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        loading={mutation.isLoading}
        disabled={
          mutation.isLoading || !isValidBugReport(description, severity?.id)
        }
        trackingInfo="Submit bug report"
      >
        Submit
      </Button>
      <Button onClick={onClose} trackingInfo="Cancel add bug report">
        Cancel
      </Button>
    </>
  );
  return (
    <Modal title="Bug Report" footer={actions} open onClose={onClose}>
      {mutation.isError && (
        <div className="px-8 pt-2">
          <Alert type="error">{mutation.error.message}</Alert>
        </div>
      )}
      <div className="w-[30rem] p-8">
        <h2 className="dark:bg-dark-bg mb-1 block text-xs text-gray-700 dark:text-gray-300">
          Hakimo output
        </h2>
        <Radio
          items={radioItems}
          value={severity}
          onChange={setSeverity}
          id={(item: RadioItem) => item.id}
          displayValue={(item: RadioItem) => item.name}
        />
        <div className="mt-6">
          <label
            htmlFor="feedback"
            className="mb-1 block text-xs text-gray-700 dark:text-gray-300"
          >
            Add your feedback
          </label>
          <div className="mt-1">
            <textarea
              rows={4}
              name="feedback"
              id="feedback"
              className="focus:border-primary-500 focus:ring-primary-500 dark:bg-dark-bg block w-full rounded-md border-gray-300 shadow-sm dark:border-gray-500 sm:text-sm"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-6">
          <label
            htmlFor="bug-hunter-name"
            className="mb-1 block text-xs text-gray-700 dark:text-gray-300"
          >
            Bug Hunter Name
          </label>
          <div className="mt-1">
            <textarea
              rows={1}
              name="bug-hunter-name"
              id="bug-hunter-name"
              className="focus:border-primary-500 focus:ring-primary-500 dark:bg-dark-bg block w-full rounded-md border-gray-300 shadow-sm dark:border-gray-500 sm:text-sm"
              value={bugReporter}
              onChange={(e) => setBugReporter(e.target.value)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default BugReportModal;
