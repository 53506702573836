/*eslint max-lines: ["error", 200]*/
import { useAlarms } from '@hakimo-ui/hakimo/data-access';
import { AlarmListFilters, AlarmsDTO } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  trackFilters,
  useCanViewTenantColumn,
  useLocalStorage,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useOnClickRow } from '../shared/useOnClickRow';
import {
  createSetPageAction,
  createUpdateFiltersAction,
} from '../store/action-creators';
import { useLocalDispatch, useLocalState } from '../store/StateProvider';
import AlarmListTable from './alarm-list-table/AlarmListTable';
import FilterPanel from './filters/filter-panel/FilterPanel';
import { columns, getSearchParams, getTableData, notifyNewAlarm } from './util';

const ALARM_QUERY_REFRESH = 5000;
function AlarmList() {
  const {
    alarmListPage: page,
    alarmListPageSize: pageSize,
    alarmListFilters,
  } = useLocalState();

  const dispatch = useLocalDispatch();
  const [total, setTotal] = useState(0);
  const [queryParams, setQueryParams] = useState<string>(
    getSearchParams(page, pageSize, alarmListFilters)
  );
  const [audioNotify] = useLocalStorage<boolean>(
    'alarmAudioNotification',
    false
  );
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const canViewTenantColumn = useCanViewTenantColumn();

  const availableColumnIds = useMemo(
    () =>
      columns
        .filter((col) => col.id !== 'tenant' || canViewTenantColumn)
        .map((col) => col.id),
    [canViewTenantColumn]
  );

  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });
  const prevData = useRef<AlarmsDTO>();

  const [shownColumns, setShownColumns] = useLocalStorage<string[]>(
    'alarm-list-shown-columns',
    availableColumnIds
  );

  useEffect(() => {
    const timer = setTimeout(
      () => setQueryParams(getSearchParams(page, pageSize, alarmListFilters)),
      ALARM_QUERY_REFRESH
    );

    return () => clearTimeout(timer);
  }, [page, pageSize, alarmListFilters, queryParams]);
  useEffect(() => {
    setQueryParams(getSearchParams(page, pageSize, alarmListFilters));
  }, [page, pageSize, alarmListFilters]);
  const { isLoading, isError, error, data, isRefetching } = useAlarms(
    queryParams,
    ALARM_QUERY_REFRESH
  );
  const onClickRow = useOnClickRow();

  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      const newData = getTableData(
        items,
        onClickRow,
        canViewTenantColumn,
        shownColumns ?? availableColumnIds
      );
      notifyNewAlarm(prevData.current?.items, data.items, page, audioNotify);
      prevData.current = data;
      setTableData(newData);
      setTotal(t);
    }
  }, [
    data,
    onClickRow,
    canViewTenantColumn,
    shownColumns,
    availableColumnIds,
    page,
    audioNotify,
  ]);

  const onApplyFilters = (filters: AlarmListFilters) => {
    setOpenFilterPanel(false);
    dispatch(createUpdateFiltersAction(filters));
    dispatch(createSetPageAction(1));
    trackFilters(filters);
  };

  return (
    <Page title="Alarms">
      {isError && (
        <div className="mb-2">
          <Alert type="error">{error.message}</Alert>
        </div>
      )}
      <FilterPanel
        open={openFilterPanel}
        filters={alarmListFilters}
        onClose={() => setOpenFilterPanel(false)}
        onApplyFilters={onApplyFilters}
      />
      <AlarmListTable
        tableData={tableData}
        canViewTenantColumn={canViewTenantColumn}
        shownColumns={shownColumns ?? availableColumnIds}
        isLoading={(isLoading || isRefetching) && !tableData}
        isRefetching={isRefetching || isLoading}
        total={total}
        onNextPage={() => dispatch(createSetPageAction(page + 1))}
        onPreviousPage={() => dispatch(createSetPageAction(page - 1))}
        onFirstPage={() => dispatch(createSetPageAction(1))}
        onChangeShownColumns={setShownColumns}
        onOpenFilterPanel={() => setOpenFilterPanel(true)}
      />
    </Page>
  );
}

export default withAuthz(withErrorBoundary(AlarmList), ['alarm:view']);
