// Hardcoding _APP_ENV_ right now, because I couldn't find a way
// around it without allowing all access to window to be without
// type checking

import { AuthMode, Environment } from './types';

declare global {
  interface Window {
    _APP_ENV_: { [key: string]: string };
  }
}

function getEnvValueAsString(name: string): string {
  return window._APP_ENV_?.[name] ?? 'UNKNOWN';
}

export const environment = {
  auth0: {
    domain: getEnvValueAsString('AUTH0_DOMAIN'),
    clientId: getEnvValueAsString('AUTH0_CLIENT_ID'),
    audience: getEnvValueAsString('AUTH0_AUDIENCE'),
  },
  apiUrl: getEnvValueAsString('API_URL'),
  release: getEnvValueAsString('RELEASE_NAME'),
  name: getEnvValueAsString('ENVIRONMENT') as Environment,
  authMode: getEnvValueAsString('AUTH_MODE') as AuthMode,
};
