import { DCPLabel } from '@hakimo-ui/hakimo/types';
import produce from 'immer';
import { Reducer } from 'react';
import { Action } from './actions';

const reducer: Reducer<DCPLabel, Action> = produce((draft, action) => {
  switch (action.type) {
    case 'ADD_DOOR_MARKER':
      if (!draft.doorMarkers) {
        draft.doorMarkers = [];
      }
      draft.doorMarkers.push(action.payload);
      break;
    case 'ADD_FLOOR_MARKER':
      draft.floorMarker = action.payload;
      break;
    case 'UPDATE_LABEL':
      if (action.payload.cameraPosition !== undefined) {
        draft.cameraPosition = action.payload.cameraPosition;
      }
      if (action.payload.doorMarkers !== undefined) {
        draft.doorMarkers = action.payload.doorMarkers;
      }
      if (action.payload.floorMarker !== undefined) {
        draft.floorMarker = action.payload.floorMarker;
      }
      if (action.payload.labellingResolution !== undefined) {
        draft.labellingResolution = action.payload.labellingResolution;
      }
      break;
    default:
      throw new Error();
  }
});

export default reducer;
