import { useDoorImage } from '@hakimo-ui/hakimo/data-access';
import { Marker } from '@hakimo-ui/hakimo/types';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { Alert } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { line } from 'd3-shape';
import { useMemo, useState } from 'react';
import MarkerIcon from './MarkerIcon';
import { clientMarkerToImageMarker, imageMarkerToClientMarker } from './util';

const lineGenerator = line();

interface Props {
  dcpId: string;
  doorMarkers: Marker[];
  floorMarker?: Marker | null;
  onAddDoorMarker: (marker: Marker) => void;
  onAddFloorMarker: (marker: Marker) => void;
  onImageLoadCallback: (marker: Marker) => void;
}

function ImagePanel(props: Props) {
  const {
    dcpId,
    doorMarkers: doorMarkersImage,
    floorMarker: floorMarkerImage,
    onAddDoorMarker,
    onAddFloorMarker,
    onImageLoadCallback,
  } = props;

  const queryResult = useDoorImage(dcpId);

  const [imageElement, setImageElement] = useState<HTMLImageElement>();

  const doorMarkers = useMemo(() => {
    if (!imageElement) {
      return [];
    }
    return doorMarkersImage.map((marker) =>
      imageMarkerToClientMarker(imageElement, marker)
    );
  }, [imageElement, doorMarkersImage]);

  const floorMarker = useMemo(() => {
    if (!imageElement || !floorMarkerImage) {
      return undefined;
    }
    return imageMarkerToClientMarker(imageElement, floorMarkerImage);
  }, [imageElement, floorMarkerImage]);

  const onClickImage: React.MouseEventHandler<HTMLImageElement> = (e) => {
    if (!imageElement) {
      return;
    }

    const marker: Marker = clientMarkerToImageMarker(imageElement, [
      e.clientX,
      e.clientY,
    ]);

    if (doorMarkers.length < 4) {
      onAddDoorMarker(marker);
    } else if (!floorMarker) {
      onAddFloorMarker(marker);
    }
  };

  const boundingPathData = useMemo(() => {
    if (doorMarkers.length < 4) {
      return '';
    }
    const markers: [number, number][] = doorMarkers.map((m) => [m[0], m[1]]);
    return lineGenerator(markers) + 'z' ?? '';
  }, [doorMarkers]);

  const onLoadImage: React.ReactEventHandler<HTMLImageElement> = (e) => {
    setImageElement(e.currentTarget);
    const imgSize: Marker = [
      e.currentTarget.naturalWidth,
      e.currentTarget.naturalHeight,
    ];
    onImageLoadCallback(imgSize);
  };

  return (
    <QueryResult
      queryResult={queryResult}
      loadingText="Loading Image..."
      errorFormatter={(error) => <Alert type="error">{error.message}</Alert>}
      keepCurrentContentOnRefetch={false}
    >
      {(data) => (
        <div
          className={clsx(
            'relative',
            !floorMarker ? 'cursor-crosshair' : 'cursor-auto'
          )}
        >
          <img
            src={data.url}
            alt="door"
            onClick={onClickImage}
            onLoad={onLoadImage}
            className="w-full"
          />
          {doorMarkers.map((marker, markerIndex) => {
            return (
              <MarkerIcon
                key={markerIndex}
                style={{
                  left: marker[0] - 24,
                  top: marker[1] - 24,
                }}
                className="text-primary-700 absolute w-12"
              />
            );
          })}
          {floorMarker && (
            <MarkerIcon
              style={{
                left: floorMarker[0] - 24,
                top: floorMarker[1] - 24,
              }}
              className="absolute w-12 text-gray-700 [transform:rotateX(60deg)]"
            />
          )}
          <svg className="pointer-events-none absolute left-0 top-0 h-full w-full">
            <path
              d={boundingPathData}
              className="stroke-primary-700 fill-transparent stroke-[4]"
            ></path>
          </svg>
        </div>
      )}
    </QueryResult>
  );
}

export default ImagePanel;
