import { useSendAudio } from '@hakimo-ui/hakimo/data-access';
import { toast } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { MicrophoneIcon } from '@heroicons/react/24/solid';
import { useCallback, useState } from 'react';
import Recorder from './Recorder';

interface Props {
  cameraId: string;
  alarmId: string;
  onToggle?: (open: boolean) => void;
}

export function AudioRecorder(props: Props) {
  const { cameraId, alarmId, onToggle } = props;
  const [record, setRecord] = useState(false);
  const [audioFile, setAudioFile] = useState<File>();

  const mutation = useSendAudio(cameraId, alarmId, () => {
    toast('Audio sent to camera successfully');
  });

  const onSubmitAudio = useCallback(() => {
    const formData = new FormData();
    formData.append('audio', audioFile || '');
    mutation.mutate(formData);
  }, [audioFile, mutation]);

  const onCloseRecorder = useCallback(() => {
    setRecord(false);
    onToggle && onToggle(false);
  }, [onToggle]);

  const onOpenRecorder = () => {
    setRecord(true);
    onToggle && onToggle(true);
  };

  return (
    <div className="flex h-12 items-center justify-end">
      {record ? (
        <Recorder
          onClose={onCloseRecorder}
          onChangeAudio={setAudioFile}
          onSubmitAudio={onSubmitAudio}
        />
      ) : (
        <Button variant="icon" onClick={onOpenRecorder}>
          <MicrophoneIcon className="h-5 w-5" />
        </Button>
      )}
    </div>
  );
}

export default AudioRecorder;
