import {
  AlarmStatus,
  displayStatusTypes,
  LocationAlarmStatus,
  StatusType,
} from '@hakimo-ui/hakimo/types';
import { LabelType, Selectable } from '@hakimo-ui/shared/ui-base';

export function getStatusLabelType(
  status: AlarmStatus | LocationAlarmStatus
): LabelType {
  switch (status) {
    case 'Resolved Manually':
      return 'success';
    case 'Resolved by Hakimo':
      return 'success';
    case 'Resolved':
      return 'success';
    case 'Alarm Acknowledged via ACS':
      return 'success';
    case 'Analyzing':
      return 'info';
    case 'In Progress':
      return 'warning';
    case 'Pending':
      return 'error';
    default:
      return 'error';
  }
}

export const displayStatusSelectables: Selectable<StatusType>[] =
  displayStatusTypes.map((name, index) => ({
    id: `${index}`,
    name,
  }));

export function checkNeedsComment(
  oldStatus: StatusType,
  newStatus: StatusType | undefined,
  commentOptional: boolean
): boolean {
  if (commentOptional) {
    return false;
  }
  if (oldStatus === 'Pending' && newStatus === 'In Progress') {
    return false;
  }
  return true;
}
