import { AlarmTypeFilterValue } from '@hakimo-ui/hakimo/types';
import { FilterSection } from '@hakimo-ui/hakimo/ui-filters';
import AlarmTypeFilter from './AlarmTypeFilter';
interface Props {
  onChange: (val: AlarmTypeFilterValue) => void;
  filterValue: AlarmTypeFilterValue;
}
export function AlarmTypeFilterSection(props: Props) {
  const { filterValue, onChange } = props;
  return (
    <FilterSection
      title="Type"
      content={
        <AlarmTypeFilter
          label="Alarm Type"
          value={filterValue}
          onChange={onChange}
          enableNegativeFilters={true}
        />
      }
      hasFilterApplied={filterValue.values.length > 0}
      negativeApplied={filterValue.negative}
    />
  );
}
