import { DCPLabel, Marker } from '@hakimo-ui/hakimo/types';

import {
  AddDoorMarkerAction,
  AddFloorMarkerAction,
  ADD_DOOR_MARKER,
  ADD_FLOOR_MARKER,
  UpdateLabelAction,
  UPDATE_LABEL,
} from './actions';

export function createUpdateLabelAction(
  payload: Partial<DCPLabel>
): UpdateLabelAction {
  return {
    type: UPDATE_LABEL,
    payload,
  };
}

export function createAddDoorMarkerAction(
  payload: Marker
): AddDoorMarkerAction {
  return {
    type: ADD_DOOR_MARKER,
    payload,
  };
}

export function createAddFloorMarkerAction(
  payload: Marker
): AddFloorMarkerAction {
  return {
    type: ADD_FLOOR_MARKER,
    payload,
  };
}
