import { Alert } from '@hakimo-ui/shared/ui-base';
import { memo } from 'react';
import AudioRecorder from '../../../alarm-details/alarm-media/audio-recorder/AudioRecorder';
import LiveVideo from '../../../alarm-details/alarm-media/live-video/LiveVideo';

export interface Props {
  alarmId: string;
  cameraId: string;
}

export function LiveView(props: Props) {
  const { alarmId, cameraId } = props;

  return (
    <div className="space-y-3">
      <div className="flex h-9 items-center justify-between">
        <h2 className="flex items-center gap-2">
          <span>Live</span>
          <span className="bg-status-red h-1.5 w-1.5 rounded-full"></span>
        </h2>
        <AudioRecorder
          alarmId={alarmId}
          cameraId={cameraId}
          onToggle={() => ({})}
        />
      </div>
      <LiveVideo
        cameraId={cameraId}
        open
        renderAlert={renderAlert}
        lockAspectRatio
      />
    </div>
  );
}

export default memo(LiveView);

function renderAlert(message: string) {
  return <Alert>{message}</Alert>;
}
