import { LocationFilterValue } from '@hakimo-ui/hakimo/types';
import { FilterSection, LocationFilter } from '@hakimo-ui/hakimo/ui-filters';

interface Props {
  onChange: (val: LocationFilterValue) => void;
  filterValue: LocationFilterValue;
}
export function LocationFilterSection(props: Props) {
  const { filterValue, onChange } = props;
  return (
    <FilterSection
      title="Location"
      content={
        <LocationFilter
          label="Alarm Location"
          value={filterValue}
          onChange={onChange}
          enableNegativeFilters={true}
        />
      }
      hasFilterApplied={filterValue.values.length > 0}
      negativeApplied={filterValue.negative}
    />
  );
}
