import Spinner from './Spinner';

export function HakimoSpinner() {
  return (
    <span className="dark:text-hakimo-yellow text-primary-700">
      <Spinner />
    </span>
  );
}

export default HakimoSpinner;
