import { SourceSystemFilterValue } from '@hakimo-ui/hakimo/types';
import { MultiSelect } from '@hakimo-ui/hakimo/ui-elements';
import { getMultiSelectItems, selectHelpers } from '@hakimo-ui/hakimo/util';
import { Selectable } from '@hakimo-ui/shared/ui-base';

interface Props {
  value: SourceSystemFilterValue;
  onChange: (value: SourceSystemFilterValue) => void;
  options?: Selectable[];
}

function SourceSystemFilter(props: Props) {
  const { value, onChange, options } = props;
  const getItems = (query: string) => {
    const { filterPredicate, isEqual } = selectHelpers;
    return getMultiSelectItems(query, value, filterPredicate, isEqual, {
      staticItems: options || [],
    });
  };

  return (
    <MultiSelect
      label="Alarm Source System"
      value={value}
      onChange={onChange}
      getItems={getItems}
      displayValue={(item) => item?.name}
      id={(item) => item.id}
    />
  );
}

export default SourceSystemFilter;
