import { useUpdateAlarm } from '@hakimo-ui/hakimo/data-access';
import { toast } from '@hakimo-ui/hakimo/util';
import {
  Alert,
  Button,
  Checkboxes,
  Modal,
  Radio,
  Textarea,
} from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import {
  DisabledField,
  Feedback,
  feedbackCheckboxItems,
  getAlarmUpdateDTO,
  isValidFeedback,
  RadioItem,
  radioItems,
} from './util';

interface Props {
  alarmId: string;
  onClose: () => void;
}

export function AlarmFeedback(props: Props) {
  const { alarmId, onClose } = props;
  const [output, setOutput] = useState<RadioItem>(radioItems[0]);
  const [comment, setComment] = useState('');
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [disabled, setDisabled] = useState<DisabledField>();

  const mutation = useUpdateAlarm(alarmId, () => {
    toast('Feedback added', { type: 'success' });
    onClose();
  });

  const onSubmit = () => {
    const update = getAlarmUpdateDTO(output, comment, feedbacks);
    mutation.mutate(update);
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        loading={mutation.isLoading}
        disabled={mutation.isLoading || !isValidFeedback(feedbacks, comment)}
        trackingInfo="Submit add alarm feedback"
      >
        Submit
      </Button>
      <Button onClick={onClose} trackingInfo="Cancel add alarm feedback">
        Cancel
      </Button>
    </>
  );

  const onChangeCheckboxValue = (value: Feedback[]) => {
    if (value.some((item) => item.value === 'tapTooHigh')) {
      setDisabled('tapTooLow');
    } else if (value.some((item) => item.value === 'tapTooLow')) {
      setDisabled('tapTooHigh');
    } else {
      setDisabled(undefined);
    }

    setFeedbacks(value);
  };

  const checkboxItems = feedbackCheckboxItems.map((item) => {
    return {
      ...item,
      disabled: item.value === disabled,
    };
  });

  const onChangeOutput = (value: RadioItem) => {
    if (value.id === 'correct') {
      setFeedbacks([]);
      setDisabled(undefined);
    }
    setComment('');
    setOutput(value);
  };

  return (
    <Modal open onClose={onClose} title="Add Feedback" footer={actions}>
      {mutation.isError && (
        <div className="px-8 pt-2">
          <Alert type="error">{mutation.error.message}</Alert>
        </div>
      )}
      <div className="w-[30rem] p-8">
        <h2 className="dark:text-dark-text mb-1 text-sm text-gray-700">
          Hakimo output
        </h2>
        <Radio
          items={radioItems}
          value={output}
          onChange={onChangeOutput}
          id={(item) => item.id}
          displayValue={(item) => item.name}
        />
        {output.id === 'incorrect' && (
          <div className="mt-6">
            <h2 className="dark:text-dark-text mb-1 text-sm text-gray-700">
              Additional Feedback
            </h2>
            <Checkboxes
              items={checkboxItems}
              value={feedbacks}
              onChange={onChangeCheckboxValue}
            />
          </div>
        )}
        <div className="mt-6">
          <Textarea
            label="Add your feedback"
            value={comment}
            onChange={(value) => setComment(value)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AlarmFeedback;
