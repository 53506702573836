import { LocationAlarmUpdate } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateLocationAlarm(
  alarmId: string,
  onSuccess?: (data: null, variables: LocationAlarmUpdate | null) => void
) {
  const url = `/v2/orm/location_alarms/update/${alarmId}`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, LocationAlarmUpdate | null>(request, {
    onSuccessInvalidationKeys: [['locationAlarms']],
    onSuccess,
  });
}

export default useUpdateLocationAlarm;
