import { AddDoorGroupRequestDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useAddDoorGroup(onSuccess: () => void) {
  const url = `/v2/orm/door_groups`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, AddDoorGroupRequestDTO>(request, {
    onSuccessInvalidationKeys: [['door_groups']],
    onSuccess,
  });
}

export default useAddDoorGroup;
