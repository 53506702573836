import { AlarmUpdatesDTO, ReducedAlarm } from '@hakimo-ui/hakimo/types';
import {
  BasicPanel,
  QueryResult,
  Timeline,
} from '@hakimo-ui/hakimo/ui-elements';
import { useCanAddAlarmComment } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Tooltip } from '@hakimo-ui/shared/ui-base';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { UseQueryResult } from 'react-query';
import { CommentModal } from './comment-modal/CommentModal';
import RegularAlarmUpdates from './RegularAlarmUpdates';
import SharedAlarmUpdates from './SharedAlarmUpdates';
import { getUpdateEvents } from './util';
interface Props {
  alarm: ReducedAlarm;
  sharedToken?: string;
}

export function AlarmUpdates(props: Props) {
  const { alarm, sharedToken } = props;
  const [open, setOpen] = useState(false);
  const canComment = useCanAddAlarmComment();

  const addComment = canComment ? (
    <div className="mr-6">
      <Tooltip text="Add comment" position="left">
        <Button
          variant="icon"
          onClick={() => setOpen(true)}
          trackingInfo="Open add alarm comments modal"
        >
          <ChatBubbleBottomCenterTextIcon className="w-5" />
        </Button>
      </Tooltip>
    </div>
  ) : null;

  const renderContent = (
    queryResult: UseQueryResult<AlarmUpdatesDTO, Error>
  ) => {
    return (
      <QueryResult queryResult={queryResult}>
        {(data) => {
          const events = getUpdateEvents(data);

          return (
            <div className="max-h-[30rem] overflow-auto">
              {events.length > 0 ? (
                <Timeline events={events} />
              ) : (
                <div className="p-6">
                  <Alert>No updates</Alert>
                </div>
              )}
              {open && (
                <CommentModal
                  open={true}
                  onClose={() => setOpen(false)}
                  alarm={alarm}
                />
              )}
            </div>
          );
        }}
      </QueryResult>
    );
  };

  return (
    <BasicPanel title="Alarm Updates" secondary={addComment}>
      {sharedToken ? (
        <SharedAlarmUpdates sharedToken={sharedToken}>
          {renderContent}
        </SharedAlarmUpdates>
      ) : (
        <RegularAlarmUpdates alarmId={alarm.id}>
          {renderContent}
        </RegularAlarmUpdates>
      )}
    </BasicPanel>
  );
}

export default AlarmUpdates;
