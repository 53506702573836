import { hasPermission, useUser } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import HakimoNavLink from '../hakimo/HakimoNavLink';
import { navItems } from '../nav-items';
import Links from './Links';
import User from './User';

interface Props {
  collapsed?: boolean;
  onClick?: () => void;
}
function SidebarContent(props: Props) {
  const { collapsed, onClick = () => null } = props;
  const user = useUser();

  const items = navItems.filter((item) =>
    item.requiredPermissions.every((perm) => hasPermission(user, perm))
  );

  return (
    <div
      className={clsx(
        !collapsed ? 'space-y-8 px-8' : 'space-y-8 px-2 text-center'
      )}
    >
      <HakimoNavLink short={collapsed} />
      {!collapsed ? (
        <div className="-mx-3 space-y-4">
          <User />
          <Links items={items} onClick={onClick} />
        </div>
      ) : (
        <>
          <User short />
          <Links short items={items} onClick={onClick} />
        </>
      )}
    </div>
  );
}

export default SidebarContent;
