import { AlarmListFilters, TimePeriod } from '@hakimo-ui/hakimo/types';

export interface State {
  alarmListFilters: AlarmListFilters;
}

export const initialState: State = {
  alarmListFilters: {
    time: {
      period: TimePeriod.PAST_24_HOURS,
      customRangeStart: '',
      customRangeEnd: '',
    },
    sourceSystem: [],
    location: { values: [], negative: false },
    sourceEntity: { values: [], negative: false },
    status: { values: [], negative: false },
    type: { values: [], negative: false },
    employee: { values: [], negative: false },
    tenant: { values: [], negative: false },
  },
};
