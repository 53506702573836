import { useAddDoorGroup } from '@hakimo-ui/hakimo/data-access';
import { AddDoorGroupRequestDTO } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import {
  Alert,
  Button,
  InputField,
  Modal,
  Textarea,
} from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

interface Props {
  onClose: () => void;
}

const initialFormValue: AddDoorGroupRequestDTO = {
  name: '',
  description: '',
};

export function AddDoorGroup(props: Props) {
  const { onClose } = props;
  const [formValue, setFormValue] =
    useState<AddDoorGroupRequestDTO>(initialFormValue);

  const addDoorGroupMutation = useAddDoorGroup(() => {
    toast('Door Group Added', { type: 'success' });
    onClose();
  });

  const valuePending = Object.entries(formValue).some(
    ([_, value]: [string, string]) => {
      return value === '';
    }
  );

  const onSubmit = () => {
    addDoorGroupMutation.mutate(formValue);
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={valuePending || addDoorGroupMutation.isLoading}
        trackingInfo="Submit add door group"
        loading={addDoorGroupMutation.isLoading}
      >
        Submit
      </Button>
      <Button onClick={onClose} trackingInfo="Cancel add door group">
        Cancel
      </Button>
    </>
  );

  const valueUpdater =
    (name: keyof AddDoorGroupRequestDTO) =>
    (arg: React.ChangeEvent<HTMLInputElement> | string) => {
      setFormValue((value) => ({
        ...value,
        [name]: typeof arg === 'string' ? arg : arg.target.value,
      }));
    };

  return (
    <Modal title="Add New Door Group" open onClose={onClose} footer={actions}>
      {addDoorGroupMutation.isError && (
        <div className="mt-4 px-8">
          <Alert type="error">{addDoorGroupMutation.error.message}</Alert>
        </div>
      )}
      <div className="w-[36rem] space-y-4 p-8">
        <InputField
          type="text"
          label="Name"
          value={formValue.name}
          onChange={valueUpdater('name')}
        />
        <Textarea
          rows={2}
          label="Description"
          value={formValue.description}
          onChange={valueUpdater('description')}
        />
      </div>
    </Modal>
  );
}

export default AddDoorGroup;
