import { Button } from '@hakimo-ui/shared/ui-base';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  title: string;
  subtitle?: ReactNode;
  children: ReactNode;
  secondary?: ReactNode;
  fullWidth?: boolean;
  onClickBack?: () => void;
}

export function Page(props: Props) {
  const {
    title,
    subtitle,
    children,
    secondary,
    fullWidth = false,
    onClickBack,
  } = props;

  return (
    <main className="dark:bg-dark-bg dark:text-dark-text h-full overflow-y-auto bg-gray-50">
      <div
        className={clsx(
          'mx-auto h-full px-4 sm:px-6 md:px-8',
          !fullWidth && 'max-w-container'
        )}
      >
        <div className="flex h-full flex-col xl:mx-20">
          <div className="flex items-center justify-between">
            <div className="flex items-center py-6">
              {onClickBack && (
                <div className="mr-4">
                  <Button
                    variant="icon"
                    onClick={onClickBack}
                    trackingInfo="Go back from page"
                  >
                    <ArrowLeftIcon className="w-6" />
                  </Button>
                </div>
              )}
              <div>
                <h1 className="dark:text-dark-text text-2xl leading-8 text-gray-800">
                  {title}
                </h1>
                <h2 className="pt-1 text-base text-gray-600 dark:text-gray-300">
                  {subtitle}
                </h2>
              </div>
            </div>
            <div>{secondary}</div>
          </div>
          <div className="flex min-h-0 flex-1 flex-col pb-8">{children}</div>
        </div>
      </div>
    </main>
  );
}

export default Page;
