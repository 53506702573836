import { UserListFilters } from '@hakimo-ui/hakimo/types';
import { Button, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { ReactElement } from 'react';
import { isNotDefaultFilter } from '../utils';

interface Props {
  isRefetching: boolean;
  isLoading: boolean;
  userListFilters: UserListFilters;
  onOpenAddUserModal: () => void;
  onOpenFilterPanel: () => void;
}
function UserListHeader(props: Props): ReactElement {
  const {
    isRefetching,
    userListFilters,
    onOpenFilterPanel,
    onOpenAddUserModal,
  } = props;

  return (
    <div className="dark:bg-dark-bg flex items-center justify-between border-b bg-white p-4 dark:border-0">
      <Button variant="primary" onClick={onOpenAddUserModal}>
        Add User
        <span className="sr-only">Add User</span>
      </Button>
      <div className="flex items-center gap-2">
        {isRefetching && <HakimoSpinner />}
        <Button
          variant="icon"
          badge={isNotDefaultFilter(userListFilters)}
          onClick={onOpenFilterPanel}
        >
          <span className="sr-only">Open filters panel</span>
          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}

export default UserListHeader;
