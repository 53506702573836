import { DCPLabel, Marker } from '@hakimo-ui/hakimo/types';

export const ADD_DOOR_MARKER = 'ADD_DOOR_MARKER';
export const ADD_FLOOR_MARKER = 'ADD_FLOOR_MARKER';
export const UPDATE_LABEL = 'UPDATE_LABEL';

export interface AddDoorMarkerAction {
  type: typeof ADD_DOOR_MARKER;
  payload: Marker;
}

export interface AddFloorMarkerAction {
  type: typeof ADD_FLOOR_MARKER;
  payload: Marker;
}

export interface UpdateLabelAction {
  type: typeof UPDATE_LABEL;
  payload: Partial<DCPLabel>;
}
export type Action =
  | AddDoorMarkerAction
  | AddFloorMarkerAction
  | UpdateLabelAction;
