import { Button } from '@hakimo-ui/shared/ui-base';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import AlarmFeedback from './alarm-feedback/AlarmFeedback';
import BugReportModal from './bug-report-modal/BugReportModal';
import PageMenu from './page-menu/PageMenu';
import ShareAlarm from './share-alarm/ShareAlarm';

interface Props {
  alarmId: string;
  onRefresh: () => void;
  sharedToken?: string;
}

export function PageActions(props: Props) {
  const { alarmId, sharedToken, onRefresh } = props;
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openShareAlarm, setOpenShareAlarm] = useState(false);
  const [openBugReport, setOpenBugReport] = useState(false);
  return (
    <div className="flex">
      <Button variant="icon" onClick={onRefresh}>
        <ArrowPathIcon className="h-5 w-5" />
      </Button>
      {!sharedToken && (
        <div>
          <PageMenu
            onOpenFeedback={() => setOpenFeedback(true)}
            onOpenShareAlarm={() => setOpenShareAlarm(true)}
            onOpenBugReport={() => setOpenBugReport(true)}
          />
          {openFeedback && (
            <AlarmFeedback
              alarmId={alarmId}
              onClose={() => setOpenFeedback(false)}
            />
          )}
          {openShareAlarm && (
            <ShareAlarm
              alarmId={alarmId}
              onClose={() => setOpenShareAlarm(false)}
            />
          )}
          {openBugReport && (
            <BugReportModal
              alarmId={alarmId}
              onClose={() => setOpenBugReport(false)}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default PageActions;
