import { DCPLabel, Marker } from '@hakimo-ui/hakimo/types';
import { scaleLinear } from 'd3-scale';
import { BOTTOM_LABELLING_LIMIT_PERCENT, DOOR_HEIGHT_LIMIT } from './constants';

export function normalizeLabel(label: DCPLabel | null): DCPLabel | null {
  if (label === null) {
    return null;
  }

  return {
    ...label,
    doorMarkers: label.doorMarkers
      ? label.doorMarkers.map(normalizeMarker)
      : null,
    floorMarker: label.floorMarker ? normalizeMarker(label.floorMarker) : null,
  };
}

export function normalizeMarker(marker: Marker): Marker {
  return [normalizeMarkerValue(marker[0]), normalizeMarkerValue(marker[1])];
}

export function normalizeMarkerValue(val: number) {
  return Math.max(0, Math.ceil(val));
}

export function getImageCoordinatesLabel(marker?: Marker | null) {
  if (!marker) {
    return '-';
  }
  return `${normalizeMarkerValue(marker[0])}, ${normalizeMarkerValue(
    marker[1]
  )}`;
}

export function clientMarkerToImageMarker(
  image: HTMLImageElement,
  marker: Marker
): Marker {
  const imgRect = image.getBoundingClientRect();

  const scaleX = scaleLinear()
    .domain([0, imgRect.width])
    .range([0, image.naturalWidth]);

  const scaleY = scaleLinear()
    .domain([0, imgRect.height])
    .range([0, image.naturalHeight]);

  const markerImageRect = [marker[0] - imgRect.left, marker[1] - imgRect.top];

  const markerImage: Marker = [
    scaleX(markerImageRect[0]),
    scaleY(markerImageRect[1]),
  ];

  return markerImage;
}

export function imageMarkerToClientMarker(
  image: HTMLImageElement,
  marker: Marker
): Marker {
  const imgRect = image.getBoundingClientRect();

  const scaleX = scaleLinear()
    .domain([0, image.naturalWidth])
    .range([0, imgRect.width]);

  const scaleY = scaleLinear()
    .domain([0, image.naturalHeight])
    .range([0, imgRect.height]);

  const markerImageRect: Marker = [scaleX(marker[0]), scaleY(marker[1])];
  const markerClient: Marker = [markerImageRect[0], markerImageRect[1]];
  return markerClient;
}

export function checkValidity(label: DCPLabel): boolean {
  if (label.doorMarkers && label.doorMarkers.length > 0) {
    return Boolean(
      label.floorMarker &&
        label.doorMarkers.length === 4 &&
        (label.cameraPosition === 'secure' ||
          label.cameraPosition === 'unsecure')
    );
  }
  return true;
}

function identifyDoorBottomCoords(doorMarkers: Marker[]): Marker[] {
  const doorCoords = [...doorMarkers];
  return doorCoords.sort((a, b) => b[1] - a[1]).slice(0, 2);
}

function validateDoorSize(doorMarkers: Marker[]): boolean {
  const doorCoords = [...doorMarkers];
  const [, minY] = doorCoords.sort((a, b) => a[1] - b[1])[0];
  const [, maxY] = doorCoords.sort((a, b) => b[1] - a[1])[0];
  const doorHeight = maxY - minY;
  return doorHeight > DOOR_HEIGHT_LIMIT;
}

function validateDoorBottomLabelling(
  doorBottomCoords: Marker[],
  imageHeight: number
): boolean {
  const heightLimit = (imageHeight * BOTTOM_LABELLING_LIMIT_PERCENT) / 100;
  return !doorBottomCoords.some((coords) => {
    const [, doorHeight] = coords;
    return imageHeight - doorHeight < heightLimit;
  });
}

export function validateDoorAnnotation(label: DCPLabel): boolean {
  const { doorMarkers, labellingResolution } = label;
  const resolutionHeight = labellingResolution?.at(1) || 0;
  const isDoorLabellingInProgress = doorMarkers && doorMarkers.length !== 4;

  if (isDoorLabellingInProgress || !resolutionHeight) {
    return true;
  }

  const doorBottomCoords = identifyDoorBottomCoords(doorMarkers || []);

  const isBottomLabellingValid = validateDoorBottomLabelling(
    doorBottomCoords,
    resolutionHeight
  );
  const isDoorSizeValid = validateDoorSize(doorMarkers || []);

  return isBottomLabellingValid && isDoorSizeValid;
}
