import {
  useCanAddAlarmFeedback,
  useCanReportBug,
} from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Fragment } from 'react';

interface Props {
  onOpenFeedback: () => void;
  onOpenShareAlarm: () => void;
  onOpenBugReport: () => void;
}

export function PageMenu(props: Props) {
  const { onOpenFeedback, onOpenShareAlarm, onOpenBugReport } = props;
  const canReportBug = useCanReportBug();
  const canAddFeedback = useCanAddAlarmFeedback();

  return (
    <div className="flex items-center gap-2">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button as="div">
            <Button variant="icon" trackingInfo="Open alarm actions menu">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="dark:bg-dark-bg absolute right-0 z-10 mt-2 min-w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-gray-700">
            <div className="py-1">
              <Menu.Item as="div">
                {({ active }) => (
                  <MenuItem
                    text="Share Alarm"
                    active={active}
                    onClick={onOpenShareAlarm}
                  />
                )}
              </Menu.Item>
              {canAddFeedback && (
                <Menu.Item as="div">
                  {({ active }) => (
                    <MenuItem
                      text="Add feedback"
                      active={active}
                      onClick={onOpenFeedback}
                    />
                  )}
                </Menu.Item>
              )}
              {canReportBug && (
                <Menu.Item as="div">
                  {({ active }) => (
                    <MenuItem
                      text="Report Bug"
                      active={active}
                      onClick={onOpenBugReport}
                    />
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

interface MenuItemProps {
  text: string;
  active: boolean;
  onClick: () => void;
}

function MenuItem(props: MenuItemProps) {
  const { text, active, onClick } = props;

  return (
    <button
      className={clsx(
        active
          ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-300'
          : 'dark:text-dark-text text-gray-700',
        'block w-full px-4 py-2 text-left text-sm'
      )}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default PageMenu;
