import {
  useAddDCP,
  useCamerasByName,
  useDoorsByName,
} from '@hakimo-ui/hakimo/data-access';
import { SelectAsync } from '@hakimo-ui/hakimo/ui-elements';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal, Selectable } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
interface Props {
  open: boolean;
  onClose: () => void;
}

function AddMappingModal(props: Props) {
  const { open, onClose } = props;
  const [door, setDoor] = useState<Selectable>();
  const [camera, setCamera] = useState<Selectable>();
  const addMappingMutation = useAddDCP(() => {
    onClose();
    toast('Mapping added', { type: 'success' });
  });
  const getDoorsByName = useDoorsByName();
  const getCamerasByName = useCamerasByName();

  const onCloseCb = () => {
    onClose();
    addMappingMutation.reset();
    setDoor(undefined);
    setCamera(undefined);
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={() => {
          addMappingMutation.mutate({
            // OR conditions not needed because button is disabled
            // if door or camera does not exist - needed for typescript
            // compiler
            doorName: door?.name || '',
            cameraName: camera?.name || '',
          });
        }}
        // Only enable if input exists
        disabled={door && camera ? false : true}
        loading={addMappingMutation.isLoading}
        trackingInfo="Submit add door camera mapping"
      >
        Submit
      </Button>
      <Button onClick={onCloseCb} trackingInfo="Cancel add door camera mapping">
        Cancel
      </Button>
    </>
  );
  return (
    <Modal title="Add Mapping" open={open} onClose={onCloseCb} footer={actions}>
      <div className="w-[36rem] p-8">
        {addMappingMutation.isError ? (
          <div className="py-4 px-8">
            <Alert type="error">{addMappingMutation.error.message} </Alert>
          </div>
        ) : null}
        <div className="space-y-4">
          <SelectAsync
            label="Door"
            value={door}
            onChange={setDoor}
            onChangeQuery={getDoorsByName}
            displayValue={(item) => (item ? item.name : '')}
            id={(item) => item.id}
          />
          <SelectAsync
            label="Camera"
            value={camera}
            onChange={setCamera}
            onChangeQuery={getCamerasByName}
            displayValue={(item) => (item ? item.name : '')}
            id={(item) => item.id}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddMappingModal;
