import { useEffect } from 'react';
import { getHls } from './hls-helper';
import Hls from 'hls.js';

export function useHLS(
  videoRef: React.RefObject<HTMLVideoElement>,
  videoPath: string
) {
  useEffect(() => {
    const videoElem = videoRef.current;

    if (!videoElem) {
      return;
    }

    let hlsPromise: Promise<Hls | undefined>;

    if (videoElem.canPlayType('application/vnd.apple.mpegurl')) {
      videoElem.src = videoPath;
      // If no native HLS support, check if HLS.js is supported
    } else {
      let url;
      try {
        url = new URL(videoPath);
      } catch (error) {
        if (error instanceof Error) {
          error.message = error.message + `: ${videoPath}`;
        }
        throw error;
      }

      hlsPromise = getHls(url.search).then((hls) => {
        if (hls) {
          hls.loadSource(videoPath);
          hls.attachMedia(videoElem);
        }

        return hls;
      });
    }

    return () => {
      if (hlsPromise) {
        onUnmount(hlsPromise);
      }
    };
  }, [videoRef, videoPath]);
}

function onUnmount(hlsPromise: Promise<Hls | undefined>) {
  hlsPromise.then((hls) => {
    if (hls) {
      hls.destroy();
    }
  });
}
