import { Route, Routes } from 'react-router-dom';
import LocationList from './location-list/LocationList';

export function LocationRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LocationList />} />
    </Routes>
  );
}

export default LocationRoutes;
