import produce from 'immer';
import { Reducer } from 'react';
import { Action } from './actions';
import { State } from './state';

const reducer: Reducer<State, Action> = produce((draft, action) => {
  switch (action.type) {
    case 'UPDATE_FILTERS':
      draft.alarmListFilters = action.payload;
      break;
    case 'SET_PAGE':
      draft.alarmListPage = action.payload;
      break;
    default:
      throw new Error();
  }
});

export default reducer;
