import { AlarmListFilters } from '@hakimo-ui/hakimo/types';
import { useEffect, useState } from 'react';
import { getSearchParams } from '../../alarm-list/util';

export function useSearchParams(
  page: number,
  pageSize: number,
  filters: AlarmListFilters
): [boolean, string] {
  const [initializing, setInitializing] = useState(true);
  const [searchParams, setSearchParams] = useState('');

  useEffect(() => {
    function tick() {
      const sParams = getSearchParams(page, pageSize, filters);
      setSearchParams(sParams);
      setInitializing(false);
    }

    const timer = window.setInterval(tick, 15000);
    tick();

    return () => {
      clearInterval(timer);
    };
  }, [page, pageSize, filters]);

  return [initializing, searchParams];
}
