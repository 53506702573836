import { useLogin } from '@hakimo-ui/hakimo/data-access';
import { HakimoLogo } from '@hakimo-ui/hakimo/ui-navigation';
import { toast, useLocalStorage } from '@hakimo-ui/hakimo/util';
import { Alert, Button, InputField } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [currentToken, setToken] = useLocalStorage('token');

  const mutation = useLogin(async ({ token }) => {
    toast('Login successful', { type: 'success' });
    setToken(token);
    const searchParams = new URLSearchParams(location.search);
    navigate(searchParams.get('redirectTo') || '/');
  });

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    mutation.mutate({
      email,
      password,
    });
  };

  if (currentToken) {
    return <Navigate to="/" />;
  }

  return (
    <div className="relative flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <img
        className="absolute right-0 top-0 block h-64 w-64"
        src="/assets/hakimo-arc.svg"
        alt="Hakimo arc"
      />
      <div className="w-full max-w-md space-y-8">
        <div>
          <div className="flex justify-center">
            <HakimoLogo full lg />
          </div>
        </div>
        {mutation.isError && (
          <Alert type="error">{mutation.error.message}</Alert>
        )}
        <form className="mt-8 space-y-6" onSubmit={onSubmit}>
          <div className="space-y-4 rounded-md shadow-sm">
            <div>
              <InputField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                required
              />
            </div>
            <div>
              <InputField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
              />
            </div>
          </div>
          <div>
            <Button
              block
              type="submit"
              variant="primary"
              loading={mutation.isLoading}
              disabled={mutation.isLoading}
            >
              Sign In
            </Button>
          </div>
          <div className="flex justify-center">
            <Button variant="link" disabled>
              Forgot your password?
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
