import { Selectable } from '@hakimo-ui/shared/ui-base';
import {
  EmployeeFilterValue,
  LocationFilterValue,
  SourceEntityNameFilterValue,
  TenantFilterValue,
} from '../common';
import { AlarmStatus } from './alarms';

export interface AlarmListFilters {
  status: StatusFilterValue;
  time: TimeFilterValue;
  sourceSystem: SourceSystemFilterValue;
  location: LocationFilterValue;
  sourceEntity: SourceEntityNameFilterValue;
  type: AlarmTypeFilterValue;
  employee: EmployeeFilterValue;
  tenant: TenantFilterValue;
}

export type StatusFilterValue = { values: AlarmStatus[]; negative: boolean };
export type AlarmTypeFilterValue = { values: Selectable[]; negative: boolean };

export interface TimeFilterValue {
  period: TimePeriod;
  /**
   * A string representing a date in `YYYY-MM-DDThh:mm` format
   */
  customRangeStart: string;
  /**
   * A string representing a date in `YYYY-MM-DDThh:mm` format
   */
  customRangeEnd: string;
}

export type SourceSystemFilterValue = Selectable[];

export interface Option<T extends string = string> {
  id: string;
  name: T;
}

export enum TimePeriod {
  ALL_TIME = 'All Time',
  PAST_MINUTE = 'Past Minute',
  PAST_HOUR = 'Past Hour',
  PAST_SIX_HOURS = 'Past 6 Hours',
  PAST_24_HOURS = 'Past 24 Hours',
  PAST_WEEK = 'Past Week',
  PAST_MONTH = 'Past Month',
  PAST_THREE_MONTHS = 'Past 3 Months',
  CUSTOM_RANGE = 'Custom Range',
}

export type AlarmFilters = Omit<AlarmListFilters, 'time'>;

interface BaseNamedSearch {
  id: string;
  name: string;
  pinned: boolean;
  filters: AlarmFilters;
}

interface SystemNamedSearch extends BaseNamedSearch {
  group: 'system';
  type: 'pending' | 'inprogress' | 'all';
}

interface CustomNamedSearch extends BaseNamedSearch {
  group: 'custom';
  type: 'new' | 'saved';
}

export type NamedSearch = SystemNamedSearch | CustomNamedSearch;

export interface NamedSearchesDTO {
  items: NamedSearch[];
  pinned: string[];
}

export type ItemCountNamedSearches = Record<string, number>;
