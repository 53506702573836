import { useAlarmMedia } from '@hakimo-ui/hakimo/data-access';
import { environment } from '@hakimo-ui/hakimo/environments';
import { Alarm, AlarmMedia as AlarmMediaType } from '@hakimo-ui/hakimo/types';
import { BasicPanel, QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { useCanViewLiveCamera } from '@hakimo-ui/hakimo/util';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import AlarmMediaActions from './actions/AlarmMediaActions';
import AlarmImage from './alarm-image/AlarmImage';
import AlarmVideo from './alarm-video/AlarmVideo';
import { getRemainingMSecBeforeExpiration } from './alarm-video/util';
import LiveVideo from './live-video/LiveVideo';
import { getAlert } from './util';

interface Props {
  sharedToken?: string;
  alarm: Alarm;
}

const isAirgap = environment.name === 'AIRGAP';

export function AlarmMedia(props: Props) {
  const { alarm, sharedToken } = props;
  const [debug, setDebug] = useState(false);
  const canViewLiveVideo = useCanViewLiveCamera();

  const getRefetchIntervalForVideoUrlExpiration = (
    data: AlarmMediaType | undefined
  ): number | false => {
    return isAirgap
      ? false
      : data?.videoPath
      ? getRemainingMSecBeforeExpiration(data?.videoPath)
      : false;
  };

  const queryResult = useAlarmMedia({
    id: alarm.id,
    debug,
    sharedToken,
    refetchInterval: getRefetchIntervalForVideoUrlExpiration,
  });

  const [live, setLive] = useState(false);

  const onErrorLoadingVideo = () => {
    Sentry.captureMessage('Error loading video', {
      extra: { alarm },
    });
  };

  // Only camera alarms are supported for now
  const cameraId =
    alarm.sourceEntity?.type === 'CAMERA' ? alarm.sourceEntity.id : null;

  const renderContent = (
    <>
      {canViewLiveVideo && <LiveVideo cameraId={cameraId} open={live} />}
      {!live && (
        <QueryResult queryResult={queryResult}>
          {(data) =>
            data.videoPath ? (
              <AlarmVideo
                videoPath={data.videoPath}
                videoDetails={data.videoDetails}
                onErrorLoadingVideo={onErrorLoadingVideo}
              />
            ) : data.imagePath ? (
              <AlarmImage imagePath={data.imagePath} />
            ) : (
              getAlert('Media not available')
            )
          }
        </QueryResult>
      )}
    </>
  );

  const actions = (
    <AlarmMediaActions
      cameraId={cameraId}
      alarmId={alarm.id}
      debug={debug}
      live={live}
      sharedToken={sharedToken}
      onToggleDebug={setDebug}
      onToggleLive={setLive}
    />
  );

  return (
    <BasicPanel title="Alarm Media" secondary={actions}>
      {renderContent}
    </BasicPanel>
  );
}

export default AlarmMedia;
