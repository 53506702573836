import { AlarmListFilters } from '@hakimo-ui/hakimo/types';
import { SlideOver } from '@hakimo-ui/hakimo/ui-elements';
import FilterPanelContent from '../filter-panel-content/FilterPanelContent';

interface Props {
  open: boolean;
  filters: AlarmListFilters;
  hideTimeFilter?: boolean;
  onClose: () => void;
  onApplyFilters: (filters: AlarmListFilters) => void;
}

function FilterPanel(props: Props) {
  const {
    open,
    onClose,
    filters,
    hideTimeFilter = false,
    onApplyFilters,
  } = props;

  return (
    <SlideOver title="Alarm Filters" open={open} onClose={onClose}>
      <FilterPanelContent
        filters={filters}
        hideTimeFilter={hideTimeFilter}
        onApplyFilters={onApplyFilters}
      />
    </SlideOver>
  );
}

export default FilterPanel;
