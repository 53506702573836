import { useLocationAlarms } from '@hakimo-ui/hakimo/data-access';
import {
  AlarmListFilters,
  LocationAlarm,
  LocationAlarmsDTO,
} from '@hakimo-ui/hakimo/types';
import { LoadingIndicator } from '@hakimo-ui/hakimo/ui-elements';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { ReactElement } from 'react';
import { useSearchParams } from '../alarm-monitoring/list-view/useSearchParams';

export interface Props {
  page: number;
  pageSize: number;
  filters: AlarmListFilters;
  onData: (data: LocationAlarmsDTO) => void;
  children: (alarms: LocationAlarm[]) => ReactElement;
}

export function LocationAlarmsData(props: Props) {
  const { page, pageSize, filters, onData, children } = props;
  const [initalizing, searchParams] = useSearchParams(page, pageSize, filters);

  const { isError, error, data } = useLocationAlarms(
    searchParams,
    0,
    onData,
    initalizing,
    true
  );

  if (initalizing) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <Alert>{error.message}</Alert>;
  }

  return children(data?.items ?? []);
}

export default LocationAlarmsData;
