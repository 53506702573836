import * as Sentry from '@sentry/react';
import { createRef, useEffect } from 'react';
const DEFAULT_AUDIO = 'notification-sound.mp3';
interface Props {
  enabled: boolean;
  audioFileName?: string;
}
export function NotificationSound(props: Props) {
  const { enabled, audioFileName = DEFAULT_AUDIO } = props;
  const audioRef = createRef<HTMLAudioElement>();
  useEffect(() => {
    if (audioRef.current !== null) {
      audioRef.current.addEventListener('canplaythrough', () => {
        // the audio is now playable; play it if permissions allow
        if (enabled) {
          audioRef.current?.play().catch((e) => {
            alert('Please allow audio playback in your browser');
            Sentry.captureException(e);
          });
        }
      });
      audioRef.current.addEventListener('ended', () => {
        // the audio is now playable; play it if permissions allow
        if (enabled) {
          audioRef.current?.play().catch((e) => {
            alert('Please allow audio playback in your browser');
            Sentry.captureException(e);
          });
        }
      });
    }
  }, [audioRef, enabled]);
  return <audio ref={audioRef} src={`assets/${audioFileName}`}></audio>;
}

export default NotificationSound;
