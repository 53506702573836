import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useSendAudio(
  cameraId: string | null,
  alarmId: string,
  onSuccess?: () => void
) {
  const url = `/v2/orm/camera/audio/${cameraId}?alarmId=${alarmId}`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, FormData>(request, {
    onSuccess,
    getBody: (data) => data,
    onSuccessInvalidationKeys: [['alarm_updates', alarmId]],
  });
}

export default useSendAudio;
