import {
  AlarmListFilters,
  AlarmTypeFilterValue,
  EmployeeFilterValue,
  LocationFilterValue,
  SourceEntityNameFilterValue,
  SourceSystemFilterValue,
  StatusFilterValue,
  TenantFilterValue,
  TimeFilterValue,
} from '@hakimo-ui/hakimo/types';
import { FilterFooter } from '@hakimo-ui/hakimo/ui-filters';
import { useEffect, useReducer } from 'react';
import { createUpdateFiltersAction } from '../state/action-creators';
import reducer from '../state/reducer';
import { initialState } from '../state/state';
import FilterPanelSections from './FilterPanelSections';
interface Props {
  filters: AlarmListFilters;
  hideTimeFilter?: boolean;
  onApplyFilters: (filters: AlarmListFilters) => void;
}

export function FilterPanelContent(props: Props) {
  const { filters, hideTimeFilter = false, onApplyFilters } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const { alarmListFilters } = state;

  useEffect(() => {
    dispatch(createUpdateFiltersAction(filters));
  }, [filters]);

  const onChangeStatusFilterValue = (value: StatusFilterValue) => {
    dispatch(createUpdateFiltersAction({ status: value }));
  };

  const onChangeTimeFilterValue = (value: TimeFilterValue) => {
    dispatch(createUpdateFiltersAction({ time: value }));
  };

  const onChangeSourceSystemFilterValue = (value: SourceSystemFilterValue) => {
    dispatch(createUpdateFiltersAction({ sourceSystem: value }));
  };

  const onChangeLocationFilterValue = (value: LocationFilterValue) => {
    dispatch(createUpdateFiltersAction({ location: value }));
  };
  const onChangeSourceEntityFilterValue = (
    value: SourceEntityNameFilterValue
  ) => {
    dispatch(createUpdateFiltersAction({ sourceEntity: value }));
  };
  const onChangeTypeFilterValue = (value: AlarmTypeFilterValue) => {
    dispatch(createUpdateFiltersAction({ type: value }));
  };
  const onChangeEmployeeFilterValue = (value: EmployeeFilterValue) => {
    dispatch(createUpdateFiltersAction({ employee: value }));
  };
  const onChangeTenantFilterValue = (value: TenantFilterValue) => {
    dispatch(createUpdateFiltersAction({ tenant: value }));
  };

  return (
    <div className="space-y-6">
      <div className="dark:divide-dark-border-bg dark:border-dark-border-bg divide-y border-t border-b">
        <FilterPanelSections
          hideTimeFilter={hideTimeFilter}
          alarmListFilters={alarmListFilters}
          onChangeStatusFilterValue={onChangeStatusFilterValue}
          onChangeTimeFilterValue={onChangeTimeFilterValue}
          onChangeEmployeeFilterValue={onChangeEmployeeFilterValue}
          onChangeLocationFilterValue={onChangeLocationFilterValue}
          onChangeSourceEntityFilterValue={onChangeSourceEntityFilterValue}
          onChangeSourceSystemFilterValue={onChangeSourceSystemFilterValue}
          onChangeTenantFilterValue={onChangeTenantFilterValue}
          onChangeTypeFilterValue={onChangeTypeFilterValue}
        />
      </div>
      <FilterFooter
        onApply={() => onApplyFilters(alarmListFilters)}
        onReset={() =>
          dispatch(createUpdateFiltersAction(initialState.alarmListFilters))
        }
        trackingInfo="alarms"
      />
    </div>
  );
}

export default FilterPanelContent;
