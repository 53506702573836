import { AlarmFilters } from '@hakimo-ui/hakimo/types';
import { getFilterTags } from './util';

interface Props {
  filters: AlarmFilters;
}

export function FilterTags(props: Props) {
  const { filters } = props;
  const tags = getFilterTags(filters);

  return <div className="flex items-center gap-2">{tags}</div>;
}

export default FilterTags;
