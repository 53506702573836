import {
  NavbarMobile,
  Sidebar,
  SidebarMobile,
} from '@hakimo-ui/hakimo/ui-navigation';
import { useLocalStorage } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';

interface Props {
  children: ReactElement;
}

export function RootLayout(props: Props) {
  const { children } = props;
  const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useLocalStorage<boolean>(
    'sidebarOpen',
    true
  );

  return (
    <div className="dark:bg-dark-bg h-full dark:text-white">
      <SidebarMobile
        open={sidebarMobileOpen}
        onClose={() => setSidebarMobileOpen(false)}
      />
      <Sidebar
        open={sidebarOpen || false}
        expandSidebar={() => setSidebarOpen(true)}
        collapseSidebar={() => setSidebarOpen(false)}
      />
      <NavbarMobile onOpenSidebar={() => setSidebarMobileOpen(true)} />
      <div
        className={clsx(
          'lg:h-full',
          sidebarOpen ? 'pl-0 lg:pl-64' : 'pl-0 lg:pl-16'
        )}
        data-testid="page-container"
      >
        {children}
      </div>
    </div>
  );
}

export default RootLayout;
