import { CSSProperties } from 'react';

interface Props {
  className?: string;
  style?: CSSProperties;
}

function MarkerIcon(props: Props) {
  const { style, className } = props;

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" />
      <circle cx="12" cy="12" r="3" fill="currentColor" />
    </svg>
  );
}

export default MarkerIcon;
