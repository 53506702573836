import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import {
  User,
  UserEnabledFilter,
  UserListFilters,
} from '@hakimo-ui/hakimo/types';
import { Column, TableData } from '@hakimo-ui/hakimo/ui-table';
import { Button } from '@hakimo-ui/shared/ui-base';
import { PencilIcon } from '@heroicons/react/24/outline';

export const columns: Column[] = [
  {
    id: 'name',
    name: 'Name',
  },
  {
    id: 'email',
    name: 'E-Mail',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'role',
    name: 'Role',
    showAtBreakpoint: 'md',
  },
  {
    id: 'action',
    name: '',
    showAtBreakpoint: 'sm',
  },
];

export function getTableData(
  items: User[],
  onClickEdit: (userId: string) => void
): TableData {
  return {
    columns: columns,
    rows: items.map((item) => ({
      id: item.id,
      cells: [
        { value: item.name },
        { value: item.email },
        { value: item.roles[0] },
        {
          value: (
            <Button variant="icon" onClick={() => onClickEdit(item.id)}>
              <PencilIcon className="w-4" />
            </Button>
          ),
        },
      ],
    })),
  };
}

export function getSearchParams(
  page: number,
  pageSize: number,
  filters: UserListFilters
): string {
  const params = new URLSearchParams();
  params.append('emails', filters.emails.join(SEPARATOR));
  params.append('names', filters.names.join(SEPARATOR));
  params.append('roles', filters.roles.join(SEPARATOR));
  params.append('enabled', getEnabledQueryParams(filters.enabled));
  params.append('page', String(page));
  params.append('pageSize', String(pageSize));
  return params.toString();
}

function getEnabledQueryParams(enabledFilter: UserEnabledFilter): string {
  const ret = [];
  if (enabledFilter.showEnabled) {
    ret.push('true');
  }
  if (enabledFilter.showNotEnabled) {
    ret.push('false');
  }
  return ret.join(SEPARATOR);
}

export function isNotDefaultFilter(filters: UserListFilters): boolean {
  return (
    filters.emails.length > 0 ||
    filters.names.length > 0 ||
    filters.roles.length > 0 ||
    !filters.enabled.showEnabled ||
    !filters.enabled.showNotEnabled
  );
}

export const mapUserFilter = (filters: UserListFilters) => {
  const { emails, names, roles, enabled } = filters;
  const filtersApplied: Record<string, string[] | object> = {};

  emails.length > 0 && (filtersApplied['emails'] = emails);
  names.length > 0 && (filtersApplied['names'] = names);
  roles.length > 0 && (filtersApplied['roles'] = roles);
  filtersApplied['enabled'] = enabled;

  return filtersApplied;
};
