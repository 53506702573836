import { useAlarmTypesByName } from '@hakimo-ui/hakimo/data-access';
import { AlarmTypeFilterValue } from '@hakimo-ui/hakimo/types';
import { MultiSelectFilter } from '@hakimo-ui/hakimo/ui-filters';

interface Props {
  value: AlarmTypeFilterValue;
  label: string;
  enableNegativeFilters?: boolean;
  onChange: (value: AlarmTypeFilterValue) => void;
}

function AlarmTypeFilter(props: Props) {
  const { value, label, enableNegativeFilters = false, onChange } = props;
  const getAlarmTypesByName = useAlarmTypesByName();

  return (
    <MultiSelectFilter
      value={value}
      label={label}
      enableNegativeFilters={enableNegativeFilters}
      onChange={onChange}
      getOptions={getAlarmTypesByName}
    />
  );
}

export default AlarmTypeFilter;
