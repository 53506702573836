import { Alarm } from '@hakimo-ui/hakimo/types';
import { ChevronRightIcon, MapPinIcon } from '@heroicons/react/24/outline';
import AlarmStatusBadge from '../../shared/AlarmStatusBadge';
import { useOnClickRow } from '../../shared/useOnClickRow';
import { tapColors } from '../constants';
import { CameraIcon, DoorIcon, HourglassIcon } from './icons';

interface Props {
  alarm: Alarm;
}

export function ListItem(props: Props) {
  const { alarm } = props;

  const tapBgColor = alarm.tap
    ? tapColors[Math.min(Math.floor(alarm.tap / 10), 9)]
    : null;

  const onClickRow = useOnClickRow();

  return (
    <button
      onClick={(e) => onClickRow(alarm, e)}
      className="dark:bg-ondark-bg-2 dark:hover:outline-ondark-primary grid w-full cursor-pointer grid-cols-12 items-center gap-8 overflow-hidden rounded-md outline-offset-2 hover:outline"
    >
      <span className="col-span-3 flex items-center">
        {tapBgColor ? (
          <span
            className="font-oswald mr-4 w-16 flex-shrink-0 p-3 text-center text-3xl font-light"
            style={{ backgroundColor: tapBgColor }}
          >
            {alarm.tap}
          </span>
        ) : (
          <span className="mr-4 w-16 flex-shrink-0 p-3 text-center text-3xl font-light">
            <span className="inline-block h-5 w-5 flex-shrink-0">
              <HourglassIcon />
            </span>
          </span>
        )}
        <span className="flex flex-col truncate">
          <span
            className="truncate text-left text-sm font-medium"
            title={alarm.type}
          >
            {alarm.type}
          </span>
          <span className="dark:text-ondark-text-2 mt-1 flex items-center gap-2 text-left text-sm">
            <span className="font-medium">
              {new Date(alarm.timestamp).toLocaleTimeString()}
            </span>
            <span className="opacity-50">
              {new Date(alarm.timestamp).toLocaleDateString()}
            </span>
          </span>
        </span>
      </span>
      <span className="col-span-3 text-left">
        <AlarmStatusBadge status={alarm.status} />
      </span>
      <span className="dark:text-ondark-text-2 col-span-3 flex items-center gap-2 text-sm">
        {alarm.sourceEntity?.type === 'DOOR' ? (
          <span className="inline-block h-5 w-5 flex-shrink-0">
            <DoorIcon />
          </span>
        ) : (
          <span className="inline-block h-5 w-5 flex-shrink-0">
            <CameraIcon />
          </span>
        )}
        <span className="truncate" title={alarm.sourceEntity?.name}>
          {alarm.sourceEntity?.name}
        </span>
      </span>
      <span className="col-span-3 flex items-center justify-between gap-2">
        <span className="dark:text-ondark-text-2 flex items-center gap-2 truncate text-sm">
          <span className="flex-shrink-0">
            <MapPinIcon className="h-5 w-5" />
          </span>
          <span className="truncate" title={alarm.location?.name}>
            {alarm.location?.name}
          </span>
        </span>
        <ChevronRightIcon className="dark:text-ondark-text-2 mr-4 h-4 w-4 flex-shrink-0" />
      </span>
    </button>
  );
}

export default ListItem;
