import { DoorGroupFilters } from '@hakimo-ui/hakimo/types';

export interface State {
  filters: DoorGroupFilters;
  page: number;
  pageSize: number;
}

export const initialState: State = {
  filters: {
    name: { values: [], negative: false },
  },
  page: 1,
  pageSize: 20,
};
