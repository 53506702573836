import { useSourceSystems } from '@hakimo-ui/hakimo/data-access';
import {
  AlarmListFilters,
  AlarmTypeFilterValue,
  EmployeeFilterValue,
  LocationFilterValue,
  SourceEntityNameFilterValue,
  SourceSystemFilterValue,
  StatusFilterValue,
  TenantFilterValue,
  TimeFilterValue,
} from '@hakimo-ui/hakimo/types';
import {
  TenantFilter,
  FilterSection,
  SourceEntityFilter,
} from '@hakimo-ui/hakimo/ui-filters';
import { useCanViewTenantColumn } from '@hakimo-ui/hakimo/util';
import { AlarmTypeFilterSection } from './AlarmTypeFilterSection';
import { EmployeeFilterSection } from './EmployeeFilterSection';
import { LocationFilterSection } from './LocationFilterSection';
import SourceSystemFilter from './SourceSystemFilter';
import StatusFilter from './StatusFilter';
import TimeFilter from './TimeFilter';

interface Props {
  hideTimeFilter: boolean;
  alarmListFilters: AlarmListFilters;
  onChangeStatusFilterValue: (value: StatusFilterValue) => void;
  onChangeTimeFilterValue: (value: TimeFilterValue) => void;
  onChangeSourceSystemFilterValue: (value: SourceSystemFilterValue) => void;
  onChangeLocationFilterValue: (value: LocationFilterValue) => void;
  onChangeSourceEntityFilterValue: (value: SourceEntityNameFilterValue) => void;
  onChangeTypeFilterValue: (value: AlarmTypeFilterValue) => void;
  onChangeEmployeeFilterValue: (value: EmployeeFilterValue) => void;
  onChangeTenantFilterValue: (value: TenantFilterValue) => void;
}

export function FilterPanelSections(props: Props) {
  const {
    hideTimeFilter,
    alarmListFilters,
    onChangeStatusFilterValue,
    onChangeTimeFilterValue,
    onChangeSourceSystemFilterValue,
    onChangeLocationFilterValue,
    onChangeSourceEntityFilterValue,
    onChangeTypeFilterValue,
    onChangeEmployeeFilterValue,
    onChangeTenantFilterValue,
  } = props;
  const canViewTenantColumn = useCanViewTenantColumn();

  const {
    status,
    time,
    sourceSystem,
    location,
    sourceEntity,
    type,
    employee,
    tenant = { values: [], negative: false },
  } = alarmListFilters;
  const { data: sourceSystems } = useSourceSystems();
  return (
    <>
      <FilterSection
        title="Status"
        content={
          <StatusFilter value={status} onChange={onChangeStatusFilterValue} />
        }
        hasFilterApplied={status.values.length > 0}
        negativeApplied={status.negative}
      />
      {!hideTimeFilter && (
        <FilterSection
          title="Time"
          content={
            <TimeFilter value={time} onChange={onChangeTimeFilterValue} />
          }
          hasFilterApplied={time.period !== 'All Time'}
        />
      )}
      <FilterSection
        title="Source System"
        content={
          <SourceSystemFilter
            value={sourceSystem}
            onChange={onChangeSourceSystemFilterValue}
            options={sourceSystems}
          />
        }
        hasFilterApplied={sourceSystem.length > 0}
      />
      <LocationFilterSection
        onChange={onChangeLocationFilterValue}
        filterValue={location}
      />
      <AlarmTypeFilterSection
        filterValue={type}
        onChange={onChangeTypeFilterValue}
      />
      <FilterSection
        title="Source Entity"
        content={
          <SourceEntityFilter
            label="Source Entity Name"
            value={sourceEntity}
            onChange={onChangeSourceEntityFilterValue}
            enableNegativeFilters={true}
          />
        }
        hasFilterApplied={sourceEntity.values.length > 0}
        negativeApplied={sourceEntity.negative}
      />
      <EmployeeFilterSection
        onChange={onChangeEmployeeFilterValue}
        filterValue={employee}
      />
      {canViewTenantColumn && (
        <FilterSection
          title="Tenant"
          content={
            <TenantFilter
              value={tenant}
              label="Tenant"
              onChange={onChangeTenantFilterValue}
              enableNegativeFilters={true}
            />
          }
          hasFilterApplied={tenant.values.length > 0}
          negativeApplied={tenant.negative}
        />
      )}
    </>
  );
}

export default FilterPanelSections;
