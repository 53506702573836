import { AddUserPayload } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUpdateUser(id: string, onSuccess: () => void) {
  const url = `/v2/orm/user/${id}`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<null, Partial<AddUserPayload> | null>(
    request,
    {
      onSuccessInvalidationKeys: [['users']],
      onSuccess,
    }
  );
}
