import { AlarmListFilters } from '@hakimo-ui/hakimo/types';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_STATUS_FILTER = 'UPDATE_STATUS_FILTER';
export const UPDATE_TIME_FILTER = 'UPDATE_TIME_FILTER';
export const UPDATE_SOURCE_SYSTEM_FILTER = 'UPDATE_SOURCE_SYSTEM_FILTER';
export const UPDATE_LOCATION_FILTER = 'UPDATE_LOCATION_FILTER';
export const UPDATE_DOOR_NAME_FILTER = 'UPDATE_DOOR_NAME_FILTER';

export interface UpdateFiltersAction {
  type: typeof UPDATE_FILTERS;
  payload: Partial<AlarmListFilters>;
}

export type Action = UpdateFiltersAction;
