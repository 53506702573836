import { useUsers } from '@hakimo-ui/hakimo/data-access';
import { User, UserListFilters } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  trackFilters,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  createSetPageAction,
  createUpdateFiltersAction,
} from '../store/action-creators';
import { useLocalDispatch, useLocalState } from '../store/StateProvider';
import { AddUserModal } from './add-user-modal/AddUserModal';
import { EditUserModal } from './edit-user-modal/EditUserModal';
import FilterPanel from './filter-panel/FilterPanel';
import UserListFooter from './user-list-footer/UserListFooter';
import UserListHeader from './user-list-header/UserListHeader';
import { columns, getSearchParams, getTableData, mapUserFilter } from './utils';

function UserList() {
  const {
    userListPage: page,
    userListPageSize: pageSize,
    userListFilters,
  } = useLocalState();

  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });
  const dispatch = useLocalDispatch();
  const [total, setTotal] = useState(0);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ id: '', role: '' });
  const [users, setUsers] = useState<User[]>([]);

  const searchParams = useMemo(() => {
    return getSearchParams(page, pageSize, userListFilters);
  }, [page, pageSize, userListFilters]);

  const { isLoading, isError, error, data, isRefetching } =
    useUsers(searchParams);

  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;

  const onClickEdit = useCallback(
    (userId: string) => {
      const user = users.find(({ id }) => id === userId);

      if (user) {
        setSelectedUser({ id: user.id, role: user.roles[0] });
        setOpenEditUserModal(true);
      }
    },
    [users]
  );
  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      setUsers(items);
      setTableData(getTableData(items, onClickEdit));
      setTotal(t);
    }
  }, [data, onClickEdit]);

  const onNextPage = () => {
    dispatch(createSetPageAction(page + 1));
  };
  const onPreviousPage = () => {
    dispatch(createSetPageAction(page - 1));
  };
  const onFirstPage = () => {
    dispatch(createSetPageAction(1));
  };
  const onApplyFilters = (filters: UserListFilters) => {
    setOpenFilterPanel(false);
    dispatch(createUpdateFiltersAction(filters));
    dispatch(createSetPageAction(1));
    trackFilters(mapUserFilter(filters));
  };

  const tableHeader = (
    <UserListHeader
      userListFilters={userListFilters}
      isLoading={isLoading}
      onOpenFilterPanel={() => setOpenFilterPanel(true)}
      onOpenAddUserModal={() => setOpenAddUserModal(true)}
      isRefetching={isRefetching}
    />
  );

  const tableFooter = (
    <UserListFooter
      fromIndex={fromIndex}
      toIndex={toIndex}
      pageSize={pageSize}
      total={total}
      onNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      onFirstPage={onFirstPage}
    />
  );

  return (
    <Page title="Users">
      {isError && (
        <div className="mb-2">
          <Alert type="error">{error.message}</Alert>
        </div>
      )}
      <AddUserModal
        open={openAddUserModal}
        onClose={() => setOpenAddUserModal(false)}
      />
      {openEditUserModal && (
        <EditUserModal
          currentUserData={selectedUser}
          onClose={() => setOpenEditUserModal(false)}
        />
      )}
      <FilterPanel
        open={openFilterPanel}
        filters={userListFilters}
        onClose={() => setOpenFilterPanel(false)}
        onApplyFilters={onApplyFilters}
      />
      <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start bg-white sm:-mx-6 md:mx-0 md:rounded-lg">
        <Table
          data={tableData}
          loading={isLoading}
          header={tableHeader}
          footer={tableFooter}
          scrollResetKey={page}
        />
      </div>
    </Page>
  );
}

export default withAuthz(withErrorBoundary(UserList), ['user:view']);
