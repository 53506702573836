import {
  identify,
  Identify,
  init as initAmplitude,
  track,
} from '@amplitude/analytics-browser';
import { environment } from '@hakimo-ui/hakimo/environments';
import { useUser } from '@hakimo-ui/hakimo/util';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AMPLITUDE_API_KEY, INTERCOM_APP_ID, SENTRY_DSN } from './util';

const isLocalOrAirgap =
  environment.name === 'LOCAL' || environment.name === 'AIRGAP';

export function AppInit() {
  const user = useUser();
  const location = useLocation();

  useEffect(() => {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      enabled: !isLocalOrAirgap,
      environment: environment.name,
      release: environment.release,
    });

    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.name ?? 'Unknown',
    });
  }, [user]);

  useEffect(() => {
    if (!isLocalOrAirgap) {
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        email: user.email,
        user_id: user.id,
        name: user.name ?? 'Unknown',
        user_hash: user.userIntercomHash,
      });
    }
  }, [user]);

  useEffect(() => {
    initAmplitude(AMPLITUDE_API_KEY, user.email, {
      optOut: isLocalOrAirgap,
    });
  }, [user.email]);

  useEffect(() => {
    const identifyObj = new Identify();
    identifyObj.set('environment_name', environment.name);
    identify(identifyObj);
  }, []);

  useEffect(() => {
    track('PageView', {
      path: location.pathname,
    });
  }, [location]);

  return null;
}

export default AppInit;
