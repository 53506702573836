import { AlarmFilters, NamedSearch, TimePeriod } from '@hakimo-ui/hakimo/types';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const initialValueFilters: AlarmFilters = {
  sourceSystem: [],
  location: { values: [], negative: false },
  sourceEntity: { values: [], negative: false },
  status: { values: [], negative: false },
  type: { values: [], negative: false },
  employee: { values: [], negative: false },
  tenant: { values: [], negative: false },
};

export const timePeriodAtom = atomWithStorage<TimePeriod>(
  'timePeriod',
  TimePeriod.PAST_HOUR
);

export const activeNamedSearchIdAtom = atomWithStorage<string>(
  'activeNamedSearchId',
  'all'
);

export const customNamedSearchesAtom = atomWithStorage<NamedSearch[]>(
  'customNamedSearches',
  []
);

export const newSearchAtom = atom<NamedSearch>({
  id: 'new',
  name: 'New Search*',
  group: 'custom',
  type: 'new',
  pinned: false,
  filters: {
    ...initialValueFilters,
  },
});

export const pageAtom = atom<number>(1);
export const pageSizeAtom = atom<number>(30);
