import { Button } from '@hakimo-ui/shared/ui-base';
interface Props {
  onApply: () => void;
  onReset: () => void;
  trackingInfo?: string;
}

export function FilterFooter(props: Props) {
  const { trackingInfo, onApply, onReset } = props;
  return (
    <div className="space-y-3">
      <Button
        block
        variant="primary"
        onClick={onApply}
        trackingInfo={
          trackingInfo ? `Apply ${trackingInfo} filters` : undefined
        }
      >
        Apply
      </Button>
      <Button
        block
        variant="outline"
        onClick={onReset}
        trackingInfo={
          trackingInfo ? `Reset ${trackingInfo} filters` : undefined
        }
      >
        Reset
      </Button>
    </div>
  );
}

export default FilterFooter;
