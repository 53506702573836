import { DCP, DCPLabel } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useSaveLabel(dcpId: string, onSuccess?: () => void) {
  const url = `/v2/orm/dcp/${dcpId}`;

  const request = new Request(url, {
    method: 'PATCH',
  });

  return useAuthenticatedMutation<DCP, DCPLabel | null>(request, {
    onSuccessInvalidationKeys: [['dcps'], ['dcp', dcpId]],
    onSuccess: onSuccess,
  });
}

export default useSaveLabel;
