import { Checkbox } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { Fragment } from 'react';
import Cell from './Cell';
import { Column, Row, RowSelection } from './types';

interface Props {
  cellIndex: number;
  row: Row;
  columns: Column[];
}

export function DataCell(props: Props) {
  const { cellIndex, row, columns } = props;

  const cell = row.cells[cellIndex];
  const isFirstColumn = cellIndex === 0;
  const column = columns[cellIndex];

  const responsiveColumns = columns.reduce<number[]>((acc, cur, idx) => {
    if (cur.showAtBreakpoint !== undefined) {
      acc.push(idx);
    }
    return acc;
  }, []);

  if (isFirstColumn) {
    return (
      <Cell type="data" align={cell.align}>
        {cell.value}
        <dl className="font-normal">
          {responsiveColumns.map((columnIndex) => {
            const col = columns[columnIndex];
            if (columnIndex === 0) {
              return null;
            }

            return (
              <Fragment key={col.id}>
                <dt className="sr-only">{col.name}</dt>
                <dd
                  className={clsx(
                    'dark:text-dark-text mt-1 truncate text-gray-500',
                    {
                      'sm:hidden': col.showAtBreakpoint === 'sm',
                      'md:hidden': col.showAtBreakpoint === 'md',
                      'lg:hidden': col.showAtBreakpoint === 'lg',
                      'xl:hidden': col.showAtBreakpoint === 'xl',
                      '2xl:hidden': col.showAtBreakpoint === '2xl',
                    }
                  )}
                >
                  {row.cells[columnIndex].value}
                </dd>
              </Fragment>
            );
          })}
        </dl>
      </Cell>
    );
  }

  return (
    <Cell type="data" breakpoint={column.showAtBreakpoint} align={cell.align}>
      {cell.value}
    </Cell>
  );
}

interface SelectableCellProps {
  rowSelection: RowSelection;
  rowId: string;
}

DataCell.Selectable = function (props: SelectableCellProps) {
  const { rowSelection, rowId } = props;

  return (
    <Cell type="data">
      <Checkbox
        checked={rowSelection.selected.includes(rowId)}
        onChange={(checked) => {
          if (checked) {
            rowSelection.onChangeSelected(rowSelection.selected.concat(rowId));
          } else {
            rowSelection.onChangeSelected(
              rowSelection.selected.filter((id) => id !== rowId)
            );
          }
        }}
      />
    </Cell>
  );
};

export default DataCell;
