import { StatusType } from '@hakimo-ui/hakimo/types';
import { Label, LabelType } from '@hakimo-ui/shared/ui-base';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { Fragment } from 'react';
import { displayStatusSelectables } from '../../../alarm-details/alarm-status/util';

export interface Props {
  statusType: StatusType;
  labelType: LabelType;
  onChange: (statusType: StatusType) => void;
}

export function StatusDropdown(props: Props) {
  const { statusType, labelType, onChange } = props;
  const value = displayStatusSelectables.find((s) => s.name === statusType);

  return (
    <Listbox
      as="div"
      value={value}
      onChange={(val) => onChange(val.name)}
      by={(a, b) => a.id === b.id}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Select status</Listbox.Label>
          <div className="relative">
            <Listbox.Button
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                e.stopPropagation()
              }
              className="dark:focus:ring-ondark-primary focus:ring-onlight-primary dark:focus:ring-offset-ondark-bg-2 focus:ring-offset-onlight-bg-2 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <span className="sr-only">Select status</span>
              <Label type={labelType}>
                <span className="inline-flex items-center gap-1 px-1.5 py-1">
                  <span>{statusType}</span>
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </Label>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="dark:bg-ondark-bg-2 bg-onlight-bg-2 absolute z-10 mt-1 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {displayStatusSelectables.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      clsx(
                        active
                          ? 'bg-onlight-primary dark:bg-ondark-primary text-white'
                          : 'text-onlight-text-1 dark:text-ondark-text-1',
                        'relative cursor-default select-none px-3 py-2'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex w-full flex-col">
                        <div className="flex justify-between">
                          <p className={selected ? 'font-bold' : 'font-normal'}>
                            {option.name}
                          </p>
                          {selected ? (
                            <span
                              className={
                                active
                                  ? 'text-white'
                                  : 'text-onlight-primary dark:text-ondark-primary'
                              }
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default StatusDropdown;
