import { UserDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useUsers(queryParams: string) {
  const url = `/v2/orm/user?${queryParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<UserDTO>(request, {
    queryKey: ['users', queryParams],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useUsers;
