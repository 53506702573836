import { AlarmMedia } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

interface Options {
  id: string;
  debug?: boolean;
  sharedToken?: string | null;
  refetchInterval?: (data: AlarmMedia | undefined) => number | false;
}

export function useAlarmMedia(options: Options) {
  const { id, debug = false, sharedToken, refetchInterval } = options;

  const url = sharedToken
    ? `/shared/v2/alarm/media?sharedToken=${sharedToken}`
    : debug
    ? `/v2/orm/alarm/debug-media/${id}`
    : `/v2/orm/alarm/media/${id}`;
  const request = new Request(url);

  return useAuthenticatedRequest<AlarmMedia>(request, {
    queryKey: ['alarms', 'media', debug, id],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval,
    cacheTime: 0,
    noAuth: typeof sharedToken === 'string',
  });
}

export default useAlarmMedia;
