import { DoorFilters } from '@hakimo-ui/hakimo/types';
import {
  useCanAddDoorsToDoorGroup,
  useCanAddDoorsToLocation,
} from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { isNotDefaultFilter } from './utils';

interface Props {
  selectedDoors: string[];
  filters: DoorFilters;
  isLoading: boolean;
  onClickFilter: () => void;
  onClickAddToLocation: () => void;
  onClickAddToDoorGroup: () => void;
}

export function DoorListHeader(props: Props) {
  const {
    selectedDoors,
    filters,
    isLoading,
    onClickFilter,
    onClickAddToLocation,
    onClickAddToDoorGroup,
  } = props;

  const canAddDoorsToLocation = useCanAddDoorsToLocation();
  const canAddDoorsToDoorGroup = useCanAddDoorsToDoorGroup();

  return (
    <div className="dark:bg-dark-bg border-b bg-white p-4 dark:border-0">
      <div className="flex h-10 items-center">
        <div className="flex flex-1">
          <div className="ml-0">
            {canAddDoorsToLocation && selectedDoors.length > 0 && (
              <Button
                variant="primary"
                trackingInfo="Open add door(s) to location dialog"
                onClick={onClickAddToLocation}
              >
                Add {selectedDoors.length} door
                {selectedDoors.length === 1 ? '' : 's'} to location
                <span className="sr-only">Add to Location</span>
              </Button>
            )}
          </div>
          <div className="ml-2">
            {canAddDoorsToDoorGroup && selectedDoors.length > 0 && (
              <Button
                variant="primary"
                trackingInfo="Open add door(s) to door group dialog"
                onClick={onClickAddToDoorGroup}
              >
                Add {selectedDoors.length} door
                {selectedDoors.length === 1 ? '' : 's'} to door group
                <span className="sr-only">Add to Door Group</span>
              </Button>
            )}
          </div>
        </div>
        <Button
          variant="icon"
          badge={isNotDefaultFilter(filters)}
          onClick={onClickFilter}
          disabled={isLoading}
          trackingInfo="Open doors filters panel"
        >
          <span className="sr-only">Open filters panel</span>
          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}

export default DoorListHeader;
