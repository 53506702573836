import {
  AlarmListFilters,
  NamedSearch,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { trackFilters } from '@hakimo-ui/hakimo/util';
import { useAtom, useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import FilterPanel from '../alarm-list/filters/filter-panel/FilterPanel';
import AlarmMonitoringData from './AlarmMonitoringData';
import FiltersHeader from './filters-header/FiltersHeader';
import { staticNamedSearches } from './filters-header/named-search';
import ListHeader from './list-header/ListHeader';
import ListItem from './list-item/ListItem';
import ListView from './list-view/ListView';
import {
  activeNamedSearchIdAtom,
  customNamedSearchesAtom,
  initialValueFilters,
  newSearchAtom,
  pageAtom,
  pageSizeAtom,
  timePeriodAtom,
} from './state';
import TimePeriodSelector from './time-period-selector/TimePeriodSelector';

export function AlarmMonitoringRoot() {
  const [page, setPage] = useAtom(pageAtom);
  const pageSize = useAtomValue(pageSizeAtom);
  const [customNamedSearches, setCustomNamedSearches] = useAtom(
    customNamedSearchesAtom
  );
  const [newSearch, setNewSearch] = useAtom(newSearchAtom);
  const [activeNamedSearchId, setActiveNamedSearchId] = useAtom(
    activeNamedSearchIdAtom
  );
  const [period, setPeriod] = useAtom(timePeriodAtom);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [total, setTotal] = useState(0);

  const namedSearches = useMemo(
    () => [...staticNamedSearches, newSearch, ...customNamedSearches],
    [customNamedSearches, newSearch]
  );

  const alarmFilters = useMemo(() => {
    const namedSearch = namedSearches.find(
      (ns) => ns.id === activeNamedSearchId
    );
    return namedSearch?.filters || initialValueFilters;
  }, [activeNamedSearchId, namedSearches]);

  const alarmListFilters: AlarmListFilters = useMemo(
    () => ({
      time: {
        period,
        customRangeStart: '',
        customRangeEnd: '',
      },
      ...alarmFilters,
    }),
    [alarmFilters, period]
  );

  const onApplyFilters = (value: AlarmListFilters) => {
    const { time, ...filters } = value;
    setOpenFilterPanel(false);
    setActiveNamedSearchId('new');
    setNewSearch({
      ...newSearch,
      filters,
    });
    trackFilters(filters);
    setPage(1);
  };

  const onChangeTimePeriod = (timePeriod: TimePeriod) => {
    setPeriod(timePeriod);
  };

  const onActivateNamedSearch = (namedSearch: NamedSearch) => {
    setActiveNamedSearchId(namedSearch.id);
  };

  const secondary = (
    <TimePeriodSelector period={period} onChange={onChangeTimePeriod} />
  );

  return (
    <Page title="Monitoring" secondary={secondary}>
      <AlarmMonitoringData
        page={page}
        pageSize={pageSize}
        filters={alarmListFilters}
        onCount={setTotal}
      >
        {(alarms) => (
          <>
            <div className="mt-10 divide-y dark:divide-white/10">
              <div className="pb-4">
                <FiltersHeader
                  period={period}
                  activeNamedSearchId={activeNamedSearchId}
                  namedSearches={namedSearches}
                  onChangePeriod={setPeriod}
                  onClickOpenFilterPanel={() => setOpenFilterPanel(true)}
                  onActivateNamedSearch={onActivateNamedSearch}
                />
              </div>
              <div className="py-4">
                <ListHeader
                  filters={alarmFilters}
                  activeNamedSearchId={activeNamedSearchId}
                  customNamedSearches={customNamedSearches}
                  newSearch={newSearch}
                  onChangeActiveNamedSearchId={setActiveNamedSearchId}
                  onChangeCustomNamedSearches={setCustomNamedSearches}
                  onChangeNewSearch={setNewSearch}
                />
              </div>
              <div className="py-4">
                <ListView
                  items={alarms}
                  total={total}
                  page={page}
                  pageSize={pageSize}
                  onChangePage={setPage}
                  renderListItem={(alarm) => <ListItem alarm={alarm} />}
                />
              </div>
            </div>
            <FilterPanel
              hideTimeFilter
              open={openFilterPanel}
              filters={alarmListFilters}
              onClose={() => setOpenFilterPanel(false)}
              onApplyFilters={onApplyFilters}
            />
          </>
        )}
      </AlarmMonitoringData>
    </Page>
  );
}

export default AlarmMonitoringRoot;
