import {
  AlarmsRoot,
  LocationAlarmsRoot,
  SharedAlarmRoot,
} from '@hakimo-ui/hakimo/feature-alarms';
import { AuditLogRoot } from '@hakimo-ui/hakimo/feature-audit-log';
import { DoorGroupsRoot } from '@hakimo-ui/hakimo/feature-door-groups';
import { DoorsRoot } from '@hakimo-ui/hakimo/feature-doors';
import { InsightsRoot } from '@hakimo-ui/hakimo/feature-insights';
import { LocationsRoot } from '@hakimo-ui/hakimo/feature-locations';
import { UserProfileRoot } from '@hakimo-ui/hakimo/feature-user-profile';
import { UsersRoot } from '@hakimo-ui/hakimo/feature-users';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../auth/Login';
import AppMain from '../main/AppMain';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/shared/alarm" element={<SharedAlarmRoot />} />
      <Route path="/login" element={<Login />} />
      <Route element={<AppMain />}>
        <Route path="/" element={<Navigate to="/alarms" replace />} />
        <Route path="/location-alarms/*" element={<LocationAlarmsRoot />} />
        <Route path="/alarms/*" element={<AlarmsRoot />} />
        <Route path="/doors/*" element={<DoorsRoot />} />
        <Route path="/users/*" element={<UsersRoot />} />
        <Route path="/insights/*" element={<InsightsRoot />} />
        <Route path="/profile/*" element={<UserProfileRoot />} />
        <Route path="/audit-log/*" element={<AuditLogRoot />} />
        <Route path="/locations/*" element={<LocationsRoot />} />
        <Route path="/doorgroups/*" element={<DoorGroupsRoot />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
