import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Fragment } from 'react';

interface Props<T> {
  value?: T;
  label?: string;
  items: T[];
  onChange: (value: T) => void;
  displayValue: (item?: T) => string;
  id?: (item: T) => string;
}

/**
 * Basic select control without autocomplete or type to filter options support
 */
export function SelectMenu<T>(props: Props<T>) {
  const { label, items, value, onChange, displayValue, id } = props;

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className="w-full">
          {label && (
            <Listbox.Label className="dark:text-dark-text mb-1 block text-xs text-gray-700">
              {label}
            </Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button className="focus:border-primary-500 focus:ring-primary-500 dark:bg-dark-bg relative w-full cursor-default rounded border border-gray-400 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus:ring-1 dark:border-gray-600 dark:text-white sm:text-sm">
              <span className="block truncate">{displayValue(value)}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="dark:text-dark-text h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="dark:bg-dark-bg absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:text-white dark:shadow-gray-700 sm:text-sm">
                {items.map((item, idx) => (
                  <Listbox.Option
                    key={id ? id(item) : idx}
                    className={({ active }) =>
                      clsx(
                        active
                          ? 'bg-primary-600 text-white'
                          : 'dark:text-dark-text text-gray-700',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={item}
                  >
                    {({ selected: isSelected, active }) => (
                      <>
                        <span
                          className={clsx(
                            isSelected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {displayValue(item)}
                        </span>
                        {isSelected ? (
                          <span
                            className={clsx(
                              active ? 'text-white' : 'text-primary-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}

export default SelectMenu;
