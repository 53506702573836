import { LABEL_OFFSET, MARGIN } from './constants';

export interface Props {
  width: number;
  height: number;
  min: number;
  max: number;
}

export function Bounds(props: Props) {
  const { width, height, min, max } = props;

  return (
    <>
      <line
        x1={0}
        y1={0}
        x2={0}
        y2={height - MARGIN.bottom}
        className="stroke-black/10 dark:stroke-white/10"
        strokeWidth={2}
      />
      <text
        x={LABEL_OFFSET}
        y={0}
        className="dark:fill-ondark-text-2 fill-onlight-text-2 text-xs"
        dominantBaseline="hanging"
      >
        {new Date(min).toLocaleString()}
      </text>
      <line
        x1={width}
        y1={0}
        x2={width}
        y2={height - MARGIN.bottom}
        className="stroke-black/10 dark:stroke-white/10"
        strokeWidth={2}
      />
      <text
        x={width - LABEL_OFFSET}
        y={0}
        className="dark:fill-ondark-text-2 fill-onlight-text-2 text-xs"
        dominantBaseline="hanging"
        textAnchor="end"
      >
        {new Date(max).toLocaleString()}
      </text>
    </>
  );
}

export default Bounds;
