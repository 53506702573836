import AlarmsRoutes from './AlarmsRoutes';
import StateProvider from './store/StateProvider';

export function AlarmsRoot() {
  return (
    <StateProvider>
      <AlarmsRoutes />
    </StateProvider>
  );
}

export default AlarmsRoot;
