import { useHttpClient } from '@hakimo-ui/hakimo/util';
import { Selectable } from '@hakimo-ui/shared/ui-base';

export function useCamerasByName() {
  const httpClient = useHttpClient();

  return (name: string) => {
    const url = `/v2/orm/camera/names?name=${name}`;

    return httpClient<Selectable[]>(url, {
      responseModifier: async (res) => {
        const respJson = await res.json();
        return respJson.payload;
      },
    });
  };
}

export default useCamerasByName;
