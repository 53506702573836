import { Pagination, Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useLocalState } from '../../store/StateProvider';
import AlarmListHeader from '../alarm-list-header/AlarmListHeader';
import { columns } from '../util';

export interface Props {
  tableData: TableData;
  canViewTenantColumn: boolean;
  shownColumns: string[];
  isLoading: boolean;
  isRefetching: boolean;
  total: number;
  onChangeShownColumns: (columnsIds: string[]) => void;
  onOpenFilterPanel: () => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onFirstPage: () => void;
}

export function AlarmListTable(props: Props) {
  const {
    tableData,
    canViewTenantColumn,
    shownColumns,
    isLoading,
    isRefetching,
    total,
    onChangeShownColumns,
    onOpenFilterPanel,
    onNextPage,
    onPreviousPage,
    onFirstPage,
  } = props;

  const {
    alarmListPage: page,
    alarmListPageSize: pageSize,
    alarmListFilters,
  } = useLocalState();

  const onChangeShownColumnsCb = (selectedColumns: string[]) => {
    onChangeShownColumns(selectedColumns);
  };

  const selectableColumns = columns.filter(
    (col) => col.id !== 'tenant' || canViewTenantColumn
  );

  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;

  const tableHeader = (
    <div className="dark:bg-dark-bg flex items-center justify-end gap-2 border-b bg-white p-4 dark:border-0">
      {isRefetching && <HakimoSpinner />}
      <AlarmListHeader
        selectableColumns={selectableColumns}
        alarmListFilters={alarmListFilters}
        alarmListShownColumns={shownColumns}
        onOpenFilterPanel={onOpenFilterPanel}
        onChangeShownColumns={onChangeShownColumnsCb}
      />
    </div>
  );

  const tableFooter = (
    <div className="dark:bg-dark-bg border-t bg-white p-4 dark:border-gray-800">
      <Pagination
        from={fromIndex + 1}
        to={toIndex + 1}
        pageSize={pageSize}
        total={total}
        onNext={onNextPage}
        onPrevious={onPreviousPage}
        onFirst={onFirstPage}
        disabled={isLoading}
      />
    </div>
  );

  return (
    <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start bg-white sm:-mx-6 md:mx-0 md:rounded-lg">
      <Table
        data={tableData}
        loading={isLoading}
        header={tableHeader}
        footer={tableFooter}
        scrollResetKey={page}
      />
    </div>
  );
}

export default AlarmListTable;
