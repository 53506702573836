import { AlarmFilters, NamedSearch } from '@hakimo-ui/hakimo/types';
import { Button, InputField, Modal } from '@hakimo-ui/shared/ui-base';
import { useId, useState } from 'react';
import FilterTags from '../filter-tags/FilterTags';
import SortSelector from '../sort-selector/SortSelector';
import { initialValueFilters } from '../state';
import { isNotDefaultFilter } from './util';

interface Props {
  filters: AlarmFilters;
  activeNamedSearchId: string;
  customNamedSearches: NamedSearch[];
  newSearch: NamedSearch;
  onChangeActiveNamedSearchId: (id: string) => void;
  onChangeCustomNamedSearches: (namedSearches: NamedSearch[]) => void;
  onChangeNewSearch: (namedSearch: NamedSearch) => void;
}

export function ListHeader(props: Props) {
  const {
    filters,
    activeNamedSearchId,
    customNamedSearches,
    newSearch,
    onChangeActiveNamedSearchId,
    onChangeCustomNamedSearches,
    onChangeNewSearch,
  } = props;
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const onSaveSearch = (id: string, name: string) => {
    onChangeCustomNamedSearches(
      customNamedSearches.concat({
        id,
        name,
        filters,
        group: 'custom',
        pinned: false,
        type: 'saved',
      })
    );

    onChangeActiveNamedSearchId(id);

    onChangeNewSearch({
      ...newSearch,
      filters: {
        ...initialValueFilters,
      },
    });

    setOpenSaveModal(false);
  };

  return (
    <>
      <div className="flex justify-between">
        <SortSelector />
        <div className="flex gap-2">
          {activeNamedSearchId === 'new' && isNotDefaultFilter(filters) && (
            <Button variant="link" onClick={() => setOpenSaveModal(true)}>
              Save
            </Button>
          )}
          <FilterTags filters={filters} />
        </div>
      </div>
      {openSaveModal && (
        <SaveSearchModal
          onClose={() => setOpenSaveModal(false)}
          onSave={onSaveSearch}
        />
      )}
    </>
  );
}

interface SaveSearchModalProps {
  onSave: (id: string, name: string) => void;
  onClose: () => void;
}

function SaveSearchModal(props: SaveSearchModalProps) {
  const { onSave, onClose } = props;
  const [name, setName] = useState('');
  const id = useId();

  const footer = (
    <div className="flex gap-2">
      <Button
        variant="primary"
        onClick={() => onSave(id, name)}
        disabled={!name}
      >
        Save
      </Button>
      <Button onClick={onClose}>Cancel</Button>
    </div>
  );

  return (
    <Modal title="Save Search" open={true} onClose={onClose} footer={footer}>
      <div className="w-[30rem] px-8 py-6">
        <InputField
          label="Name"
          value={name}
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    </Modal>
  );
}

export default ListHeader;
