import DoorGroupRoutes from './DoorGroupRoutes';
import StateProvider from './store/StateProvider';

export function DoorGroupsRoot() {
  return (
    <StateProvider>
      <DoorGroupRoutes />
    </StateProvider>
  );
}

export default DoorGroupsRoot;
