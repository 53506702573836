import { useSharedAlarmUpdates } from '@hakimo-ui/hakimo/data-access';
import { AlarmUpdatesDTO } from '@hakimo-ui/hakimo/types';
import { ReactElement } from 'react';
import { UseQueryResult } from 'react-query';
import { getAlarmUpdatesSearchParams } from './util';

const REFETCH_INTERVAL = 60000;

export interface Props {
  sharedToken: string;
  children: (
    queryResult: UseQueryResult<AlarmUpdatesDTO, Error>
  ) => ReactElement;
}

export function SharedAlarmUpdates(props: Props) {
  const { sharedToken, children } = props;

  const queryResult = useSharedAlarmUpdates(
    sharedToken,
    getAlarmUpdatesSearchParams(),
    REFETCH_INTERVAL
  );

  return children(queryResult);
}

export default SharedAlarmUpdates;
