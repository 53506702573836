export async function getHls(searchParams) {
  return import('hls.js').then((module) => {
    const Hls = module.default;

    if (Hls.isSupported()) {
      return new Hls({
        fLoader: class CustomLoader extends Hls.DefaultConfig.loader {
          load(context, config, callbacks) {
            context.url = context.url + searchParams;
            super.load(context, config, callbacks);
          }
        },
      });
    }
  });
}
