import { AlarmListFilters, LocationAlarmsDTO } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { trackFilters, withAuthz } from '@hakimo-ui/hakimo/util';
import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useAtom, useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { useIsFetching } from 'react-query';
import FilterPanel from '../alarm-list/filters/filter-panel/FilterPanel';
import FiltersHeader from '../alarm-monitoring/filters-header/FiltersHeader';
import { staticNamedSearches } from '../alarm-monitoring/filters-header/named-search';
import ListView from '../alarm-monitoring/list-view/ListView';
import AlarmOverview from './alarm-overview/AlarmOverview';
import ListItem from './list-item/ListItem';
import LocationAlarmsData from './LocationAlarmsData';
import {
  activeNamedSearchIdAtom,
  customNamedSearchesAtom,
  initialValueFilters,
  newSearchAtom,
  pageAtom,
  pageSizeAtom,
  timePeriodAtom,
} from './state';

export function LocationAlarmsRoot() {
  const [page, setPage] = useAtom(pageAtom);
  const pageSize = useAtomValue(pageSizeAtom);
  const [customNamedSearches] = useAtom(customNamedSearchesAtom);
  const [newSearch, setNewSearch] = useAtom(newSearchAtom);
  const [activeNamedSearchId, setActiveNamedSearchId] = useAtom(
    activeNamedSearchIdAtom
  );
  const [period, setPeriod] = useAtom(timePeriodAtom);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedId, setSelectedId] = useState<string>();
  const isFetchingAlarms = useIsFetching({ queryKey: ['locationAlarms'] });

  const namedSearches = useMemo(
    () => [...staticNamedSearches, newSearch, ...customNamedSearches],
    [customNamedSearches, newSearch]
  );

  const alarmFilters = useMemo(() => {
    const namedSearch = namedSearches.find(
      (ns) => ns.id === activeNamedSearchId
    );
    return namedSearch?.filters || initialValueFilters;
  }, [activeNamedSearchId, namedSearches]);

  const alarmListFilters: AlarmListFilters = useMemo(
    () => ({
      time: {
        period,
        customRangeStart: '',
        customRangeEnd: '',
      },
      ...alarmFilters,
    }),
    [alarmFilters, period]
  );

  const onApplyFilters = (value: AlarmListFilters) => {
    const { time, ...filters } = value;
    setOpenFilterPanel(false);
    setActiveNamedSearchId('new');
    setNewSearch({
      ...newSearch,
      filters,
    });
    trackFilters(filters);
    setPage(1);
  };

  const onData = (data: LocationAlarmsDTO) => {
    setTotal(data.total);
    if (!selectedId && data.items.length > 0) {
      setSelectedId(data.items[0].id);
    }
  };

  const secondary = isFetchingAlarms > 0 ? <HakimoSpinner /> : null;

  return (
    <Page title="Location Alarms" secondary={secondary} fullWidth>
      <LocationAlarmsData
        page={page}
        pageSize={pageSize}
        filters={alarmListFilters}
        onData={onData}
      >
        {(alarms) => {
          const selectedAlarm = alarms.find((alarm) => alarm.id === selectedId);

          return (
            <>
              <div className="flex divide-x dark:divide-white/10">
                <div className="w-[30rem] divide-y dark:divide-white/10">
                  <FiltersHeader
                    period={period}
                    activeNamedSearchId={activeNamedSearchId}
                    namedSearches={namedSearches}
                    onChangePeriod={setPeriod}
                    onClickOpenFilterPanel={() => setOpenFilterPanel(true)}
                    onActivateNamedSearch={(ns) =>
                      setActiveNamedSearchId(ns.id)
                    }
                  />
                  <div className="py-4 pr-4 pb-4">
                    <ListView
                      items={alarms}
                      total={total}
                      page={page}
                      pageSize={pageSize}
                      onChangePage={setPage}
                      renderListItem={(alarm) => (
                        <ListItem
                          alarm={alarm}
                          selected={alarm.id === selectedId}
                          onClick={() => setSelectedId(alarm.id)}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  {selectedAlarm && (
                    <AlarmOverview
                      key={selectedAlarm.id}
                      alarm={selectedAlarm}
                    />
                  )}
                </div>
              </div>
              <FilterPanel
                hideTimeFilter
                open={openFilterPanel}
                filters={alarmListFilters}
                onClose={() => setOpenFilterPanel(false)}
                onApplyFilters={onApplyFilters}
              />
            </>
          );
        }}
      </LocationAlarmsData>
    </Page>
  );
}

export default withAuthz(LocationAlarmsRoot, ['location_alarm:view']);
