import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import { Location, LocationFilters } from '@hakimo-ui/hakimo/types';
import { Column as TableColumn, TableData } from '@hakimo-ui/hakimo/ui-table';

export const columns: TableColumn[] = [
  {
    id: 'name',
    name: 'Name',
  },
  {
    id: 'description',
    name: 'Description',
    showAtBreakpoint: 'sm',
  },
  {
    id: 'city',
    name: 'City',
    showAtBreakpoint: 'sm',
  },
  {
    id: 'state',
    name: 'State',
    showAtBreakpoint: 'md',
  },
  {
    id: 'country',
    name: 'Country',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'timeZone',
    name: 'Time Zone',
    showAtBreakpoint: 'xl',
  },
];

export function getTableData(items: Location[]): TableData {
  return {
    rows: items.map((location) => {
      return {
        id: String(location.id),
        cells: [
          {
            value: location.name,
          },
          {
            value: location.description,
          },
          {
            value: location.city,
          },
          {
            value: location.state,
          },
          {
            value: location.country,
          },
          {
            value: location.timezone,
          },
        ],
      };
    }),
    columns,
  };
}

export function getSearchParams(
  page: number,
  filters: LocationFilters,
  pageSize: number
): string {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('pageSize', String(pageSize));

  if (filters.name.values.length > 0) {
    params.append(
      'name',
      filters.name.values.map((item) => item.name).join(SEPARATOR)
    );
  }

  return params.toString();
}
