import {
  ArrowsPointingInIcon,
  BuildingOfficeIcon,
  ChartPieIcon,
  ClipboardDocumentListIcon,
  GlobeAltIcon,
  UsersIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { NavItem } from './sidebar/types';

export const navItems: NavItem[] = [
  {
    id: 'alarms',
    name: 'Alarms',
    href: '/alarms',
    icon: VideoCameraIcon,
    requiredPermissions: ['alarm:view'],
  },
  {
    id: 'location-alarms',
    name: 'Location Alarms',
    href: '/location-alarms',
    icon: ArrowsPointingInIcon,
    requiredPermissions: ['location_alarm:view'],
  },
  {
    id: 'doors',
    name: 'Doors',
    href: '/doors',
    icon: BuildingOfficeIcon,
    requiredPermissions: ['door:view'],
  },
  {
    id: 'locations',
    name: 'Locations',
    href: '/locations',
    icon: GlobeAltIcon,
    requiredPermissions: ['location:view'],
  },
  {
    id: 'door_groups',
    name: 'Door Groups',
    href: '/doorgroups',
    icon: GlobeAltIcon,
    requiredPermissions: ['door_group:view'],
  },
  {
    id: 'users',
    name: 'Users',
    href: '/users',
    icon: UsersIcon,
    requiredPermissions: ['user:view'],
  },
  {
    id: 'auditLog',
    name: 'Audit Log',
    href: '/audit-log',
    icon: ClipboardDocumentListIcon,
    requiredPermissions: ['audit-log:view'],
  },
  {
    id: 'insights',
    name: 'Insights',
    href: '/insights',
    icon: ChartPieIcon,
    requiredPermissions: ['insights:view'],
  },
];
