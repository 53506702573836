import { Menu, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Fragment } from 'react';
import { User } from '@hakimo-ui/hakimo/types';

interface Props {
  user: Partial<User>;
  onClickViewProfile: () => void;
  onLogout: () => void;
}

function ExpandedUserMenu(props: Props) {
  const { user, onClickViewProfile, onLogout } = props;
  const { name, email } = user;

  return (
    <Menu as="div" className="relative text-left">
      <Menu.Button
        className="focus:ring-primary-500 dark:text-dark-text dark:hover:bg-dark-hover-bg group w-full rounded-md bg-gray-100 px-3 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:bg-gray-800 dark:focus:ring-offset-gray-800"
        data-testid="btn-menu"
      >
        <span className="flex w-full items-center justify-between">
          <span className="flex min-w-0 items-center justify-between space-x-3">
            <span className="mr-2 flex min-w-0 flex-1 flex-col">
              <span
                className="dark:text-dark-text truncate text-sm font-medium text-gray-900"
                data-testid="user-name"
              >
                {name}
              </span>
              <span
                className="dark:text-dark-secondary-text truncate text-sm text-gray-500"
                data-testid="user-email"
              >
                {email}
              </span>
            </span>
          </span>
          <ChevronUpDownIcon
            className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="dark:bg-dark-bg absolute right-0 left-0 z-10 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-700 dark:shadow-gray-700 dark:ring-gray-700">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={clsx(
                    active
                      ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-100'
                      : 'dark:bg-dark-bg dark:text-dark-text text-gray-700',
                    'w-full px-4 py-2 text-left text-sm'
                  )}
                  onClick={onClickViewProfile}
                  data-testid="btn-view-profile"
                >
                  Profile and Settings
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={clsx(
                    active
                      ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-100'
                      : 'dark:bg-dark-bg dark:text-dark-text text-gray-700',
                    'w-full px-4 py-2 text-left text-sm'
                  )}
                  onClick={onLogout}
                  data-testid="btn-logout"
                >
                  Logout
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ExpandedUserMenu;
