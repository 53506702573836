import { AlarmListFilters } from '@hakimo-ui/hakimo/types';
import {
  SetPageAction,
  SET_PAGE,
  UpdateFiltersAction,
  UPDATE_FILTERS,
} from './actions';

export function createSetPageAction(payload: number): SetPageAction {
  return {
    type: SET_PAGE,
    payload,
  };
}

export function createUpdateFiltersAction(
  payload: AlarmListFilters
): UpdateFiltersAction {
  return {
    type: UPDATE_FILTERS,
    payload,
  };
}
