import { AlarmListFilters } from '@hakimo-ui/hakimo/types';

export const SET_PAGE = 'SET_PAGE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';

export interface SetPageAction {
  type: typeof SET_PAGE;
  payload: number;
}

export interface UpdateFiltersAction {
  type: typeof UPDATE_FILTERS;
  payload: AlarmListFilters;
}

export type Action = UpdateFiltersAction | SetPageAction;
