import { DoorFilters } from '@hakimo-ui/hakimo/types';
import { Pagination, Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import { createUpdatePageAction } from '../store/action-creators';
import { useAppDispatch } from '../store/StateProvider';
import { DoorListHeader } from './DoorListHeader';

export interface Props {
  selected: string[];
  tableData: TableData;
  loading: boolean;
  fromIndex: number;
  toIndex: number;
  pageSize: number;
  total: number;
  page: number;
  filters: DoorFilters;
  onChangeSelected: (selected: string[]) => void;
  onClickOpenFilter: () => void;
  onClickAddToLocation: () => void;
  onClickAddToDoorGroup: () => void;
}

export function DoorListTable(props: Props) {
  const {
    selected,
    tableData,
    loading,
    fromIndex,
    toIndex,
    pageSize,
    total,
    page,
    filters,
    onChangeSelected,
    onClickOpenFilter,
    onClickAddToLocation,
    onClickAddToDoorGroup,
  } = props;
  const dispatch = useAppDispatch();

  const tableFooter = (
    <div className="dark:bg-dark-bg border-t bg-white p-4 dark:border-gray-800">
      <Pagination
        from={fromIndex + 1}
        to={toIndex + 1}
        pageSize={pageSize}
        total={total}
        onNext={() => {
          dispatch(createUpdatePageAction(page + 1));
        }}
        onPrevious={() => {
          dispatch(createUpdatePageAction(page - 1));
        }}
        onFirst={() => dispatch(createUpdatePageAction(1))}
        disabled={loading}
      />
    </div>
  );

  return (
    <Table
      data={tableData}
      loading={loading}
      header={
        <DoorListHeader
          selectedDoors={selected}
          filters={filters}
          isLoading={loading}
          onClickFilter={onClickOpenFilter}
          onClickAddToLocation={onClickAddToLocation}
          onClickAddToDoorGroup={onClickAddToDoorGroup}
        />
      }
      footer={tableFooter}
      scrollResetKey={page}
      rowSelection={{
        selected,
        onChangeSelected,
      }}
    />
  );
}

export default DoorListTable;
