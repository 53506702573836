// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useModifySelf } from '@hakimo-ui/hakimo/data-access';
import { Button, InputField, Modal } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

export function SignUp() {
  const [name, setName] = useState<string>('');
  const modifySelfMutation = useModifySelf(() => null);
  const footer = (
    <Button
      variant="primary"
      onClick={() => modifySelfMutation.mutate({ name: name })}
      loading={modifySelfMutation.isLoading}
    >
      Let's go!
    </Button>
  );
  return (
    <div className="dark:bg-dark-bg dark:text-dark-text flex h-screen items-center justify-center">
      <Modal
        open={true}
        onClose={() => null}
        closable={false}
        footer={footer}
        title="Welcome to Hakimo"
      >
        <div className="w-96">
          <div className="mb-2 px-8 py-4">
            <h1>Tell us about yourself</h1>
          </div>
          <div className="dark:text-dark-text mb-2 px-8 py-4">
            <InputField
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="John Doe"
              label="Name"
              type="text"
            />
          </div>
          <div className="dark:text-dark-text mb-2 px-8 py-4 text-sm">
            We've set you up as a GSOC Operator. Please contact an Admin at your
            company if this is not the right role.
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SignUp;
