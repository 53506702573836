import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import SidebarButton from './SidebarButton';
import SidebarContent from './SidebarContent';

interface Props {
  open: boolean;
  onClose: () => void;
}

function ExpandedSidebar(props: Props) {
  const { open, onClose } = props;
  return (
    <Transition.Root show={open}>
      <div className="fixed inset-0 z-10 hidden lg:block lg:w-64">
        <Transition.Child
          as={Fragment}
          enter="transition-transform ease-in duration-500"
          enterFrom="-translate-x-3/4"
          enterTo="translate-x-0"
          leave="transition-transform ease-out duration-500"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-3/4"
        >
          <div className="relative py-6 lg:fixed lg:inset-y-0 lg:w-64 lg:border-r lg:border-gray-200 lg:bg-gray-100 dark:lg:border-gray-800 lg:dark:bg-gray-800">
            <Transition.Child
              leave="transition-opacity duration-700"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute bottom-0 w-full p-2 text-right">
                <SidebarButton open={open} onClick={onClose} />
              </div>
              <SidebarContent />
            </Transition.Child>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
}

export default ExpandedSidebar;
