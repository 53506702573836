import { Button } from '@hakimo-ui/shared/ui-base';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/solid';

interface Props {
  open: boolean;
  onClick: () => void;
}

function SidebarButton(props: Props) {
  const { open, onClick } = props;
  const buttonType = open ? 'Close sidebar' : 'Open sidebar';

  return (
    <Button variant="icon" onClick={onClick} trackingInfo={buttonType}>
      <span className="sr-only">{buttonType}</span>
      {open ? (
        <ChevronDoubleLeftIcon
          className="h-4 text-gray-700 dark:text-gray-400"
          aria-hidden="true"
        />
      ) : (
        <ChevronDoubleRightIcon
          className="h-4 text-gray-700 dark:text-gray-400"
          aria-hidden="true"
        />
      )}
    </Button>
  );
}

export default SidebarButton;
