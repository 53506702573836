import { useCameraLivestreamUrl } from '@hakimo-ui/hakimo/data-access';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { Alert } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { createRef, memo, ReactElement } from 'react';
import { getAlert } from '../util';
import { useHLS } from './useHLS';

interface Props {
  cameraId: string | null;
  open: boolean;
  lockAspectRatio?: boolean;
  renderAlert?: (message: string) => ReactElement;
}

function LiveVideo(props: Props) {
  const { cameraId, open, lockAspectRatio = false, renderAlert } = props;

  return cameraId !== null ? (
    <WithCameraId
      cameraId={cameraId}
      open={open}
      renderAlert={renderAlert}
      lockAspectRatio={lockAspectRatio}
    />
  ) : open ? (
    (renderAlert || getAlert)('Live streaming not enabled')
  ) : null;
}

interface WithCameraIdProps {
  cameraId: string;
  open: boolean;
  lockAspectRatio?: boolean;
  renderAlert?: (message: string) => ReactElement;
}

const WithCameraId = memo((props: WithCameraIdProps) => {
  const { cameraId, open, lockAspectRatio = false, renderAlert } = props;
  const queryResult = useCameraLivestreamUrl(cameraId);

  return open ? (
    <QueryResult
      queryResult={queryResult}
      errorFormatter={renderAlert && ((error) => renderAlert(error.message))}
    >
      {(livestreamUrl) => (
        <LiveVideoPlayer
          videoPath={livestreamUrl}
          lockAspectRatio={lockAspectRatio}
        />
      )}
    </QueryResult>
  ) : null;
});

interface LiveVideoPlayerProps {
  videoPath: string;
  lockAspectRatio?: boolean;
}

function LiveVideoPlayer(props: LiveVideoPlayerProps) {
  const { videoPath, lockAspectRatio = false } = props;
  const videoRef = createRef<HTMLVideoElement>();
  useHLS(videoRef, videoPath);

  return (
    <video
      ref={videoRef}
      controls
      autoPlay
      className={clsx(
        'dark:bg-ondark-bg-2 before:border-hakimo-yellow max-h-[48rem] w-full bg-gray-200',
        lockAspectRatio && 'aspect-video'
      )}
      muted
    >
      <Alert>Your browser cannot play the provided video file.</Alert>
    </video>
  );
}

export default LiveVideo;
