import { TabItem } from '@hakimo-ui/shared/ui-base';
import { useLocation } from 'react-router-dom';

export type TabId = 'general' | 'preferences';

export function useActiveTabId(): TabId {
  const location = useLocation();

  switch (location.pathname) {
    case '/profile/general':
      return 'general';
    case '/profile/preferences':
      return 'preferences';
    default:
      return 'general';
  }
}

export const tabItems: TabItem<TabId>[] = [
  {
    id: 'general',
    name: 'General',
  },
  {
    id: 'preferences',
    name: 'Preferences',
  },
];
