import { AlarmStatus, LocationAlarmStatus } from '@hakimo-ui/hakimo/types';
import { Label } from '@hakimo-ui/shared/ui-base';
import { getStatusLabelType } from '../alarm-details/alarm-status/util';

interface Props {
  status: AlarmStatus | LocationAlarmStatus;
  small?: boolean;
}

const AlarmStatusBadge = (props: Props) => {
  const { status, small } = props;
  const labelType = getStatusLabelType(status);

  return <Label type={labelType} text={status} small={small} />;
};

export default AlarmStatusBadge;
