import { useLocalStorage } from '@hakimo-ui/hakimo/util';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';

export function Notifications() {
  const [notificationsEnabled, setNotificationsEnabled] =
    useLocalStorage<boolean>('alarmAudioNotification', false);

  return (
    <dl className="divide-y divide-gray-200">
      <Switch.Group
        as="div"
        className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5"
      >
        <Switch.Label
          as="dt"
          className="dark:text-dark-text/70 text-sm font-medium text-gray-500"
          passive
        >
          Audio Notifications
        </Switch.Label>
        <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <Switch
            checked={notificationsEnabled}
            onChange={setNotificationsEnabled}
            className={clsx(
              notificationsEnabled
                ? 'bg-primary-600'
                : 'dark:bg-dark-surface bg-gray-200',
              'focus:ring-primary-500 dark:focus:ring-offset-dark-bg relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-auto'
            )}
          >
            <span
              aria-hidden="true"
              className={clsx(
                notificationsEnabled ? 'translate-x-5' : 'translate-x-0',
                'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        </dd>
      </Switch.Group>
    </dl>
  );
}

export default Notifications;
