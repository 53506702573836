import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { ReactElement, useEffect, useRef } from 'react';
import DataCell from './DataCell';
import HeaderCell from './HeaderCell';
import { Row, RowSelection, TableData } from './types';

interface Props {
  data: TableData;
  loading?: boolean;
  /**
   * Makes the table borderless. Useful
   * if the table is embedded in a component
   * having its own border
   */
  embedded?: boolean;
  header?: ReactElement;
  footer?: ReactElement;
  scrollResetKey?: number;
  rowSelection?: RowSelection;
}

export function Table(props: Props) {
  const {
    data: { columns, rows },
    loading = false,
    embedded = false,
    header,
    footer,
    scrollResetKey,
    rowSelection,
  } = props;

  const selectable = rowSelection !== undefined;

  const onClickRow = (
    row: Row,
    event: React.MouseEvent | React.KeyboardEvent
  ) => {
    // Using zero delay setTimeout to schedule this execution at
    // the end of current executions which includes setting of
    // Selection.type to "None" if the text was already selected
    setTimeout(() => {
      const selection = window.getSelection();
      if (selection?.type !== 'Range' && row.onClick) {
        row.onClick(event);
      }
    });
  };

  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current?.scroll(0, 0);
    }
  }, [scrollResetKey]);

  const onEnterRow = (row: Row, event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onClickRow(row, event);
    }
  };

  return (
    <div
      className={clsx(
        'relative',
        columns.length && 'overflow-hidden',
        !embedded &&
          'flex max-h-full flex-col border dark:border-gray-800 md:rounded-lg'
      )}
    >
      {header}
      {loading && (
        <>
          <div className="dark:bg-dark-bg/70 absolute inset-0 z-10 bg-white/70"></div>
          <div className="absolute inset-0 z-10 flex items-center justify-center">
            <HakimoSpinner />
          </div>
        </>
      )}
      <div className="overflow-auto scroll-smooth" ref={tableContainerRef}>
        <table className="min-w-full">
          <thead>
            <tr>
              {selectable && (
                <HeaderCell.Selectable
                  rowIds={rows.map((row) => row.id)}
                  rowSelection={rowSelection}
                />
              )}
              {columns.map((column) => (
                <HeaderCell key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-800">
            {rows.map((row) => (
              <tr
                key={row.id}
                onClick={(event) => onClickRow(row, event)}
                onKeyDown={(event) => onEnterRow(row, event)}
                className={clsx(
                  'dark:hover:bg-dark-hover-bg group hover:bg-gray-200 focus-visible:outline-none',
                  row.onClick && 'cursor-pointer',
                  row.highlighted && 'bg-hakimo-yellow/20 font-semibold'
                )}
                tabIndex={row.onClick ? 0 : -1}
              >
                {selectable && (
                  <DataCell.Selectable
                    rowSelection={rowSelection}
                    rowId={row.id}
                  />
                )}
                {row.cells.map((_, index) => (
                  <DataCell
                    key={index}
                    cellIndex={index}
                    columns={columns}
                    row={row}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {footer}
    </div>
  );
}

export default Table;
