/* eslint-disable max-lines */
import {
  NEGATIVE_FILTER_TOKEN,
  SEPARATOR,
} from '@hakimo-ui/hakimo/data-access';
import {
  Alarm,
  AlarmListFilters,
  TimeFilterValue,
  TimePeriod,
} from '@hakimo-ui/hakimo/types';
import { Column, Row, TableData } from '@hakimo-ui/hakimo/ui-table';
import { toast } from '@hakimo-ui/hakimo/util';
import { Label } from '@hakimo-ui/shared/ui-base';
import React from 'react';
import { getStatusLabelType, getStatusType } from '../util';
import { default as rhToast } from 'react-hot-toast';
// https://observablehq.com/d/30321568fafe3e55
const tapColors = [
  'rgb(22, 163, 74)',
  'rgb(19, 171, 18)',
  'rgb(78, 180, 15)',
  'rgb(146, 189, 10)',
  'rgb(197, 172, 6)',
  'rgb(200, 123, 7)',
  'rgb(197, 95, 12)',
  'rgb(193, 70, 17)',
  'rgb(189, 48, 23)',
  'rgb(185, 28, 28)',
];
const NEW_ALARM_TOAST_ID = 'audio-notify-new-alarm';
export interface AlarmColumn extends Column {
  id:
    | 'timestamp'
    | 'status'
    | 'tap'
    | 'type'
    | 'sourceSystem'
    | 'location'
    | 'sourceEntity'
    | 'employee'
    | 'tenant';
}
/*
Order of columns here is what is displayed in the table
*/
export const columns: AlarmColumn[] = [
  {
    id: 'timestamp',
    name: 'Time',
  },
  {
    id: 'status',
    name: 'Status',
    showAtBreakpoint: 'xl',
  },
  {
    id: 'tap',
    name: 'TAP',
    showAtBreakpoint: 'xl',
  },
  {
    id: 'type',
    name: 'Type',
    showAtBreakpoint: 'md',
  },
  {
    id: 'sourceSystem',
    name: 'Source System',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'location',
    name: 'Location',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'sourceEntity',
    name: 'Source Entity',
    showAtBreakpoint: 'md',
  },
  { id: 'employee', name: 'Employee', showAtBreakpoint: 'lg' },
  { id: 'tenant', name: 'Tenant', showAtBreakpoint: 'lg' },
];

const columnConstructor = {
  timestamp: (item: Alarm) => ({
    value: new Date(item.timestamp).toLocaleString(),
  }),
  status: (item: Alarm) => ({
    value: <Label text={item.status} type={getStatusLabelType(item.status)} />,
  }),
  tap: (item: Alarm) => {
    const tapColor = item.tap
      ? tapColors[Math.min(Math.floor(item.tap / 10), 9)]
      : null;
    return {
      value: tapColor ? (
        <div>
          <span
            className="inline-block h-4 w-4 rounded-full align-middle"
            style={{ backgroundColor: tapColor }}
          />
          <span className={`px-2 py-1 text-xs font-medium`}>{item.tap}</span>
        </div>
      ) : (
        '-'
      ),
    };
  },
  type: (item: Alarm) => ({ value: item.type }),
  sourceSystem: (item: Alarm) => ({ value: item.sourceSystem }),
  location: (item: Alarm) => ({
    value: item.location?.name ?? 'Unknown Location',
  }),
  sourceEntity: (item: Alarm) => ({
    value: item.sourceEntity?.name ? item.sourceEntity?.name : 'Unknown',
  }),
  employee: (item: Alarm) => ({ value: item.employee?.name }),
  tenant: (item: Alarm) => ({ value: item.tenant.name }),
};

export function getTableData(
  items: Alarm[],
  onClickRowCb: (
    item: Alarm,
    event: React.MouseEvent | React.KeyboardEvent
  ) => void,
  canViewTenantColumn: boolean,
  shownColumns: string[]
): TableData {
  const displayedColumns = columns
    .filter((column) => shownColumns.includes(column.id))
    .filter((col) => {
      return col.id !== 'tenant' || canViewTenantColumn;
    });

  const rows: Row[] = items.map<Row>((item) => {
    return {
      onClick: (event: React.MouseEvent | React.KeyboardEvent) =>
        onClickRowCb(item, event),
      id: item.id,
      cells: displayedColumns.map((col) => {
        const index = col.id;
        return columnConstructor[index](item);
      }),
    };
  });

  return {
    columns: displayedColumns,
    rows,
  };
}

function getFilterQuery(values: string[], negative: boolean): string {
  const query = values;
  if (negative) {
    query.push(NEGATIVE_FILTER_TOKEN);
  }
  return query.join(SEPARATOR);
}

export function getSearchParams(
  page: number,
  pageSize: number,
  filters: AlarmListFilters
): string {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('pageSize', String(pageSize));

  if (filters.location.values.length > 0) {
    const query = getFilterQuery(
      filters.location.values.map((item) => item.name),
      filters.location.negative
    );
    params.append('locations', query);
  }

  if (filters.sourceEntity.values.length > 0) {
    const query = getFilterQuery(
      filters.sourceEntity.values.map((item) => item.name),
      filters.sourceEntity.negative
    );
    params.append('sourceEntities', query);
  }

  if (filters.type.values.length > 0) {
    const query = getFilterQuery(
      filters.type.values.map((item) => item.name),
      filters.type.negative
    );
    params.append('types', query);
  }

  if (filters.tenant && filters.tenant.values.length > 0) {
    const query = getFilterQuery(
      filters.tenant.values.map((item) => item.id),
      filters.tenant.negative
    );
    params.append('tenants', query);
  }

  if (filters.status.values.length > 0) {
    const statusQuery: string[] = [...filters.status.values];
    const query = getFilterQuery(statusQuery, filters.status.negative);
    params.append('statuses', query);
  }

  if (filters.employee.values.length > 0) {
    const empQuery = filters.employee.values.map((item) => {
      return item.id;
    });
    const query = getFilterQuery(empQuery, false);
    params.append('employeeIds', query);
  }

  if (filters.sourceSystem.length > 0) {
    const query = getFilterQuery(
      filters.sourceSystem.map((item) => item.name),
      false
    );
    params.append('sourceSystems', query);
  }

  if (filters.time.period !== 'All Time') {
    const [startTime, endTime] = getStartEndTimes(filters.time);
    params.append('dateFrom', String(startTime));
    params.append('dateTo', String(endTime));
  }

  return params.toString();
}

function getStartEndTimes(time: TimeFilterValue): [number, number] {
  let endTime = Date.now();
  let startTime: number;

  if (time.period === TimePeriod.CUSTOM_RANGE) {
    startTime = new Date(time.customRangeStart).getTime();
    endTime = new Date(time.customRangeEnd).getTime();
  } else if (time.period === TimePeriod.PAST_MINUTE) {
    startTime = endTime - 60 * 1000;
  } else if (time.period === TimePeriod.PAST_HOUR) {
    startTime = endTime - 60 * 60 * 1000;
  } else if (time.period === TimePeriod.PAST_SIX_HOURS) {
    startTime = endTime - 6 * 60 * 60 * 1000;
  } else if (time.period === TimePeriod.PAST_24_HOURS) {
    startTime = endTime - 24 * 60 * 60 * 1000;
  } else if (time.period === TimePeriod.PAST_WEEK) {
    startTime = endTime - 7 * 24 * 60 * 60 * 1000;
  } else if (time.period === TimePeriod.PAST_MONTH) {
    startTime = endTime - 30 * 24 * 60 * 60 * 1000;
  } else {
    startTime = endTime - 3 * 30 * 24 * 60 * 60 * 1000;
  }

  return [startTime, endTime];
}

export function isNotDefaultFilter(filters: AlarmListFilters): boolean {
  return (
    filters.location.values.length > 0 ||
    filters.sourceSystem.length > 0 ||
    filters.sourceEntity.values.length > 0 ||
    filters.type.values.length > 0 ||
    filters.employee.values.length > 0 ||
    filters.time.period !== 'All Time' ||
    (filters.status !== undefined && filters.status.values.length > 0) ||
    (filters.tenant !== undefined && filters.tenant.values.length > 0)
  );
}

export const mapAlarmFilter = (filters: AlarmListFilters) => {
  const filtersApplied: Record<string, string[] | object> = {};

  for (const [filterName, filter] of Object.entries(filters)) {
    if (filter.values && filter.values.length > 0) {
      filtersApplied[filterName] = {
        values: filter.values.map((value: { name: string }) =>
          value.name ? value.name : value
        ),
        negative: filter.negative,
      };
    }
    if (Array.isArray(filter) && filter.length > 0) {
      filtersApplied[filterName] = filter.map((value: { name: string }) =>
        value.name ? value.name : value
      );
    }
    if (filter.period && filter.period !== 'Custom Range') {
      filtersApplied[filterName] = { period: filter.period };
    } else if (filter.customRangeStart && filter.customRangeEnd) {
      filtersApplied[filterName] = filter;
    }
  }

  return filtersApplied;
};

export function unresolvedAlarmCount(alarms?: Alarm[]): number {
  if (!alarms) {
    return 0;
  }
  return alarms.filter(
    (item) =>
      item.tap && item.tap > 50 && getStatusType(item.status) !== 'Resolved'
  ).length;
}

export function notifyNewAlarm(
  prevData?: Alarm[],
  newData?: Alarm[],
  page?: number,
  audio?: boolean
) {
  if (
    // old data had no pending
    unresolvedAlarmCount(prevData) === 0 &&
    // new data has unresolved alarm
    unresolvedAlarmCount(newData) > 0 &&
    // on first page
    page === 1
  ) {
    toast('New unresolved alarm', {
      audio,
      duration: Infinity,
      id: NEW_ALARM_TOAST_ID,
    });
  } else if (unresolvedAlarmCount(newData) === 0) {
    rhToast.dismiss(NEW_ALARM_TOAST_ID);
  }
}
