import { useCustomComments } from '@hakimo-ui/hakimo/data-access';
import { StatusType } from '@hakimo-ui/hakimo/types';
import { LoadingIndicator, Select } from '@hakimo-ui/hakimo/ui-elements';
import { getSelectItemsSelectable } from '@hakimo-ui/hakimo/util';
import { Button, Selectable } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

export interface Props {
  statusType: StatusType;
  onSubmit: (comment: string) => void;
  onClose: () => void;
}

export function UpdateInline(props: Props) {
  const { statusType, onSubmit, onClose } = props;
  const [comment, setComment] = useState<Selectable | null>(null);
  const { data: commentOptions, isLoading } = useCustomComments();

  const getItems = (q: string) => {
    return getSelectItemsSelectable(q, comment, commentOptions);
  };

  return (
    <div className="w-full">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="flex items-center gap-2">
          <div className="w-64 flex-1">
            <Select
              value={comment}
              displayValue={(item) => item?.name ?? 'Select or type comment'}
              getItems={getItems}
              onChange={setComment}
              id={(item) => item.id}
            />
          </div>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            disabled={comment === null}
            onClick={() => onSubmit(comment?.name ?? '')}
          >
            Mark {statusType}
          </Button>
        </div>
      )}
    </div>
  );
}

export default UpdateInline;
