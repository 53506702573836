import { Button } from '@hakimo-ui/shared/ui-base';
import { MicrophoneIcon, StopIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  onClose: () => void;
  onChangeAudio: (file: File) => void;
  onSubmitAudio: () => void;
}

export function Recorder(props: Props) {
  const { onClose, onChangeAudio, onSubmitAudio } = props;
  const playerRef = useRef<HTMLAudioElement>(null);
  const [recording, setRecording] = useState(true);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();

  const onData = useCallback(
    (e: BlobEvent) => {
      if (e.data.size > 0) {
        const player = playerRef.current;

        if (player) {
          player.src = URL.createObjectURL(e.data);
          const file = new File([e.data], Date.now() + '.webm');
          onChangeAudio(file);
        }
      }
    },
    [onChangeAudio]
  );

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        const options = { mimeType: 'audio/webm;codecs="opus"' };
        const recorder = new MediaRecorder(stream, options);
        setMediaRecorder(recorder);
        setRecording(true);
      })
      .catch(() => {
        onClose();
      });
  }, [onClose]);

  useEffect(() => {
    mediaRecorder?.addEventListener('dataavailable', onData);

    return () => {
      mediaRecorder?.removeEventListener('dataavailable', onData);
      mediaRecorder?.stream.getTracks().forEach((track) => track.stop());
    };
  }, [mediaRecorder, onData]);

  useEffect(() => {
    if (recording) {
      mediaRecorder?.start();
    } else {
      mediaRecorder?.stop();
    }
  }, [mediaRecorder, recording]);

  const onStartRecording = () => {
    setRecording(true);
  };

  const onStopRecording = () => {
    setRecording(false);
  };

  const onDiscard = () => {
    onClose();
  };

  const onSubmit = () => {
    onSubmitAudio();
    onClose();
  };

  return (
    <div className="flex items-center gap-4">
      {!recording && (
        <Button variant="icon" onClick={onDiscard}>
          <TrashIcon className="w-5" />
        </Button>
      )}
      {recording ? (
        <div className="flex items-center gap-2">
          <span className="inline-flex h-3 w-3">
            <span className="absolute inline-flex h-3 w-3 animate-ping rounded-full bg-red-400 opacity-75"></span>
            <span className="relative inline-flex h-3 w-3 rounded-full bg-red-500"></span>
          </span>
          <span className="dark:text-dark-secondary-text text-gray-500">
            Recording audio...
          </span>
        </div>
      ) : (
        <audio ref={playerRef} className="inline-block h-12" controls></audio>
      )}
      {recording ? (
        <Button variant="icon" onClick={onStopRecording}>
          <StopIcon className="w-5" />
        </Button>
      ) : (
        <Button variant="icon" onClick={onStartRecording}>
          <MicrophoneIcon className="w-5 text-red-500" />
        </Button>
      )}
      {!recording && (
        <Button variant="primary" onClick={onSubmit}>
          Send to speaker
        </Button>
      )}
    </div>
  );
}

export default Recorder;
