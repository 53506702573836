import { LocationAlarm } from '@hakimo-ui/hakimo/types';
import clsx from 'clsx';
import { ReactNode } from 'react';
import AlarmStatus from '../alarm-status/AlarmStatus';
import { ViewMode } from '../types';

interface Props {
  alarm: LocationAlarm;
  viewMode: ViewMode;
  onChangeViewMode: (viewMode: ViewMode) => void;
}

export function Header(props: Props) {
  const { alarm, viewMode, onChangeViewMode } = props;

  return (
    <div className="flex h-12 items-center justify-between">
      <div className="flex items-center gap-4 px-4">
        <span className="flex max-w-[10rem] flex-col">
          <span className="truncate" title={alarm.location?.name}>
            {alarm.location?.name}
          </span>
          <span
            className="dark:text-ondark-text-2 text-onlight-text-2 truncate text-xs"
            title={alarm.guid}
          >
            {alarm.guid}
          </span>
        </span>
        <div className="h-4 border-l border-black/20 dark:border-white/20"></div>
        <AlarmStatus alarm={alarm} />
      </div>
      <div className="flex items-center gap-2">
        <div className="dark:text-ondark-text-2 text-onlight-text-2 dark:divide-ondark-bg-3 divide-onlight-bg-3 dark:border-ondark-bg-3 border-onlight-bg-3 divide-x overflow-hidden rounded border text-xs">
          <ViewModeButton
            selected={viewMode === 'alarms'}
            onClick={() => onChangeViewMode('alarms')}
          >
            Alarms
          </ViewModeButton>
          <ViewModeButton
            selected={viewMode === 'split'}
            onClick={() => onChangeViewMode('split')}
          >
            Split
          </ViewModeButton>
          <ViewModeButton
            selected={viewMode === 'live'}
            onClick={() => onChangeViewMode('live')}
          >
            Live
          </ViewModeButton>
        </div>
      </div>
    </div>
  );
}

interface ViewModeButtonProps {
  selected: boolean;
  children: ReactNode;
  onClick: () => void;
}

function ViewModeButton(props: ViewModeButtonProps) {
  const { selected, children, onClick } = props;

  return (
    <button
      className={clsx(
        'px-2 py-1',
        selected
          ? 'dark:bg-ondark-primary bg-onlight-primary text-white'
          : 'dark:hover:bg-ondark-bg-2 hover:bg-onlight-bg-2'
      )}
      onClick={onClick}
      disabled={selected}
    >
      {children}
    </button>
  );
}

export default Header;
