import {
  AlarmStatus,
  LocationAlarmStatus,
  StatusType,
} from '@hakimo-ui/hakimo/types';
import { LabelType } from '@hakimo-ui/shared/ui-base';

export function mapStatusTypeToLabelType(statusType: StatusType): LabelType {
  switch (statusType) {
    case StatusType.RESOLVED:
      return 'success';
    case StatusType.IN_PROGRESS:
      return 'warning';
    case StatusType.PENDING:
      return 'error';
  }
}

export function getStatusLabelType(
  status: AlarmStatus | LocationAlarmStatus
): LabelType {
  switch (status) {
    case 'Resolved Manually':
    case 'Resolved by Hakimo':
    case 'Alarm Acknowledged via ACS':
    case 'Resolved':
      return 'success';
    case 'Analyzing':
      return 'info';
    case 'In Progress':
      return 'warning';
    case 'Pending':
      return 'error';
    default:
      return 'default';
  }
}

export function getStatusType(
  status: AlarmStatus | LocationAlarmStatus
): StatusType {
  switch (status) {
    case 'Resolved by Hakimo':
    case 'Resolved Manually':
    case 'Alarm Acknowledged via ACS':
    case 'Resolved':
      return StatusType.RESOLVED;
    case 'Analyzing':
    case 'Pending':
      return StatusType.PENDING;
    case 'In Progress':
      return StatusType.IN_PROGRESS;
  }
}
