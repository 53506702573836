import { AlarmFilters } from '@hakimo-ui/hakimo/types';
import { Label } from '@hakimo-ui/shared/ui-base';
import { EyeSlashIcon } from '@heroicons/react/24/outline';

interface Tag {
  text: string;
  negative?: boolean;
}

export function getFilterTags(filters: AlarmFilters) {
  const tags: Tag[] = [];

  if (filters.status.values.length > 0) {
    tags.push(
      ...filters.status.values.map<Tag>((val) => ({
        text: val,
        negative: filters.status.negative,
      }))
    );
  }

  if (filters.type.values.length > 0) {
    tags.push(
      ...filters.type.values.map<Tag>((val) => ({
        text: val.name,
        negative: filters.type.negative,
      }))
    );
  }

  if (filters.location.values.length > 0) {
    tags.push(
      ...filters.location.values.map<Tag>((val) => ({
        text: val.name,
        negative: filters.location.negative,
      }))
    );
  }

  if (filters.sourceSystem.length > 0) {
    tags.push(
      ...filters.sourceSystem.map<Tag>((val) => ({
        text: val.name,
        negative: false,
      }))
    );
  }

  if (filters.sourceEntity.values.length > 0) {
    tags.push(
      ...filters.sourceEntity.values.map<Tag>((val) => ({
        text: val.name,
        negative: filters.sourceEntity.negative,
      }))
    );
  }

  if (filters.employee.values.length > 0) {
    tags.push(
      ...filters.employee.values.map<Tag>((val) => ({
        text: val.name,
        negative: filters.employee.negative,
      }))
    );
  }

  return tags.map((tag, tagIndex) => {
    return (
      <Label key={tagIndex}>
        {tag.negative && (
          <span className="mr-2">
            <EyeSlashIcon className="text-status-red h-4 w-4" />
          </span>
        )}
        <span>{tag.text}</span>
      </Label>
    );
  });
}
