import { useUpdateLocationAlarm } from '@hakimo-ui/hakimo/data-access';
import { LocationAlarm, StatusType } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { useEffect, useState } from 'react';
import { getStatusType, mapStatusTypeToLabelType } from '../../../util';
import StatusDropdown from '../status-dropdown/StatusDropdown';
import UpdateInline from '../update-status/UpdateInline';

interface Props {
  alarm: LocationAlarm;
}

export function AlarmStatus(props: Props) {
  const { alarm } = props;
  const [addComment, setAddComment] = useState(false);
  const [statusType, setStatusType] = useState(getStatusType(alarm.status));
  const labelType = mapStatusTypeToLabelType(statusType);

  useEffect(() => {
    setStatusType(getStatusType(alarm.status));
  }, [alarm.status]);

  const mutation = useUpdateLocationAlarm(alarm.id, () => {
    setAddComment(false);
    toast('Alarm status updated', { type: 'success' });
  });

  const onChangeStatusType = (type: StatusType) => {
    setStatusType(type);
    setAddComment(true);
  };

  const onClose = () => {
    setStatusType(getStatusType(alarm.status));
    setAddComment(false);
  };

  const onSubmit = (comment: string) => {
    mutation.mutate({ status: statusType, comment });
  };

  return (
    <div className="flex items-center">
      {addComment ? (
        <UpdateInline
          statusType={statusType}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      ) : (
        <StatusDropdown
          statusType={statusType}
          labelType={labelType}
          onChange={onChangeStatusType}
        />
      )}
    </div>
  );
}

export default AlarmStatus;
