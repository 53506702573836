import { NamedSearch, TimePeriod } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import TimePeriodSelector from '../time-period-selector/TimePeriodSelector';

interface Props {
  period: TimePeriod;
  namedSearches: NamedSearch[];
  activeNamedSearchId: string;
  onChangePeriod: (period: TimePeriod) => void;
  onClickOpenFilterPanel: () => void;
  onActivateNamedSearch: (namedSearch: NamedSearch) => void;
}

export function FiltersHeader(props: Props) {
  const { period, onChangePeriod, onClickOpenFilterPanel } = props;

  return (
    <div className="flex h-12 items-center justify-between gap-4">
      <TimePeriodSelector period={period} onChange={onChangePeriod} />
      <span className="pr-4">
        <Button variant="icon" onClick={onClickOpenFilterPanel}>
          <FunnelIcon className="dark:text-ondark-text-2 h-5 w-5" />
        </Button>
      </span>
    </div>
  );
}

export default FiltersHeader;
