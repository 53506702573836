import { SharedAlarmTokenResponseDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useSharedAlarmToken(
  alarmId: string,
  duration: number,
  durationUnit: string
) {
  const url = `/v2/orm/shared/alarm/token/${alarmId}?duration=${duration}&durationUnit=${durationUnit}`;
  const request = new Request(url);

  return useAuthenticatedRequest<SharedAlarmTokenResponseDTO>(request, {
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    enabled: false,
    cacheTime: 0,
  });
}

export default useSharedAlarmToken;
