import { LocationAlarmsDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useLocationAlarms(
  searchParams: string,
  refetchInterval: number,
  onSuccess?: (data: LocationAlarmsDTO) => void,
  disabled = false,
  keepPreviousData = false
) {
  const url = `/v2/orm/location_alarms?${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<LocationAlarmsDTO>(request, {
    queryKey: ['locationAlarms', { searchParams }],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    refetchInterval,
    onSuccess,
    enabled: !disabled,
    keepPreviousData,
  });
}

export default useLocationAlarms;
