import { NamedSearch } from '@hakimo-ui/hakimo/types';
import { initialValueFilters } from '../state';

const initialValue = initialValueFilters;

export const staticNamedSearches: NamedSearch[] = [
  {
    id: 'pending',
    name: 'Pending',
    group: 'system',
    type: 'pending',
    pinned: true,
    filters: {
      ...initialValue,
      status: {
        negative: false,
        values: ['Pending'],
      },
    },
  },
  {
    id: 'inprogress',
    name: 'In Progress',
    group: 'system',
    type: 'inprogress',
    pinned: true,
    filters: {
      ...initialValue,
      status: {
        negative: false,
        values: ['In Progress'],
      },
    },
  },
  {
    id: 'all',
    name: 'All',
    group: 'system',
    type: 'all',
    pinned: true,
    filters: {
      ...initialValue,
    },
  },
];
