import { UserRole } from '@hakimo-ui/hakimo/types';

const passRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
export function validateInputs(
  name: string,
  email: string,
  role: UserRole | undefined,
  password: string,
  checkPass: boolean
): boolean {
  if (!name) {
    return false;
  }
  if (!isValidEmail(email)) {
    return false;
  }
  if (checkPass && !isValidPassword(password)) {
    return false;
  }
  return isValidRole(role);
}

export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export function isValidRole(role: UserRole | undefined) {
  return role !== undefined;
}

export function isValidPassword(password: string) {
  if (!password) {
    return false;
  }
  if (password.length < 14) {
    return false;
  }
  if (!passRegex.test(password)) {
    return false;
  }
  return true;
}
