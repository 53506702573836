import clsx from 'clsx';
import { DescriptionListItem } from '../types';

interface Props {
  items: DescriptionListItem[];
  align?: 'horizontal' | 'vertical';
}

export function DescriptionList(props: Props) {
  const { items, align = 'horizontal' } = props;

  return (
    <dl className="dark:divide-dark-border-bg dark:border-dark-border-bg divide-y divide-gray-200">
      {items.map((item, idx) => (
        <div
          key={idx}
          className={clsx(
            'py-4',
            align === 'horizontal' && 'sm:grid sm:grid-cols-2 sm:gap-4'
          )}
        >
          <dt className="dark:text-dark-secondary-text text-sm font-medium text-gray-500">
            {item.name}
          </dt>
          <dd
            className={clsx(
              'dark:text-dark-text mt-1 whitespace-pre-wrap text-sm text-gray-900',
              align === 'horizontal' && 'sm:mt-0 sm:justify-self-end'
            )}
          >
            {item.value}
          </dd>
        </div>
      ))}
    </dl>
  );
}

export default DescriptionList;
