/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useAddSsoUser, useProfile } from '@hakimo-ui/hakimo/data-access';
import { SignUp } from '@hakimo-ui/hakimo/feature-signup';
import { User } from '@hakimo-ui/hakimo/types';
import {
  PageLoadingIndicator,
  QueryResult,
} from '@hakimo-ui/hakimo/ui-elements';
import { UserProfileContext } from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { ReactNode, useEffect } from 'react';
import { isUserComplete, USER_NOT_SETUP_MESSAGE } from './util';

interface Props {
  children: ReactNode;
}

export function UserProfileProvider(props: Props) {
  const { children } = props;
  const result = useProfile();
  const ssoMutation = useAddSsoUser(() => null);
  useEffect(() => {
    if (
      result.isError &&
      // ssoMutation should not have been called before
      !ssoMutation.isLoading &&
      !ssoMutation.isSuccess &&
      !ssoMutation.isError
    ) {
      ssoMutation.mutate(null);
    }
  }, [result, ssoMutation]);
  // Show loading screen on error if user needs to be set up
  const errorFormatter = (error: Error) => {
    if (error.message === USER_NOT_SETUP_MESSAGE) {
      return <PageLoadingIndicator text="Setting up user..." />;
    }
    return (
      <div className="mx-auto max-w-screen-2xl px-4 py-6 sm:px-6 md:px-8">
        <Alert type="error">{error.message ?? ''}</Alert>
      </div>
    );
  };
  return (
    <div className="dark:bg-dark-bg h-full">
      <QueryResult
        queryResult={result}
        loadingText="Loading user..."
        errorFormatter={errorFormatter}
      >
        {(data: User) =>
          isUserComplete(data) ? (
            <UserProfileContext.Provider value={data}>
              {children}
            </UserProfileContext.Provider>
          ) : (
            <SignUp />
          )
        }
      </QueryResult>
    </div>
  );
}

export default UserProfileProvider;
