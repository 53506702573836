import { useRemoveDCP } from '@hakimo-ui/hakimo/data-access';
import { Door } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
interface Props {
  open: boolean;
  onClose: () => void;
  dcp: Door;
}

function RemoveMappingModal(props: Props) {
  const { open, onClose, dcp } = props;

  function onRemoveDcpSuccess() {
    toast('Removed Mapping Successfully', {
      type: 'success',
    });
    onClose();
  }

  const removeMappingMutation = useRemoveDCP(dcp.dcp?.id, onRemoveDcpSuccess);

  const onCloseCb = () => {
    onClose();
    removeMappingMutation.reset();
  };

  const actions = (
    <>
      <Button
        variant="error"
        onClick={() => removeMappingMutation.mutate({})}
        loading={removeMappingMutation.isLoading}
        disabled={!dcp.dcp?.id}
        trackingInfo="Remove door camera mapping"
      >
        Delete Mapping
      </Button>
      <Button
        onClick={onCloseCb}
        trackingInfo="Cancel remove door camera mapping"
      >
        Cancel
      </Button>
    </>
  );
  return (
    <Modal
      title="Remove Mapping"
      open={open}
      onClose={onCloseCb}
      footer={actions}
    >
      <div className="w-[36rem] p-8">
        {removeMappingMutation.isError ? (
          <div className="py-4 px-8">
            <Alert type="error">{removeMappingMutation.error.message} </Alert>
          </div>
        ) : null}
        <div className="space-y-4">
          <p>
            Are you sure you want to remove the mapping between{' '}
            <b>{dcp.name}</b> and <b>{dcp.dcp?.cameraName}</b>?
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default RemoveMappingModal;
