import {
  useSaveLabel,
  useSingleUnlabeled,
} from '@hakimo-ui/hakimo/data-access';
import { CameraPosition, DCP, DCPLabel, Marker } from '@hakimo-ui/hakimo/types';
import { Dot } from '@hakimo-ui/hakimo/ui-elements';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DOOR_ANNOTATION_NOT_VALID_WARNING_MESSAGE } from './constants';
import ImagePanel from './ImagePanel';
import LabelPanel from './label-panel/LabelPanel';
import {
  createAddDoorMarkerAction,
  createAddFloorMarkerAction,
  createUpdateLabelAction,
} from './store/action-creators';
import reducer from './store/reducer';
import { defaultLabel } from './store/state';
import { normalizeLabel, validateDoorAnnotation } from './util';

interface Props {
  dcp: DCP;
}

export function LabelDoor(props: Props) {
  const { dcp } = props;

  const saveLabelMutation = useSaveLabel(dcp.id, () => {
    toast('Label saved', { type: 'success' });
  });
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, dcp.label || defaultLabel);
  const [isDoorAnnotationValid, setIsDoorAnnotationValid] = useState(true);
  const fetchSingleQuery = useSingleUnlabeled(false, (data) => {
    if (data.id) {
      navigate(`/doors/${data.id}`);
    } else {
      toast('All doors labelled!', { type: 'success' });
      navigate(`/doors/`);
    }
  });

  useEffect(() => {
    setIsDoorAnnotationValid(validateDoorAnnotation(state));
  }, [state]);

  const onAddDoorMarker = (marker: Marker) => {
    dispatch(createAddDoorMarkerAction(marker));
  };

  const onAddFloorMarker = (marker: Marker) => {
    dispatch(createAddFloorMarkerAction(marker));
  };

  const onChangeLabel = (label: Partial<DCPLabel>) => {
    dispatch(createUpdateLabelAction(label));
  };

  const onClickClear = () => {
    onChangeLabel({
      // Only replace the labelled parts of the state,
      // not the image size for example
      doorMarkers: defaultLabel.doorMarkers,
      floorMarker: defaultLabel.floorMarker,
    });
  };
  const onClickReset = () => {
    onChangeLabel({
      cameraPosition: dcp.label?.cameraPosition || defaultLabel.cameraPosition,
      doorMarkers: dcp.label?.doorMarkers || defaultLabel.doorMarkers,
      floorMarker: dcp.label?.floorMarker || defaultLabel.floorMarker,
    });
  };

  const onClickSave = () => {
    saveLabelMutation.mutate(normalizeLabel(state));
  };
  const onClickNext = () => {
    fetchSingleQuery.refetch();
  };

  const onImageLoad = (marker: Marker) => {
    onChangeLabel({
      labellingResolution: marker,
    });
  };

  const onClickBack = () => {
    navigate('/doors');
  };

  const subtitle = (
    <span className="inline-flex items-center text-xs text-gray-500">
      {dcp.doorName}
      <Dot />
      {dcp.id}
    </span>
  );

  return (
    <Page title="Door Details" subtitle={subtitle} onClickBack={onClickBack}>
      <div className="space-y-4 pb-8">
        {saveLabelMutation.isError && (
          <Alert type="error">{saveLabelMutation.error.message}</Alert>
        )}
        {fetchSingleQuery.isError && (
          <Alert type="error">{fetchSingleQuery.error.message}</Alert>
        )}
        {!isDoorAnnotationValid && (
          <Alert type="warning">
            {DOOR_ANNOTATION_NOT_VALID_WARNING_MESSAGE}
          </Alert>
        )}
        <div className="flex flex-col gap-6 lg:flex-row">
          <div className="basis-4/5">
            <ImagePanel
              dcpId={dcp.id}
              doorMarkers={state.doorMarkers ?? []}
              floorMarker={state.floorMarker}
              onAddDoorMarker={onAddDoorMarker}
              onAddFloorMarker={onAddFloorMarker}
              onImageLoadCallback={onImageLoad}
            />
          </div>
          <div className="sticky top-4 w-full basis-1/5 self-start">
            <LabelPanel
              label={state}
              isLoading={
                saveLabelMutation.isLoading || fetchSingleQuery.isFetching
              }
              onChangeCameraPosition={(cam: CameraPosition) =>
                onChangeLabel({ cameraPosition: cam })
              }
              onClickReset={onClickReset}
              onClickClear={onClickClear}
              onClickSave={onClickSave}
              onClickNext={onClickNext}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default LabelDoor;
