import { SpaceWrapped } from '@hakimo-ui/hakimo/ui-elements';
import { Button } from '@hakimo-ui/shared/ui-base';

interface Props {
  from: number;
  to: number;
  pageSize: number;
  total: number;
  disabled?: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onFirst: () => void;
}

export function Pagination(props: Props) {
  const { from, to, total, pageSize, disabled, onNext, onPrevious, onFirst } =
    props;
  const currentPage = Math.ceil((to - 1) / pageSize);

  return (
    <nav className="flex items-center justify-between" aria-label="Pagination">
      <div className="hidden sm:block">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          {total > 0 && (
            <span>
              Showing
              <SpaceWrapped>
                <span className="font-medium">{from}</span>
              </SpaceWrapped>
              to
              <SpaceWrapped>
                <span className="font-medium">{to}</span>
              </SpaceWrapped>
              of
            </span>
          )}
          <SpaceWrapped>
            <span className="font-medium">{total}</span>
          </SpaceWrapped>
          results
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        {currentPage > 3 && (
          <div className="mt-1.5 mr-2">
            <Button
              variant="link"
              onClick={onFirst}
              trackingInfo="Go to first page in pagination"
            >
              Back to start
            </Button>
          </div>
        )}
        <Button
          onClick={onPrevious}
          disabled={disabled || total === 0 || from === 1}
          trackingInfo="Go to previous page in pagination"
        >
          Previous
        </Button>
        <div className="ml-3">
          <Button
            onClick={onNext}
            disabled={disabled || total === 0 || to === total}
            trackingInfo="Go to next page in pagination"
          >
            Next
          </Button>
        </div>
      </div>
    </nav>
  );
}

export default Pagination;
