import { Door } from '@hakimo-ui/hakimo/types';
import AddMappingModal from './add-mapping-modal/AddMappingModal';
import AddToLocationModal from './add-to-location-modal/AddToLocationModal';
import RemoveMappingModal from './remove-mapping-modal/RemoveMappingModal';
import AddToDoorGroupModal from './add-to-door-group-modal/AddToDoorGroupModal';

interface Props {
  addMappingModalDisplay: boolean;
  onCloseAddMappingModal: () => void;
  addLocationModalDisplay: boolean;
  onCloseLocationModal: () => void;
  removeMappingModalDisplay: boolean;
  onCloseRemoveMappingModal: () => void;
  addDoorGroupModalDisplay: boolean;
  onCloseAddDoorGroupModal: () => void;
  selectedMapping?: Door;
  selectedDoorIds: string[];
}
export function DoorListModals(props: Props) {
  const {
    addMappingModalDisplay,
    onCloseAddMappingModal,
    addLocationModalDisplay,
    onCloseLocationModal,
    removeMappingModalDisplay,
    onCloseRemoveMappingModal,
    addDoorGroupModalDisplay,
    onCloseAddDoorGroupModal,
    selectedMapping,
    selectedDoorIds,
  } = props;

  return (
    <>
      {addMappingModalDisplay && (
        <AddMappingModal open={true} onClose={onCloseAddMappingModal} />
      )}
      {addLocationModalDisplay && (
        <AddToLocationModal
          selectedDoors={selectedDoorIds}
          onClose={onCloseLocationModal}
        />
      )}
      {addDoorGroupModalDisplay && (
        <AddToDoorGroupModal
          selectedDoors={selectedDoorIds}
          onClose={onCloseAddDoorGroupModal}
        />
      )}
      {removeMappingModalDisplay && selectedMapping && (
        <RemoveMappingModal
          open={true}
          onClose={onCloseRemoveMappingModal}
          dcp={selectedMapping}
        />
      )}
    </>
  );
}
