import { AlarmListFilters } from '@hakimo-ui/hakimo/types';
import { useLocalStorage } from '@hakimo-ui/hakimo/util';
import { ReactNode, useContext, useEffect, useReducer } from 'react';
import Context from './context';
import reducer from './reducer';
import { initialFilters, initialState } from './state';

interface Props {
  children: ReactNode;
}

export function StateProvider(props: Props) {
  const { children } = props;
  const [localStorageState, setLocalStorageState] =
    useLocalStorage<AlarmListFilters>('alarmListFilters', initialFilters);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...(localStorageState && { alarmListFilters: localStorageState }),
  });
  useEffect(
    () => setLocalStorageState(state.alarmListFilters),
    [state, setLocalStorageState]
  );
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
}

export function useLocalState() {
  const { state } = useContext(Context);
  return state;
}

export function useLocalDispatch() {
  const { dispatch } = useContext(Context);
  return dispatch;
}

export default StateProvider;
