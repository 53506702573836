import { Popover } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/24/outline';

interface Props {
  onClickViewProfile: () => void;
  onLogout: () => void;
}

function CollapsedUserMenu(props: Props) {
  const { onClickViewProfile, onLogout } = props;
  const userMenuItems = [
    {
      dataTestId: 'btn-collapsed-view-profile',
      name: 'Profile and Settings',
      onClick: onClickViewProfile,
    },
    {
      dataTestId: 'btn-collapsed-logout',
      name: 'Logout',
      onClick: onLogout,
    },
  ];
  return (
    <Popover className="relative">
      <Popover.Button data-testid="btn-collapsed-user-menu">
        <UserCircleIcon className="w-8 translate-y-1 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300" />
      </Popover.Button>
      <Popover.Panel className="absolute ml-1 inline">
        <div className="dark:bg-dark-bg grid w-max grid-cols-1 divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-700 dark:shadow-gray-700 dark:ring-gray-700">
          {userMenuItems.map((item) => (
            <Popover.Button
              key={item.name}
              onClick={item.onClick}
              className="py-2 px-5 text-left text-sm hover:bg-gray-100 hover:text-gray-900 hover:dark:bg-gray-700 hover:dark:text-gray-100"
              data-testid={item.dataTestId}
            >
              {item.name}
            </Popover.Button>
          ))}
        </div>
      </Popover.Panel>
    </Popover>
  );
}

export default CollapsedUserMenu;
