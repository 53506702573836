import { Option, TimePeriod } from '@hakimo-ui/hakimo/types';
import { timePeriodOptions } from '../shared/constants';

const timePeriods = [
  TimePeriod.PAST_MINUTE,
  TimePeriod.PAST_HOUR,
  TimePeriod.PAST_SIX_HOURS,
  TimePeriod.PAST_24_HOURS,
];

export const timePeriodOptionsMonitoring = timePeriodOptions.filter((opt) =>
  timePeriods.includes(opt.name)
);

export const defaultTimePeriod = timePeriodOptionsMonitoring.find(
  (opt) => opt.name === TimePeriod.PAST_24_HOURS
) as Option<TimePeriod>;

export const sortOptions: Option[] = [
  {
    id: 'tap',
    name: 'TAP',
  },
  {
    id: 'time',
    name: 'Time',
  },
];

export const tapColors = [
  '#118D3F',
  '#139310',
  '#439D0B',
  '#82A608',
  '#AB9507',
  '#B16A07',
  '#B16A07',
  '#B16A07',
  '#AA2B1C',
  '#AA2B1C',
];
