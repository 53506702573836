import { DoorGroupFilters } from '@hakimo-ui/hakimo/types';
import {
  UpdateFiltersAction,
  UpdatePageAction,
  UPDATE_FILTERS,
  UPDATE_PAGE,
} from './actions';

export function createUpdateFiltersAction(
  payload: DoorGroupFilters
): UpdateFiltersAction {
  return {
    type: UPDATE_FILTERS,
    payload,
  };
}

export function createUpdatePageAction(payload: number): UpdatePageAction {
  return {
    type: UPDATE_PAGE,
    payload,
  };
}
