import { Route, Routes } from 'react-router-dom';
import AlarmDetailsRoot from './alarm-details/AlarmDetailsRoot';
import AlarmList from './alarm-list/AlarmList';
import AlarmMonitoringRoot from './alarm-monitoring/AlarmMonitoringRoot';

export function AlarmsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AlarmList />} />
      <Route path="/monitoring" element={<AlarmMonitoringRoot />} />
      <Route path=":alarmId" element={<AlarmDetailsRoot />} />
    </Routes>
  );
}

export default AlarmsRoutes;
