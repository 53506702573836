import { AddLocationRequestDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useAddLocation(onSuccess: () => void) {
  const url = `/v2/orm/locations`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<null, AddLocationRequestDTO>(request, {
    onSuccessInvalidationKeys: [['locations']],
    onSuccess,
  });
}

export default useAddLocation;
