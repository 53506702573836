import { reset } from '@amplitude/analytics-browser';
import { useAuthUtils, useUser } from '@hakimo-ui/hakimo/util';
import { useNavigate } from 'react-router-dom';
import CollapsedUserMenu from './user/CollapsedUserMenu';
import ExpandedUserMenu from './user/ExpandedUserMenu';

interface Props {
  short?: boolean;
}

function User(props: Props) {
  const { logout } = useAuthUtils();
  const { name, email } = useUser();
  const { short } = props;
  const navigate = useNavigate();

  const onLogout = () => {
    reset();
    logout({ returnTo: window.location.origin });
  };

  const onClickViewProfile = () => {
    navigate('/profile/general');
  };

  return (
    <div>
      {short ? (
        <CollapsedUserMenu
          onClickViewProfile={onClickViewProfile}
          onLogout={onLogout}
        />
      ) : (
        <ExpandedUserMenu
          user={{ name, email }}
          onClickViewProfile={onClickViewProfile}
          onLogout={onLogout}
        />
      )}
    </div>
  );
}

export default User;
