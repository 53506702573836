import { useUser } from '../contexts/user-profile';
import { hasPermission } from './utils';

export function useCanAddAlarmComment() {
  const user = useUser();
  return hasPermission(user, 'alarm/update:add-comment');
}

export function useCanAddAlarmFeedback() {
  const user = useUser();
  return hasPermission(user, 'alarm/update:add-feedback');
}

export function useCanChangeAlarmStatus() {
  const user = useUser();
  return hasPermission(user, 'alarm/update:change-status');
}

export function useCanUpdateDcp() {
  const user = useUser();
  return hasPermission(user, 'dcp/details:update');
}

export function useCanAddDcp() {
  const user = useUser();
  return hasPermission(user, 'dcp:add');
}

export function useCanDebugMedia() {
  const user = useUser();
  return hasPermission(user, 'alarm/debug-media:view');
}

export function useCanViewLiveCamera() {
  const user = useUser();
  return hasPermission(user, 'camera/live:view');
}

export function useCanReportBug() {
  const user = useUser();
  return hasPermission(user, 'alarm/bug-report:create');
}

export function useCanAddLocation() {
  const user = useUser();
  return hasPermission(user, 'location:create');
}

export function useCanAddDoorGroup() {
  const user = useUser();
  return hasPermission(user, 'door_group:create');
}

export function useCanAddDoorsToDoorGroup() {
  const user = useUser();
  return hasPermission(user, 'door/group:update');
}

export function useCanAddDoorsToLocation() {
  const user = useUser();
  return hasPermission(user, 'door/location:update');
}

export function useCanSeeDcpDetails() {
  const user = useUser();
  return hasPermission(user, 'dcp/details:view');
}

export function useCanRemoveMapping() {
  const user = useUser();
  return hasPermission(user, 'dcp:delete');
}

export function useCanViewTenantColumn() {
  const user = useUser();
  return hasPermission(user, 'multi-tenant:view');
}

export function useCanChangeStatusNoComment() {
  const user = useUser();
  return hasPermission(user, 'alarm/update:change-status-no-comment');
}
