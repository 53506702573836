import { useAddUser, useValidRoles } from '@hakimo-ui/hakimo/data-access';
import {
  Alert,
  Button,
  InputField,
  Modal,
  Radio,
} from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { environment } from '@hakimo-ui/hakimo/environments';
import { isValidEmail, isValidPassword, validateInputs } from './util';
import { toast } from '@hakimo-ui/hakimo/util';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import { UserRole } from '@hakimo-ui/hakimo/types';
interface Props {
  open: boolean;
  onClose: () => void;
}
export function AddUserModal(props: Props) {
  const { open, onClose } = props;
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const roleQuery = useValidRoles();
  const [role, setRole] = useState<UserRole>();
  const [password, setPassword] = useState<string>('');
  const resetModal = () => {
    setName('');
    setEmail('');
    setRole(undefined);
  };
  const addUserMutation = useAddUser(() => {
    resetModal();
    onClose();
    toast('User added', { type: 'success' });
  });
  const onCloseCb = () => {
    resetModal();
    onClose();
    addUserMutation.reset();
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={() => {
          if (role) {
            addUserMutation.mutate({
              name: name,
              email: email,
              roles: [role],
              // only add password for local auth
              password: environment.authMode === 'local' ? password : undefined,
            });
          }
        }}
        // Only enable if input is valid
        disabled={
          !validateInputs(
            name,
            email,
            role,
            password,
            environment.authMode === 'local'
          )
        }
        loading={addUserMutation.isLoading}
      >
        Submit
      </Button>
      <Button onClick={onCloseCb}>Cancel</Button>
    </>
  );
  return (
    <Modal title="Add User" open={open} onClose={onCloseCb} footer={actions}>
      <div className="w-96">
        {addUserMutation.isError ? (
          <div className="py-4 px-8">
            <Alert type="error">{addUserMutation.error.message} </Alert>
          </div>
        ) : null}
        <div className="mb-2 px-8 py-4">
          <InputField
            onChange={(e) => setName(e.target.value)}
            label="Name"
            type="text"
            value={name}
          />
        </div>
        <div className="mb-2 px-8 py-4">
          <InputField
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            type="email"
            error={!isValidEmail(email) && email.length > 0}
            errorText="Invalid email"
            value={email}
          />
        </div>
        {environment.authMode === 'local' && (
          <div className="mb-2 px-8 py-4">
            <InputField
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              type="password"
              error={!isValidPassword(password)}
              errorText={
                'Password must be at least 14 ' +
                'characters long and contain one uppercase ' +
                'letter, one lowercase letter, one digit and ' +
                'one special character'
              }
              value={password}
            />
          </div>
        )}
        <div className="mb-2 px-8 py-4">
          <QueryResult queryResult={roleQuery}>
            {(data) => (
              <Radio
                items={data}
                value={role}
                onChange={setRole}
                displayValue={(item) => item}
              />
            )}
          </QueryResult>
        </div>
      </div>
    </Modal>
  );
}

export default AddUserModal;
