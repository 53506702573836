import { Selectable } from '@hakimo-ui/shared/ui-base';
import { Linkable } from '../common';
import { Location } from '../location';

export interface AlarmsDTO {
  total: number;
  items: Alarm[];
}

export interface AlarmUpdatesDTO {
  items: AlarmUpdate[];
}

export interface NeighboringAlarmsDTO {
  items: ReducedAlarm[];
  curIdx: number;
}

export interface ReducedAlarm {
  id: string;
  type: string;
  timestamp: string;
}

export interface MotionAlarm {
  id: string;
  timestamp: string;
  videoStartTime?: string;
  videoEndTime?: string;
  sourceEntity: {
    id: string;
    name: string;
  };
}

export interface Alarm extends ReducedAlarm {
  status: AlarmStatus;
  tap: number | null;
  sourceSystem: string;
  location?: Location;
  sourceEntity?: SourceEntity;
  tags?: string[];
  employee?: Employee;
  localTime?: string;
  timeToRemediationInSeconds?: number;
  resolvedAt?: string;
  camera?: string;
  videoPath?: string;
  sop?: string;
  imagePath?: string;
  floorplanPath?: string;
  videoDetails?: AlarmVideoDetails;
  tenant: Linkable;
}

export interface SourceEntity extends Linkable {
  type: 'CAMERA' | 'DOOR';
}

export interface Employee extends Linkable {
  phoneNumber: string;
}

export interface AlarmMedia {
  videoPath?: string;
  imagePath?: string;
  videoDetails?: AlarmVideoDetails;
}
export interface AlarmVideoDetails {
  markers: AlarmVideoMarker[];
  startTime: string | null;
}

export interface AlarmVideoMarker {
  timestamp: number; // time in seconds since start of video
  label: string; // string to be displayed
  type: 'Error' | 'Info' | 'Warning';
}
export const statuses = [
  'Resolved by Hakimo',
  'Analyzing',
  'Pending',
  'Resolved Manually',
  'Alarm Acknowledged via ACS',
  'In Progress',
] as const;

export type AlarmStatus = typeof statuses[number];

export enum StatusType {
  RESOLVED = 'Resolved',
  IN_PROGRESS = 'In Progress',
  PENDING = 'Pending',
}

export const displayStatusTypes = [
  StatusType.RESOLVED,
  StatusType.IN_PROGRESS,
  StatusType.PENDING,
];

export interface AlarmUpdate {
  feedback?: Feedback;
  newStatus?: StatusType;
  comment?: string;
  timestamp: string;
  // here onwards is only when fetching from backend
  event?: string; // type of alarm update to help with easy parsing
  user?: Selectable; // user who made update
  status?: AlarmStatus; // existing status
  audio_url?: string;
}

export interface Feedback {
  hakimoOutput: HakimoOutput;
  extraFeedback?: ExtraFeedback[];
}

export type HakimoOutput = 'correct' | 'incorrect';

export type ExtraFeedback =
  | 'missingTag'
  | 'extraTag'
  | 'tapTooHigh'
  | 'tapTooLow';

export interface SharedAlarmTokenRequestDTO {
  alarmId: string;
  duration: number;
  durationUnit: string;
}

export interface SharedAlarmTokenResponseDTO {
  accessToken: string;
}

export interface BugReport {
  description: string;
  severity: BugReportSeverity;
  bugReporter?: string | null;
}
export type BugReportSeverity = 'low' | 'medium' | 'high';
