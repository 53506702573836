import { AlarmListFilters, TimePeriod } from '@hakimo-ui/hakimo/types';

export interface State {
  alarmListPage: number;
  alarmListPageSize: number;
  alarmListFilters: AlarmListFilters;
}
export const initialFilters: AlarmListFilters = {
  time: {
    period: TimePeriod.PAST_24_HOURS,
    customRangeStart: '',
    customRangeEnd: '',
  },
  sourceSystem: [],
  location: { values: [], negative: false },
  sourceEntity: { values: [], negative: false },
  status: { values: [], negative: false },
  type: { values: [], negative: false },
  employee: { values: [], negative: false },
  tenant: { values: [], negative: false },
};

export const initialState: State = {
  alarmListPage: 1,
  alarmListPageSize: 30,
  alarmListFilters: initialFilters,
};
