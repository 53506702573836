import ExpandedSidebar from './ExpandedSidebar';
import CollapsedSidebar from './CollapsedSidebar';
import { Fragment } from 'react';

interface Props {
  open: boolean;
  expandSidebar: () => void;
  collapseSidebar: () => void;
}

export function Sidebar(props: Props) {
  const { open, expandSidebar, collapseSidebar } = props;

  return (
    <Fragment>
      <ExpandedSidebar open={open} onClose={collapseSidebar} />
      <CollapsedSidebar open={open} onOpen={expandSidebar} />
    </Fragment>
  );
}

export default Sidebar;
