import { LocationAlarm } from '@hakimo-ui/hakimo/types';
import { HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useEffect, useRef } from 'react';
import { useIsFetching } from 'react-query';
import AlarmHeader from '../alarm-header/AlarmHeader';

export interface Props {
  alarm: LocationAlarm;
  videoPath?: string;
}

export function AlarmDetails(props: Props) {
  const { videoPath } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const isFetchingMedia = useIsFetching({ queryKey: ['alarms', 'media'] });

  useEffect(() => {
    if (videoPath) {
      videoRef.current?.load();
    }
  }, [videoPath]);

  return (
    <div className="space-y-3">
      <AlarmHeader />
      <div className="relative">
        <video
          ref={videoRef}
          className="dark:bg-ondark-bg-2 bg-onlight-bg-2 aspect-video w-full"
          controls
        >
          <source src={videoPath} type="video/mp4"></source>
        </video>
        {isFetchingMedia > 0 && (
          <div className="absolute inset-0 flex items-center justify-center">
            <HakimoSpinner />
          </div>
        )}
      </div>
    </div>
  );
}

export default AlarmDetails;
