import { useSharedAlarmToken } from '@hakimo-ui/hakimo/data-access';
import { SelectMenu } from '@hakimo-ui/hakimo/ui-elements';
import { capitalize, range } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';
import CopyButton from './CopyButton';

export interface Props {
  alarmId: string;
  onClose: () => void;
}

const durationUnits = {
  hour: {
    options: range(1, 23),
  },
  day: {
    options: range(1, 6),
  },
  week: {
    options: range(1, 3),
  },
};

type DurationUnit = keyof typeof durationUnits;
const durationUnitOptions = Object.keys(durationUnits) as DurationUnit[];

export function ShareAlarm(props: Props) {
  const { alarmId, onClose } = props;
  const [duration, setDuration] = useState<number>(1);
  const [durationOptions, setDurationOptions] = useState<number[]>([]);
  const [durationUnit, setDurationUnit] = useState<DurationUnit>('week');

  const { data, isFetching, isError, error, refetch } = useSharedAlarmToken(
    alarmId,
    duration,
    durationUnit
  );

  useEffect(() => {
    const opts = durationUnits[durationUnit].options;
    setDurationOptions(opts);
    setDuration((d) => (opts.includes(d) ? d : 1));
  }, [durationUnit]);

  const displayValueDurationUnit = (item?: DurationUnit) =>
    typeof item === 'string'
      ? capitalize(item) + (duration > 1 ? 's' : '')
      : '--Select--';

  const footer = (
    <>
      <span className="mr-2">
        <Button
          variant="primary"
          trackingInfo="Generate link"
          onClick={() => refetch()}
          loading={isFetching}
          disabled={isFetching}
        >
          Generate Link
        </Button>
      </span>
      <Button onClick={onClose} trackingInfo="Close generate link modal">
        Close
      </Button>
    </>
  );

  const onCopyURL = (url: string) => {
    navigator.clipboard.writeText(url);
  };

  const getUrl = (accessToken: string) => {
    return `${window.location.origin}/shared/alarm?sharedToken=${accessToken}`;
  };

  return (
    <Modal
      open
      onClose={onClose}
      title="Generate Shareable Link"
      footer={footer}
    >
      <div className="w-[36rem] px-8 py-4">
        {isError && (
          <div className="mb-4">
            <Alert type="error">{error.message}</Alert>
          </div>
        )}
        <h2>Link Expiry Time</h2>
        <div className="mt-4 flex gap-4">
          <SelectMenu
            label="Duration"
            items={durationOptions}
            displayValue={(item) =>
              typeof item === 'number' ? String(item) : '--Select--'
            }
            value={duration}
            onChange={(v) => setDuration(v)}
          />
          <SelectMenu
            label="Duration Unit"
            items={durationUnitOptions}
            displayValue={displayValueDurationUnit}
            value={durationUnit}
            onChange={(v) => setDurationUnit(v)}
          />
        </div>
        {data && (
          <div className="mt-4 flex items-center gap-2 text-lg">
            <div className="dark:border-dark-border-bg dark:bg-dark-surface overflow-x-scroll whitespace-nowrap break-words rounded border p-4 align-middle font-mono text-sm">
              {getUrl(data.accessToken)}
            </div>
            <CopyButton onClick={() => onCopyURL(getUrl(data.accessToken))} />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ShareAlarm;
