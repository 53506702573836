import { useDoorGroups } from '@hakimo-ui/hakimo/data-access';
import { DoorGroupFilters } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { Pagination, Table, TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  useCanAddDoorGroup,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useEffect, useState } from 'react';
import AddDoorGroup from '../add-door-group/AddDoorGroup';
import {
  createUpdateFiltersAction,
  createUpdatePageAction,
} from '../store/action-creators';
import { useLocalDispatch, useLocalState } from '../store/StateProvider';
import FilterPanel from './filter-panel/FilterPanel';
import TableHeader from './TableHeader';
import { columns, getSearchParams, getTableData } from './util';

export function DoorGroupsList() {
  const state = useLocalState();
  const dispatch = useLocalDispatch();
  const { filters, page, pageSize } = state;

  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });

  const [total, setTotal] = useState(0);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [openAddDoorGroup, setOpenAddDoorGroup] = useState(false);

  const { isLoading, isError, error, data } = useDoorGroups(
    getSearchParams(page, filters, pageSize)
  );

  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;

  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      setTableData(getTableData(items));
      setTotal(t);
    }
  }, [data]);

  const onApplyFilters = (value: DoorGroupFilters) => {
    dispatch(createUpdateFiltersAction(value));
    dispatch(createUpdatePageAction(1));
  };

  const canAddDoorGroup = useCanAddDoorGroup();

  const tableHeader = (
    <TableHeader
      canAddDoorGroup={canAddDoorGroup}
      hasCustomFiltersApplied={filters.name.values.length > 0}
      isLoading={isLoading}
      onClickAddLocation={() => setOpenAddDoorGroup(true)}
      onClickFilter={() => setOpenFilterPanel(true)}
    />
  );

  const tableFooter = (
    <div className="dark:bg-dark-bg border-t bg-white p-4 dark:border-gray-800">
      <Pagination
        from={fromIndex + 1}
        to={toIndex + 1}
        pageSize={pageSize}
        total={total}
        onNext={() => {
          dispatch(createUpdatePageAction(page + 1));
        }}
        onPrevious={() => {
          dispatch(createUpdatePageAction(page - 1));
        }}
        onFirst={() => dispatch(createUpdatePageAction(1))}
        disabled={isLoading}
      />
    </div>
  );

  return (
    <Page title="DoorGroups">
      {isError && (
        <div className="mb-2">
          <Alert type="error">{error.message}</Alert>
        </div>
      )}
      {openAddDoorGroup && (
        <AddDoorGroup onClose={() => setOpenAddDoorGroup(false)} />
      )}
      <FilterPanel
        open={openFilterPanel}
        filters={filters}
        onApplyFilters={onApplyFilters}
        onClose={() => setOpenFilterPanel(false)}
      />
      <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start bg-white sm:-mx-6 md:mx-0 md:rounded-lg">
        <Table
          data={tableData}
          header={tableHeader}
          footer={tableFooter}
          loading={isLoading}
        />
      </div>
    </Page>
  );
}

export default withAuthz(withErrorBoundary(DoorGroupsList), [
  'door_group:view',
]);
