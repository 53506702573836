import { Button } from '@hakimo-ui/shared/ui-base';
import { FunnelIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface Props {
  canAddLocation: boolean;
  hasCustomFiltersApplied: boolean;
  isLoading: boolean;
  onClickAddLocation: () => void;
  onClickFilter: () => void;
}

export function TableHeader(props: Props) {
  const {
    canAddLocation,
    hasCustomFiltersApplied,
    isLoading,
    onClickAddLocation,
    onClickFilter,
  } = props;
  return (
    <div
      className={clsx(
        'dark:bg-dark-bg flex items-center border-b bg-white p-4 dark:border-0',
        canAddLocation ? 'justify-between' : 'justify-end'
      )}
    >
      {canAddLocation && (
        <Button
          variant="primary"
          onClick={onClickAddLocation}
          disabled={isLoading}
          trackingInfo="Open add location dialog"
        >
          Add Location
          <span className="sr-only">Add Location</span>
        </Button>
      )}
      <Button
        variant="icon"
        badge={hasCustomFiltersApplied}
        onClick={onClickFilter}
        disabled={isLoading}
        trackingInfo="Open locations filters panel"
      >
        <span className="sr-only">Open filters panel</span>
        <FunnelIcon className="h-5 w-5" aria-hidden="true" />
      </Button>
    </div>
  );
}

export default TableHeader;
