import { UserProfileProvider } from '@hakimo-ui/hakimo/providers';
import { RootLayout } from '@hakimo-ui/hakimo/ui-layout';
import { Outlet } from 'react-router-dom';
import AuthHandler from '../auth/AuthHandler';
import AppInit from '../init/AppInit';

export function AppMain() {
  return (
    <AuthHandler>
      <UserProfileProvider>
        <AppInit />
        <RootLayout>
          <Outlet />
        </RootLayout>
      </UserProfileProvider>
    </AuthHandler>
  );
}

export default AppMain;
