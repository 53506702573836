import { AlarmListFilters } from '@hakimo-ui/hakimo/types';
import { UpdateFiltersAction, UPDATE_FILTERS } from './actions';

export function createUpdateFiltersAction(
  payload: Partial<AlarmListFilters>
): UpdateFiltersAction {
  return {
    type: UPDATE_FILTERS,
    payload,
  };
}
