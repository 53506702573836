import { Toaster } from 'react-hot-toast';
import AppRoutes from './routes/AppRoutes';

export function App() {
  return (
    <div className="dark:bg-dark-bg h-full bg-white">
      <AppRoutes />
      <Toaster />
    </div>
  );
}

export default App;
