import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import { DCP, Door, DoorFilters } from '@hakimo-ui/hakimo/types';
import { Column, Row, TableData } from '@hakimo-ui/hakimo/ui-table';
import { Button, Label } from '@hakimo-ui/shared/ui-base';
import { TrashIcon } from '@heroicons/react/24/outline';
import { MouseEvent } from 'react';

function isLabelled(dcp?: DCP | null): boolean {
  if (dcp == null) {
    return false;
  }
  // Check for both null and undefined
  if (dcp.label == null) {
    return false;
  }
  return (
    dcp.label.doorMarkers !== null &&
    dcp.label.doorMarkers.length === 4 &&
    dcp.label.cameraPosition !== 'unknown'
  );
}

export const columns: Column[] = [
  {
    id: 'door',
    name: 'Door',
  },
  {
    id: 'camera',
    name: 'Camera',
    showAtBreakpoint: 'xl',
  },
  {
    id: 'location',
    name: 'Location',
    showAtBreakpoint: 'lg',
  },
  {
    id: 'label',
    name: 'Label',
    align: 'center',
    showAtBreakpoint: 'md',
  },
  {
    id: 'action',
    name: '',
    align: 'center',
    showAtBreakpoint: 'md',
  },
];

export function getTableData(
  items: Door[],
  onClickCb: (item: Door) => void,
  onClickRemove: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    dcp: Door
  ) => void,
  canRemove: boolean
): TableData {
  const rows: Row[] = items.map<Row>((item) => {
    return {
      id: item.id,
      onClick: () => {
        if (item.dcp) {
          onClickCb(item);
        }
      },
      cells: [
        {
          value: <div className="truncate">{item.name}</div>,
        },
        {
          value: item.dcp?.cameraName,
        },
        {
          value: item.location?.name,
        },
        {
          value: isLabelled(item.dcp) ? (
            <Label text="Completed" type="success" />
          ) : (
            <Label text="Pending" type="error" />
          ),
          align: 'center',
        },
        {
          value:
            item.dcp && canRemove ? (
              <Button
                variant="icon"
                onClick={(event) => onClickRemove(event, item)}
              >
                <TrashIcon className="w-4" />
              </Button>
            ) : (
              <span></span>
            ),
        },
      ],
    };
  });

  return {
    columns,
    rows,
  };
}

export function getSearchParams(
  page: number,
  filters: DoorFilters,
  pageSize: number
): string {
  const params = new URLSearchParams();
  params.append('page', String(page));
  params.append('pageSize', String(pageSize));

  const labelStatuses = [];

  if (filters.labelStatus.showLabeled) {
    labelStatuses.push('completed');
  }

  if (filters.labelStatus.showUnlabeled) {
    labelStatuses.push('pending');
  }

  if (labelStatuses.length > 0) {
    params.append('labelStatus', labelStatuses.join(SEPARATOR));
  }

  if (filters.location.values.length > 0) {
    params.append(
      'locations',
      filters.location.values.map((item) => item.name).join(SEPARATOR)
    );
  }
  if (filters.door.values.length > 0) {
    params.append(
      'doors',
      filters.door.values.map((item) => item.name).join(SEPARATOR)
    );
  }

  return params.toString();
}
