import { AlarmStatus, StatusFilterValue } from '@hakimo-ui/hakimo/types';
import { MultiSelect } from '@hakimo-ui/hakimo/ui-elements';
import { getMultiSelectItems, selectHelpers } from '@hakimo-ui/hakimo/util';
import { Label, Selectable, Toggle } from '@hakimo-ui/shared/ui-base';
import { EyeSlashIcon } from '@heroicons/react/24/outline';

interface Option {
  id: string;
  name: AlarmStatus;
}

const options: Option[] = [
  { id: 'analyzing', name: 'Analyzing' },
  { id: 'pending', name: 'Pending' },
  { id: 'resolvedByHakimo', name: 'Resolved by Hakimo' },
  { id: 'resolvedManually', name: 'Resolved Manually' },
  { id: 'acsAck', name: 'Alarm Acknowledged via ACS' },
  { id: 'inProgress', name: 'In Progress' },
];

interface Props {
  value: StatusFilterValue;
  onChange: (value: StatusFilterValue) => void;
}

function StatusFilter(props: Props) {
  const { value: status, onChange } = props;
  const selected = options.filter((option) =>
    status.values.includes(option.name)
  );

  const onChangeSelected = (updated: Option[]) => {
    onChange({
      negative: status.negative,
      values: updated.map((option) => option.name),
    });
  };
  const onChangeNegative = (neg: boolean) => {
    onChange({ ...status, negative: neg });
  };

  const getItems = (query: string) => {
    const { filterPredicate, isEqual } = selectHelpers;

    return getMultiSelectItems(query, selected, filterPredicate, isEqual, {
      staticItems: options,
    });
  };
  const onRemoveSelected = (index: number) => {
    onChangeSelected([
      ...selected.slice(0, index),
      ...selected.slice(index + 1),
    ]);
  };
  const displayValue = (item: Selectable) => item?.name;
  const getId = (item: Selectable) => item.id;
  const customSelectedRenderer = (items: Selectable[]) => {
    return (
      <div className="flex flex-wrap gap-1">
        {items.map((item, idx) => (
          <span key={getId(item) || idx}>
            <Label
              text={displayValue(item)}
              removable
              onClickRemove={() => onRemoveSelected(idx)}
              type={status.negative ? 'error' : 'default'}
            />
          </span>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="pb-2">
        <Toggle
          enabled={status.negative}
          onChange={onChangeNegative}
          type="negative"
          EnabledIcon={EyeSlashIcon}
          label="Negative Filtering"
        />
      </div>
      <MultiSelect
        label="Alarm Status"
        value={selected}
        onChange={onChangeSelected}
        getItems={getItems}
        displayValue={displayValue}
        id={getId}
        customSelectedRenderer={customSelectedRenderer}
      />
    </>
  );
}

export default StatusFilter;
