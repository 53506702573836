import { Button } from '@hakimo-ui/shared/ui-base';
interface Props {
  canUpdate: boolean;
  isLoading: boolean;
  isValid: boolean;
  onClickSave: () => void;
  onClickClear: () => void;
  onClickReset: () => void;
  onClickNext: () => void;
}
function LabelPanelFooter(props: Props) {
  const {
    canUpdate,
    isLoading,
    isValid,
    onClickClear,
    onClickReset,
    onClickSave,
    onClickNext,
  } = props;
  return (
    <div className="mt-6 space-y-3">
      {canUpdate && (
        <>
          <Button
            disabled={isLoading}
            block
            onClick={onClickNext}
            loading={isLoading}
            trackingInfo="Next door details"
          >
            Next
          </Button>
          <Button
            disabled={isLoading || !isValid}
            variant="primary"
            block
            onClick={onClickSave}
            loading={isLoading}
            trackingInfo="Save door label details"
          >
            Save
          </Button>
          <Button
            onClick={onClickReset}
            disabled={isLoading}
            block
            trackingInfo="Reset door label details"
          >
            Reset
          </Button>
          <Button
            onClick={onClickClear}
            disabled={isLoading}
            variant="error"
            block
            trackingInfo="Clear door labelling"
          >
            Clear Label
          </Button>
        </>
      )}
    </div>
  );
}

export default LabelPanelFooter;
