import { Fragment } from 'react';
import { MARGIN } from '../constants';
import { secondsToHumanReadableTime } from '../util';

export interface Props {
  positions: number[];
  height: number;
}

export function XLines(props: Props) {
  let { positions } = props;
  const { height } = props;
  positions = positions.slice(1);

  return (
    <g>
      {positions.map((x, i) => (
        <Fragment key={i}>
          <line
            x1={x}
            x2={x}
            y1={MARGIN.top}
            y2={height}
            className="stroke-black/10 dark:stroke-white/10"
            strokeDasharray="2 2"
            strokeWidth={1}
          />
          <text
            x={x}
            y={height}
            className="dark:fill-ondark-text-2 fill-onlight-text-2 text-xs"
          >
            +{secondsToHumanReadableTime(30 * (i + 1))}
          </text>
        </Fragment>
      ))}
    </g>
  );
}

export default XLines;
