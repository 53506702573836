import {
  useCustomComments,
  useUpdateAlarm,
} from '@hakimo-ui/hakimo/data-access';
import { Alarm, displayStatusTypes, StatusType } from '@hakimo-ui/hakimo/types';
import { Select } from '@hakimo-ui/hakimo/ui-elements';
import {
  getSelectItemsSelectable,
  toast,
  useCanChangeStatusNoComment,
} from '@hakimo-ui/hakimo/util';
import {
  Alert,
  Button,
  Modal,
  Radio,
  Selectable,
} from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { getStatusType } from '../../util';
import { checkNeedsComment, displayStatusSelectables } from './util';

interface Props {
  open: boolean;
  alarm: Alarm;
  onCloseCb: () => void;
  onResolveCb?: () => void;
}

export function AlarmStatusModal(props: Props) {
  const { open, alarm, onCloseCb, onResolveCb } = props;
  const [comment, setComment] = useState<Selectable | null>(null);
  const [query, setQuery] = useState<string>();
  const currentStatusType = getStatusType(alarm.status);
  const { data: commentOptions } = useCustomComments();
  const mutation = useUpdateAlarm(alarm.id, (_, variables) => {
    if (
      variables?.newStatus ===
        displayStatusTypes.find((item) => item === 'Resolved') &&
      onResolveCb
    ) {
      onResolveCb();
    }
    toast('Alarm status updated', { type: 'success' });
    onCloseCb();
  });
  const [selection, setSelection] = useState<
    Selectable<StatusType> | undefined
  >(() => {
    return displayStatusSelectables.find((s) => currentStatusType === s.name);
  });
  const needsComment = checkNeedsComment(
    currentStatusType,
    selection?.name,
    useCanChangeStatusNoComment()
  );

  const statusUpdated = Boolean(
    selection && currentStatusType !== selection.name
  );

  const onChangeStatus = () => {
    if (selection && (!needsComment || comment)) {
      // must have made a selection to change status
      const update = {
        newStatus: selection.name,
        timestamp: new Date().toISOString(),
        comment: comment?.name,
      };

      mutation.mutate(update);
    }
  };

  const getItems = (q: string) => {
    return getSelectItemsSelectable(q, comment, commentOptions);
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onChangeStatus}
        // Only enable if status actually changes
        // and comment has been selected (only if it is mandatory)
        disabled={
          !statusUpdated ||
          (needsComment && !comment) ||
          (query !== '' && query !== comment?.name)
        }
        trackingInfo="Submit alarm status update"
        loading={mutation.isLoading}
      >
        Submit
      </Button>
      <Button onClick={onCloseCb} trackingInfo="Cancel alarm status update">
        Cancel
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onCloseCb}
      title="Update Status"
      footer={actions}
    >
      <div className="w-96">
        <div className="p-8">
          <h2 className="mb-1 block text-xs text-gray-700 dark:text-gray-400">
            Status
          </h2>
          {mutation.isError ? (
            <div className="py-2">
              <Alert type="error">
                Error updating alarm status. Please try again.
              </Alert>
            </div>
          ) : null}
          <Radio
            items={displayStatusSelectables}
            value={selection}
            onChange={setSelection}
            id={(item) => item.id}
            displayValue={(item) => item.name}
          />
          <div className="mt-4">
            <Select
              label="Comment"
              value={comment}
              displayValue={(item) => item?.name ?? ''}
              getItems={getItems}
              onChange={setComment}
              id={(item) => item.id}
              onChangeQuery={setQuery}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
