import { Button } from '@hakimo-ui/shared/ui-base';
import { Bars3Icon } from '@heroicons/react/24/outline';

interface Props {
  onOpenSidebar: () => void;
}

export function NavbarMobile(props: Props) {
  const { onOpenSidebar } = props;

  return (
    <div className="dark:bg-dark-surface sticky top-0 z-10 bg-white p-4 sm:p-6 md:p-8 lg:hidden">
      <Button
        variant="icon"
        onClick={onOpenSidebar}
        dataTestid="btn-open-sidebar"
        trackingInfo="Open sidebar mobile"
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-8 w-8" aria-hidden="true" />
      </Button>
    </div>
  );
}

export default NavbarMobile;
