import { useDoors } from '@hakimo-ui/hakimo/data-access';
import { Door, DoorFilters } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { TableData } from '@hakimo-ui/hakimo/ui-table';
import {
  trackFilters,
  useCanAddDcp,
  useCanRemoveMapping,
  useCanSeeDcpDetails,
  withAuthz,
  withErrorBoundary,
} from '@hakimo-ui/hakimo/util';
import { Alert, Button } from '@hakimo-ui/shared/ui-base';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterPanel from '../filter-panel/FilterPanel';
import {
  createUpdateFiltersAction,
  createUpdatePageAction,
} from '../store/action-creators';
import { useAppDispatch, useAppState } from '../store/StateProvider';
import { columns, getSearchParams, getTableData } from '../util';
import { DoorListModals } from './DoorListModals';
import DoorListTable from './DoorListTable';
import { mapDoorFilter } from './utils';

function DoorList() {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const {
    doorListFilters: filters,
    doorListPage: page,
    doorPageSize: pageSize,
  } = state;
  const [tableData, setTableData] = useState<TableData>({
    columns,
    rows: [],
  });
  const [total, setTotal] = useState(0);
  const [openFilterPanel, setOpenFilterPanel] = useState(false);
  const [openAddMappingModal, setOpenAddMappingModal] = useState(false);
  const [openAddToLocationModal, setOpenAddToLocationModal] = useState(false);
  const [openAddToDoorGroupModal, setOpenAddToDoorGroupModal] = useState(false);
  const [selectedDoorIds, setSelectedDoorIds] = useState<string[]>([]);
  const [openRemoveMappingModal, setOpenRemoveMappingModal] = useState(false);
  const [selectedDcp, setSelectedDcp] = useState<Door>();
  const { isFetching, isError, error, data } = useDoors(
    getSearchParams(page, filters, pageSize)
  );
  const fromIndex = (page - 1) * pageSize;
  const toIndex = Math.min(page * pageSize, total) - 1;
  const navigate = useNavigate();
  const canViewDcpDetails = useCanSeeDcpDetails();
  const onClickRow = useCallback(
    (door: Door) => {
      if (door.dcp && canViewDcpDetails) {
        navigate(`/doors/${door.dcp?.id}`);
      }
    },
    [navigate, canViewDcpDetails]
  );
  const canAddMapping = useCanAddDcp();
  const canRemoveMapping = useCanRemoveMapping();
  const onClickRemove = useCallback(
    (
      event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
      dcp: Door
    ) => {
      if (canRemoveMapping) {
        event.stopPropagation();
        if (dcp) {
          setSelectedDcp(dcp);
          setOpenRemoveMappingModal(true);
        }
      }
    },
    [canRemoveMapping]
  );
  useEffect(() => {
    if (data) {
      const { items, total: t } = data;
      setTableData(
        getTableData(items, onClickRow, onClickRemove, canRemoveMapping)
      );
      setTotal(t);
    }
  }, [data, onClickRow, onClickRemove, canRemoveMapping]);

  const onApplyFilters = (value: DoorFilters) => {
    dispatch(createUpdateFiltersAction(value));
    dispatch(createUpdatePageAction(1));
    trackFilters(mapDoorFilter(value));
  };
  const pageActions = canAddMapping ? (
    <Button
      variant="primary"
      onClick={() => setOpenAddMappingModal(true)}
      disabled={isFetching}
      trackingInfo="Open add door camera mapping dialog"
    >
      Add Mapping
      <span className="sr-only">Add Mapping</span>
    </Button>
  ) : null;
  return (
    <Page title="Doors" secondary={pageActions}>
      {isError && (
        <div className="mb-2">
          <Alert type="error">{error.message}</Alert>
        </div>
      )}
      <DoorListModals
        addMappingModalDisplay={canAddMapping && openAddMappingModal}
        onCloseAddMappingModal={() => setOpenAddMappingModal(false)}
        addLocationModalDisplay={openAddToLocationModal}
        onCloseLocationModal={() => setOpenAddToLocationModal(false)}
        removeMappingModalDisplay={openRemoveMappingModal && canRemoveMapping}
        onCloseRemoveMappingModal={() => setOpenRemoveMappingModal(false)}
        addDoorGroupModalDisplay={openAddToDoorGroupModal}
        onCloseAddDoorGroupModal={() => setOpenAddToDoorGroupModal(false)}
        selectedMapping={selectedDcp}
        selectedDoorIds={selectedDoorIds}
      />
      <FilterPanel
        open={openFilterPanel}
        filters={filters}
        onApplyFilters={onApplyFilters}
        onClose={() => setOpenFilterPanel(false)}
      />
      <div className="dark:bg-dark-bg -mx-4 flex min-h-0 flex-1 flex-col justify-start bg-white sm:-mx-6 md:mx-0 md:rounded-lg">
        <DoorListTable
          selected={selectedDoorIds}
          filters={filters}
          fromIndex={fromIndex}
          toIndex={toIndex}
          page={page}
          pageSize={pageSize}
          total={total}
          loading={isFetching}
          tableData={tableData}
          onChangeSelected={setSelectedDoorIds}
          onClickOpenFilter={() => setOpenFilterPanel(true)}
          onClickAddToLocation={() => setOpenAddToLocationModal(true)}
          onClickAddToDoorGroup={() => setOpenAddToDoorGroupModal(true)}
        />
      </div>
    </Page>
  );
}
export default withAuthz(withErrorBoundary(DoorList), ['door:view']);
