import Bounds from './Bounds';
import {
  LABEL_OFFSET,
  MARGIN,
  TIMELINE_HEIGHT,
  TIMELINE_V_SPACE,
  WIDTH,
} from './constants';
import Event from './Event';
import XLines from './guiding-lines/XLines';
import { TimelineSource } from './types';
import { createLinearMapping, flattenArray, getSplitTimes } from './util';

interface Props {
  large?: boolean;
  sources: TimelineSource[];
  selected: string;
  onChangeSelected: (eventId: string) => void;
}

export default function EventTimeline(props: Props) {
  const { large = false, sources, selected, onChangeSelected } = props;
  const baseWidth = large ? 2 * WIDTH : WIDTH;
  const width = baseWidth + MARGIN.left + MARGIN.right;

  const height =
    sources.length * TIMELINE_HEIGHT +
    (sources.length - 1) * TIMELINE_V_SPACE +
    MARGIN.top +
    MARGIN.bottom;

  const timeStamps = sources.reduce<number[]>((acc, cur) => {
    return [...acc, ...flattenArray(cur.events.map((e) => e.timespan))];
  }, []);

  const min = Math.min(...timeStamps);
  const max = Math.max(...timeStamps);

  const map = createLinearMapping([min, max], [0, baseWidth]);

  const xPositions = getSplitTimes(min, max).map(map);

  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
        {sources.map((source, i) => (
          <g
            key={i}
            transform={`translate(0, ${
              (TIMELINE_HEIGHT + TIMELINE_V_SPACE) * i
            })`}
          >
            <rect
              x="0"
              y="0"
              width={baseWidth}
              height={TIMELINE_HEIGHT}
              className="dark:fill-ondark-bg-2 fill-onlight-bg-3 opacity-40"
            />
            {source.events.map((event) => {
              const {
                timespan: [s, e],
              } = event;

              const x1 = map(s);
              const x2 = map(e);

              return (
                <Event
                  key={event.id}
                  selected={selected === event.id}
                  x1={x1}
                  x2={x2}
                  onClick={() => onChangeSelected(event.id)}
                />
              );
            })}
            <text
              x={LABEL_OFFSET}
              y={TIMELINE_HEIGHT / 2}
              className="dark:fill-ondark-text-2 fill-onlight-text-2 text-xs"
              dominantBaseline="central"
              pointerEvents="none"
            >
              {source.name}
            </text>
          </g>
        ))}
      </g>
      <XLines positions={xPositions} height={height} />
      <Bounds width={width} height={height} min={min} max={max} />
    </svg>
  );
}
