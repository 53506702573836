import { DCP } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useSingleUnlabeled(
  enabled: boolean,
  onSuccess: (dcp: DCP) => void
) {
  const url = `/v2/orm/dcp/single-unlabelled`;
  const request = new Request(url);

  return useAuthenticatedRequest<DCP>(request, {
    queryKey: ['dcp', 'single-unlabelled'],
    onSuccess,
    enabled,
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    cacheTime: 0,
  });
}

export default useSingleUnlabeled;
