import {
  useAddDoorsToLocation,
  useLocationsByName,
} from '@hakimo-ui/hakimo/data-access';
import { Location, LocationsDTO } from '@hakimo-ui/hakimo/types';
import { SelectAsync } from '@hakimo-ui/hakimo/ui-elements';
import { toast } from '@hakimo-ui/hakimo/util';
import { Button, Modal, Selectable } from '@hakimo-ui/shared/ui-base';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { useState } from 'react';

interface Props {
  selectedDoors: string[];
  onClose: () => void;
}

export function AddToLocationModal(props: Props) {
  const { selectedDoors, onClose } = props;
  const [location, setLocation] = useState<Selectable | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);

  const getLocationsByName = useLocationsByName(async (response) => {
    const res = await response.json();
    const payload = res.payload as LocationsDTO;
    setLocations(payload.items);
  });

  const mutation = useAddDoorsToLocation({
    locationId: location?.id ?? '',
    onSuccess: () => {
      toast(
        `Door${
          selectedDoors.length > 1 ? 's' : ''
        } added to location successfully`
      );
      onClose();
    },
  });

  const onSubmit = () => {
    mutation.mutate({
      doors: selectedDoors,
    });
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={!location || mutation.isLoading}
        trackingInfo="Submit add door(s) to location"
        loading={mutation.isLoading}
      >
        Submit
      </Button>
      <Button onClick={onClose} trackingInfo="Cancel add door(s) to location">
        Cancel
      </Button>
    </>
  );

  const selectedLocation = locations.find(
    (loc) => String(loc.id) === location?.id
  );

  return (
    <Modal
      title={`Add Door${selectedDoors.length > 1 ? 's' : ''} to Location`}
      open
      onClose={onClose}
      footer={actions}
    >
      <div className="w-[30rem] space-y-4 p-8">
        <SelectAsync
          label="Location"
          onChangeQuery={getLocationsByName}
          value={location}
          onChange={setLocation}
          displayValue={(item) => item?.name || ''}
        />
        {selectedLocation && (
          <div className="dark:border-dark-border-bg dark:bg-dark-surface mt-4 flex items-start justify-between gap-2 rounded border-gray-300 bg-gray-100 p-4">
            <div className="flex flex-col gap-1">
              <div className="mb-1 text-sm">{selectedLocation.name}</div>
              <div className="dark:text-dark-secondary-text text-xs text-gray-500">{`${[
                selectedLocation.city,
                selectedLocation.state,
                selectedLocation.country,
              ].join(', ')}`}</div>
              <div className="dark:text-dark-secondary-text text-xs text-gray-500">
                {selectedLocation.timezone}
              </div>
            </div>
            <BuildingOffice2Icon className="dark:text-dark-secondary-text h-5 w-5 text-gray-500" />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AddToLocationModal;
