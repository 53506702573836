import { AlarmFilters } from '@hakimo-ui/hakimo/types';

export function isNotDefaultFilter(filters: AlarmFilters): boolean {
  return (
    filters.location.values.length > 0 ||
    filters.sourceSystem.length > 0 ||
    filters.sourceEntity.values.length > 0 ||
    filters.type.values.length > 0 ||
    filters.employee.values.length > 0 ||
    (filters.status !== undefined && filters.status.values.length > 0)
  );
}
