import { Permission } from '@hakimo-ui/hakimo/types';
import { Unauthorized } from '@hakimo-ui/hakimo/ui-elements';
import { ComponentType, ReactElement } from 'react';
import { useUser } from '../contexts/user-profile';

export function withAuthz<T>(
  WrappedComponent: ComponentType<T>,
  permissions: Permission[],
  fallback?: ReactElement
) {
  const ComponentWithProps = (props: T & JSX.IntrinsicAttributes) => {
    return (
      <AuthzHandler permissions={permissions} fallback={fallback}>
        <WrappedComponent {...props} />
      </AuthzHandler>
    );
  };

  return ComponentWithProps;
}

interface Props {
  permissions: Permission[];
  children: ReactElement;
  fallback?: ReactElement;
}

function AuthzHandler(props: Props) {
  const { children, permissions: requiredPermissions, fallback } = props;
  const { permissions } = useUser();

  const canAccess = requiredPermissions.every((perm) =>
    permissions.includes(perm)
  );

  return canAccess ? children : fallback || <Unauthorized />;
}

export default withAuthz;
