import { TimelineEvent } from '../types';

interface Props {
  events: TimelineEvent[];
}

export function Timeline(props: Props) {
  const { events } = props;

  return (
    <div className="flow-root p-6">
      <ul>
        {events.map((event, eventIdx) => (
          <li key={eventIdx} className="group">
            <div className=" relative pb-8 group-last:pb-0">
              {eventIdx !== events.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-500"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                {event.icon}
                <div className="flex-1">
                  <div className="dark:text-dark-secondary-text basis-5/12 text-xs font-medium text-gray-500">
                    {event.time}
                  </div>
                  <p className="basis-7/12 pt-1 text-sm text-gray-700  dark:text-gray-200">
                    {event.description}
                    {event.audio_url ? (
                      <audio
                        src={event.audio_url}
                        className="h-7"
                        controls
                      ></audio>
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Timeline;
