import { AlarmUpdate, AlarmUpdatesDTO } from '@hakimo-ui/hakimo/types';
import { TimelineEvent } from '@hakimo-ui/hakimo/ui-elements';
import { HakimoLogo } from '@hakimo-ui/hakimo/ui-navigation';
import {
  ChatBubbleLeftEllipsisIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import {
  CheckIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid';
import { getStatusType } from '../../util';

const HAKIMO_USERNAME = 'Hakimo AI Engine';
const ADD_COMMENT_EVENT = 'Added a comment';
const ACS_ACKNOWLEDGMENT_EVENT = 'ACS Alarm Acknowledgment';
const SPEAKER_TALKDOWN_EVENT = 'Responded via speaker talk-down';

const checkTimelineIcon = (
  <span className="dark:ring-dark-surface flex h-8 w-8 items-center justify-center rounded-full bg-green-500 ring-8 ring-white">
    <CheckIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

const hakimoTimelineIcon = (
  <span className="dark:bg-dark-surface dark:ring-dark-surface flex h-8 w-8 items-center justify-center rounded-full bg-white ring-8 ring-white">
    <HakimoLogo />
  </span>
);
const inProgressTimelineIcon = (
  <span className="dark:ring-dark-surface flex h-8 w-8 items-center justify-center rounded-full bg-yellow-500 ring-8 ring-white">
    <ClockIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

const pendingTimelineIcon = (
  <span className="dark:ring-dark-surface flex h-8 w-8 items-center justify-center rounded-full bg-red-500 ring-8 ring-white">
    <ExclamationCircleIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

const commentTimelineIcon = (
  <span className="dark:ring-dark-surface flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
    <ChatBubbleLeftEllipsisIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

const speakerTalkdownTimelineIcon = (
  <span className="dark:ring-dark-surface flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
    <SpeakerWaveIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

export function getAlarmUpdatesSearchParams(): string {
  const params = new URLSearchParams();
  return params.toString();
}
function getTimelineIcon(update: AlarmUpdate): JSX.Element {
  if (update.user?.name === HAKIMO_USERNAME) {
    return hakimoTimelineIcon;
  }
  if (update.status) {
    switch (getStatusType(update.status)) {
      case 'Resolved':
        return checkTimelineIcon;
      case 'In Progress':
        return inProgressTimelineIcon;
      case 'Pending':
        return pendingTimelineIcon;
    }
  }
  if (update.event === SPEAKER_TALKDOWN_EVENT) {
    return speakerTalkdownTimelineIcon;
  }
  return commentTimelineIcon;
}
function getEventDescription(update: AlarmUpdate) {
  if (update.status) {
    let description = '';
    description = `${update.user?.name} changed status to ${update.status}`;
    if (update.comment) {
      description = description + `: ${update.comment}`;
    }
    return description;
  }
  if (update.event === ADD_COMMENT_EVENT) {
    return `${update.user?.name}: "${update.comment}"`;
  }
  if (update.event === ACS_ACKNOWLEDGMENT_EVENT) {
    return `Alarm Acknowledged via ACS: ${update.comment}`;
  }
  if (update.event === SPEAKER_TALKDOWN_EVENT) {
    return `${update.user?.name} responded via speaker talk-down`;
  }
  return `${update.user?.name} added feedback: ${update.comment}`;
}
export function getUpdateEvents(updates: AlarmUpdatesDTO): TimelineEvent[] {
  return updates.items.map((update) => ({
    icon: getTimelineIcon(update),
    description: getEventDescription(update),
    time: new Date(update.timestamp).toLocaleString(),
    audio_url: update.audio_url,
  }));
}
