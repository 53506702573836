import { useAlarmMedia } from '@hakimo-ui/hakimo/data-access';
import { LocationAlarm } from '@hakimo-ui/hakimo/types';
import { withAuthz } from '@hakimo-ui/hakimo/util';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import EventTimeline from '../event-timeline/EventTimeline';
import { TimelineSource } from '../event-timeline/types';
import { viewModeAtom } from '../state';
import AlarmDetails from './alarm-details/AlarmDetails';
import Header from './header/Header';
import LiveView from './live-view/LiveView';

interface Props {
  alarm: LocationAlarm;
}

export function AlarmOverview(props: Props) {
  const { alarm } = props;
  const sortedRawAlarms = useMemo(
    () =>
      alarm.rawAlarms.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      ),
    [alarm]
  );

  const latestAlarm = sortedRawAlarms[alarm.rawAlarms.length - 1];
  const [selectedEventId, setSelectedEventId] = useState(latestAlarm.id);
  const [viewMode, setViewMode] = useAtom(viewModeAtom);

  const { data } = useAlarmMedia({
    id: selectedEventId,
  });

  const timeLineSources = useMemo(
    () =>
      Array.from(
        sortedRawAlarms
          .reduce((map, item) => {
            if (!item.sourceEntity) {
              return map;
            }
            const init: TimelineSource = {
              id: item.sourceEntity.id,
              name: item.sourceEntity.name,
              events: [],
            };
            if (!map.has(item.sourceEntity.id)) {
              map.set(item.sourceEntity.id, init);
            }
            const orig = map.get(item.sourceEntity.id);
            if (orig && item.videoStartTime && item.videoEndTime) {
              // always true but makes compiler happy
              orig.events.push({
                id: item.id,
                timespan: [
                  new Date(item.videoStartTime).getTime(),
                  new Date(item.videoEndTime).getTime(),
                ],
              });
            }
            return map;
          }, new Map<string, TimelineSource>())
          .values()
      ),
    [sortedRawAlarms]
  );

  const cameraId = latestAlarm.sourceEntity.id;

  return (
    <div className="sticky top-0 w-full divide-y dark:divide-white/10">
      <Header
        alarm={alarm}
        viewMode={viewMode}
        onChangeViewMode={setViewMode}
      />
      <div className="space-y-4 pt-4 pl-4 pb-4">
        <div className="flex gap-4">
          <div className="flex-1 space-y-4" hidden={viewMode === 'live'}>
            <AlarmDetails alarm={alarm} videoPath={data?.videoPath} />
            <EventTimeline
              sources={timeLineSources}
              selected={selectedEventId}
              onChangeSelected={setSelectedEventId}
              large={viewMode === 'alarms'}
            />
          </div>
          <div className="flex-1" hidden={viewMode === 'alarms'}>
            {cameraId && <LiveView alarmId={alarm.id} cameraId={cameraId} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuthz(AlarmOverview, ['location_alarm/detail:view']);
