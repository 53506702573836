import { useAddLocation } from '@hakimo-ui/hakimo/data-access';
import { AddLocationRequestDTO } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import {
  Alert,
  Button,
  InputField,
  Modal,
  Textarea,
} from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';

interface Props {
  onClose: () => void;
}

const initialFormValue: AddLocationRequestDTO = {
  name: '',
  description: '',
  city: '',
  state: '',
  country: '',
  timezone: '',
};

export function AddLocation(props: Props) {
  const { onClose } = props;
  const [formValue, setFormValue] =
    useState<AddLocationRequestDTO>(initialFormValue);

  const addLocationMutation = useAddLocation(() => {
    toast('Location added', { type: 'success' });
    onClose();
  });

  const valuePending = Object.entries(formValue).some(
    ([_, value]: [string, string]) => {
      return value === '';
    }
  );

  const onSubmit = () => {
    addLocationMutation.mutate(formValue);
  };

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={valuePending || addLocationMutation.isLoading}
        trackingInfo="Submit add location"
        loading={addLocationMutation.isLoading}
      >
        Submit
      </Button>
      <Button onClick={onClose} trackingInfo="Cancel add location">
        Cancel
      </Button>
    </>
  );

  const valueUpdater =
    (name: keyof AddLocationRequestDTO) =>
    (arg: React.ChangeEvent<HTMLInputElement> | string) => {
      setFormValue((value) => ({
        ...value,
        [name]: typeof arg === 'string' ? arg : arg.target.value,
      }));
    };

  return (
    <Modal title="Add New Location" open onClose={onClose} footer={actions}>
      {addLocationMutation.isError && (
        <div className="mt-4 px-8">
          <Alert type="error">{addLocationMutation.error.message}</Alert>
        </div>
      )}
      <div className="w-[36rem] space-y-4 p-8">
        <InputField
          type="text"
          label="Name"
          value={formValue.name}
          onChange={valueUpdater('name')}
        />
        <Textarea
          rows={2}
          label="Description"
          value={formValue.description}
          onChange={valueUpdater('description')}
        />
        <div className="flex gap-4">
          <InputField
            type="text"
            label="City"
            value={formValue.city}
            onChange={valueUpdater('city')}
          />
          <InputField
            type="text"
            label="State"
            value={formValue.state}
            onChange={valueUpdater('state')}
          />
        </div>
        <div className="flex gap-4">
          <InputField
            type="text"
            label="Country"
            value={formValue.country}
            onChange={valueUpdater('country')}
          />
          <InputField
            type="text"
            label="Time Zone"
            value={formValue.timezone}
            onChange={valueUpdater('timezone')}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddLocation;
